/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  CloudAccountStatus,
  CloudAccountsSortField,
  Maybe,
  isEmpty,
  isNil,
} from "~/tools";
import {
  CloudSummaryTabData,
  CloudProviderType,
  Provider,
  MovedCloudAccountTotalCosts,
  ProvidersForFiltering,
  ProviderTab,
  CloudAccount,
} from "../types";

export function getProviderName(name: string) {
  switch (name.toUpperCase()) {
    case Provider.Aws:
      return "AWS";
    case Provider.Azure:
      return "Azure";
    case Provider.Gcp:
      return "Google Cloud";
    case Provider.Ibmcloud:
      return "IBMCloud";
    case Provider.Kubernetes:
      return "Kubernetes";
    case Provider.Openshift:
      return "OpenShift";
    case Provider.Vmware:
      return "VMware";
    default:
      return name;
  }
}

export const getAccountFilters = (
  provider: ProviderTab,
  providerFilter?: ProvidersForFiltering[]
) => {
  switch (provider) {
    case ProviderTab.AWS:
      return {
        provider: [Provider.Aws],
        providerType: [CloudProviderType.Cloud],
      };
    case ProviderTab.AZURE:
      return {
        provider: [Provider.Azure],
        providerType: [CloudProviderType.Cloud],
      };
    case ProviderTab.GCP:
      return {
        provider: [Provider.Gcp],
        providerType: [CloudProviderType.Cloud],
      };
    case ProviderTab.IBMCLOUD:
      return {
        provider: [Provider.Ibmcloud],
        providerType: [CloudProviderType.Cloud],
      };
    case ProviderTab.KUBERNETES:
    case ProviderTab.OPENSHIFT:
      return {
        provider: providerFilter ? [...providerFilter] : undefined,
        providerType: [provider],
      };
    case ProviderTab.VMWARE:
      return {
        provider: [Provider.Hybrid],
        providerType: [provider],
      };
    default:
      return {};
  }
};

export function getAccountStatusFilter(queryStatus: string[] = []) {
  const filter: { cloudStatusV2: CloudAccountStatus[] } = { cloudStatusV2: [] };

  if (isEmpty(queryStatus)) {
    return filter;
  }

  for (const status of queryStatus) {
    switch (status) {
      case CloudAccountStatus.ApiOnly:
        filter.cloudStatusV2.push(CloudAccountStatus.ApiOnly);
        break;
      case CloudAccountStatus.BillingOnly:
        filter.cloudStatusV2.push(CloudAccountStatus.BillingOnly);
        break;
      case CloudAccountStatus.FullAccess:
        filter.cloudStatusV2.push(CloudAccountStatus.FullAccess);
        break;
      case CloudAccountStatus.NoAccess:
        filter.cloudStatusV2.push(CloudAccountStatus.NoAccess);
        break;
      case CloudAccountStatus.NoData:
        filter.cloudStatusV2.push(CloudAccountStatus.NoData);
        break;
      default:
        break;
    }
  }

  return filter;
}

export function getSortFieldByChargeType(chargeType?: string) {
  switch (chargeType) {
    case undefined:
    case null:
    case "Total Cost":
      return CloudAccountsSortField.TotalCost;
    case "Name":
      return CloudAccountsSortField.Name;
    default:
      return CloudAccountsSortField.ChargeType;
  }
}

export function getAccountUrl(id: string, provider: Provider | string) {
  switch (provider) {
    case Provider.Aws:
      return `https://${id}.signin.aws.amazon.com/console/`;
    case Provider.Ibmcloud:
      return `https://cloud.ibm.com`;
    case Provider.Azure:
      return `https://portal.azure.com/#resource/subscriptions/${id}`;
    case Provider.Gcp:
      return `https://console.cloud.google.com/home/dashboard?organizationId=${id}`;
    default:
      return null;
  }
}

export function canBeOnboarded(
  providerType?: CloudProviderType,
  status?: CloudAccountStatus
) {
  if (isNil(providerType) || isNil(status)) {
    return false;
  }

  const providerTypes = [
    CloudProviderType.Kubernetes,
    CloudProviderType.Openshift,
  ];

  return (
    providerTypes.includes(providerType) &&
    status === CloudAccountStatus.NoAccess
  );
}

export function showDownloadButton(
  providerType?: CloudProviderType,
  status?: CloudAccountStatus
) {
  if (isNil(providerType) || isNil(status)) {
    return false;
  }

  const providerTypes = [
    CloudProviderType.Kubernetes,
    CloudProviderType.Openshift,
    CloudProviderType.Vmware,
  ];

  return (
    providerTypes.includes(providerType) &&
    status !== CloudAccountStatus.NoAccess
  );
}

// used in list, based on designs seen on 2023-05-30
export function isPossibleToUpdateCredentialsV2(
  providerType?: Maybe<CloudProviderType>,
  provider?: Provider
) {
  return Boolean(
    provider &&
      [Provider.Aws, Provider.Azure, Provider.Gcp, Provider.Ibmcloud].includes(
        provider
      ) &&
      providerType &&
      providerType === CloudProviderType.Cloud
  );
}

export function sortTabData(tabData: CloudSummaryTabData[]) {
  return tabData.sort((a, b) => {
    const aCost = a.cost;
    const bCost = b.cost;

    // N/A has to be in the very back
    if (aCost === "N/A" || bCost === "N/A") {
      return aCost === "N/A" ? 1 : -1;
    }

    if (isNil(aCost) || isNil(bCost)) {
      return isNil(aCost) ? 1 : -1;
    }

    return parseFloat(bCost) - parseFloat(aCost);
  });
}

export function extractMovedCostsForProvider(
  provider: string,
  movedCosts: MovedCloudAccountTotalCosts
) {
  switch (provider) {
    case Provider.Aws:
      return {
        movedToK8s: movedCosts.movedToK8sFromAwsTotalCost,
        movedToOc: movedCosts.movedToOcFromAwsTotalCost,
      };
    case Provider.Azure:
      return {
        movedToK8s: movedCosts.movedToK8sFromAzureTotalCost,
        movedToOc: movedCosts.movedToOcFromAzureTotalCost,
      };
    case Provider.Gcp:
      return {
        movedToK8s: movedCosts.movedToK8sFromGcpTotalCost,
        movedToOc: movedCosts.movedToOcFromGcpTotalCost,
      };
    case Provider.Ibmcloud:
      return {
        movedToK8s: movedCosts.movedToK8sFromIBMCloudTotalCost,
        movedToOc: movedCosts.movedToOcFromIBMCloudTotalCost,
      };
    default:
      return {};
  }
}

export function getTotalCost(
  costsByChargeType?: Maybe<
    {
      chargeType: string;
      cost: string;
    }[]
  >
) {
  return costsByChargeType
    ?.map(({ cost }) => cost)
    ?.reduce(
      (accumulator, currentValue) => accumulator + parseFloat(currentValue),
      0
    );
}

export function readFile<T>(file: File): Promise<T> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // eslint-disable-next-line unicorn/prefer-add-event-listener
    reader.onload = function (event) {
      try {
        const result = event.target?.result;
        if (typeof result === "string") {
          const parsed = JSON.parse(result) as T;
          resolve(parsed);
        }
      } catch {
        reject(new Error("Failed parsing file."));
      }
    };

    reader.readAsText(file);
  });
}

export const isVMWare = (providerType?: CloudProviderType) =>
  providerType === CloudProviderType.Vmware;

export const isKubernetesOrOpenshift = (providerType: CloudProviderType) =>
  [CloudProviderType.Kubernetes, CloudProviderType.Openshift].includes(
    providerType
  );

export const isKubernetesOrOpenshiftTab = (providerTab: ProviderTab) =>
  [ProviderTab.KUBERNETES, ProviderTab.OPENSHIFT].includes(providerTab);

export function toTitleCase(rawString: string): string {
  const parts = rawString.includes(" ")
    ? rawString.split(" ")
    : rawString.split(/(?=[A-Z])/);

  return parts
    .map((value) => value.charAt(0).toUpperCase() + value.slice(1))
    .join(" ");
}

export function mapProviderTab(
  provider: Provider,
  providerType?: CloudProviderType
) {
  if (providerType === CloudProviderType.Kubernetes) {
    return ProviderTab.KUBERNETES;
  }
  if (providerType === CloudProviderType.Openshift) {
    return ProviderTab.OPENSHIFT;
  }
  if (providerType === CloudProviderType.Vmware) {
    return ProviderTab.VMWARE;
  }
  if (provider === Provider.Aws) {
    return ProviderTab.AWS;
  }
  if (provider === Provider.Azure) {
    return ProviderTab.AZURE;
  }
  if (provider === Provider.Gcp) {
    return ProviderTab.GCP;
  }
  if (provider === Provider.Ibmcloud) {
    return ProviderTab.IBMCLOUD;
  }
}

export function getAccountDisplayName(account?: CloudAccount): string {
  const { name, displayName } = account ?? {};

  return displayName || name || "";
}
