/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import {
  ChartJobTableTimePoint,
  ChartTableSortField,
  ChartTableSortOrder,
  Maybe,
  SearchJobStatus,
} from "~/generated/graphql";
import {
  CostAnalysisFields,
  DateRange,
  Granularity,
  SortDirection,
  TableData,
} from "~/components";
import { dateFormat, EstatePeriod } from "~/constants";
import { isNotNil } from "~/tools";
import {
  ChartTimePoint,
  EstateChartsQueryState,
  EstateGroupBy,
  GroupTimePoint,
} from "../types";

export function isJobCompleted(status?: SearchJobStatus): boolean {
  return status === SearchJobStatus.Completed;
}

export function isJobFailed(status?: SearchJobStatus): boolean {
  return status === SearchJobStatus.Failed;
}

export function getDefaultSortOrderByFilter(
  filter: ChartTableSortField
): ChartTableSortOrder {
  const isDefaultDescending = filter === ChartTableSortField.Cost;

  return isDefaultDescending
    ? ChartTableSortOrder.Dsc
    : ChartTableSortOrder.Asc;
}

function getFilteredCategories(
  categories: string[],
  granularity?: Granularity
) {
  if (granularity === Granularity.DAYS) {
    return categories;
  }

  return categories.filter((category) => !category.includes("commitments"));
}

export function getCategories(
  timePoints: ChartTimePoint[],
  granularity?: Granularity
) {
  const categories = timePoints
    .flatMap(
      (timePoint) =>
        timePoint?.groups
          ?.filter((group) => Number(group?.value) !== 0)
          .sort((a, b) => (Number(a?.value) > Number(b?.value) ? -1 : 1))
          .flatMap((category) => category?.name ?? "")
    )
    .filter(isNotNil);

  // should not show saving commitments if monthly granularity
  const filteredCategories = getFilteredCategories(categories, granularity);

  return [...new Set(filteredCategories)];
}

export function generateEstateRecordsGroups(
  timePoints: ChartJobTableTimePoint[],
  showTotalRow: boolean
) {
  const data = generateTableData(timePoints, showTotalRow);
  const groups = generateGroups(timePoints);

  return data.map((key) => ({
    ...key,
    id: groups.find((group) => group.name === key.field)?.id ?? key.id,
  }));
}
function mapGroups(groupTimePoints: Maybe<GroupTimePoint[]> | undefined) {
  return {
    groups: groupTimePoints?.map((group) => {
      return {
        id: group?.id ?? "",
        name:
          group?.name === "correction"
            ? CostAnalysisFields.CORRECTION_COST
            : CostAnalysisFields.DAILY_COST,
        value: group?.value ?? "",
      };
    }),
  };
}

function mapAccumulatedCostData(timePoints: ChartTimePoint[] | null) {
  if (isNotNil(timePoints)) {
    return (timePoints ?? [])?.filter(isNotNil).map((timePoint) => {
      return {
        ...timePoint,
        ...mapGroups(timePoint.groups),
      };
    });
  }

  return [];
}

export function generateDataWithSavingPlanCommitments(
  timePoints: ChartTimePoint[],
  isAccumulatedChart: boolean = false
) {
  const chartTimePoints = timePoints.filter(isNotNil);
  const mappedTimePoints = isAccumulatedChart
    ? mapAccumulatedCostData(chartTimePoints)
    : chartTimePoints;

  return mappedTimePoints.map((timePoint) => {
    if (dayjs(timePoint.date).isAfter(dayjs(), "day")) {
      return {
        ...timePoint,
        groups: [
          {
            id: "savings",
            value: timePoint.value,
            name: CostAnalysisFields.SAVINGS_PLANS,
          },
        ],
      };
    }
    return {
      ...timePoint,
    };
  });
}

function generateTableData(
  timePoints: ChartJobTableTimePoint[],
  showTotalRow: boolean
): TableData[] {
  const keys = [
    ...new Set(
      timePoints
        .flatMap(
          (timePoint) =>
            timePoint?.groups?.flatMap((group) => group?.name ?? "")
        )
        .filter(isNotNil)
    ),
  ];

  const totalCostPerPeriod = Object.fromEntries(
    timePoints.map(({ date, total }) => [date, Number(total)])
  );

  const generatedData = keys.filter(isNotNil).map((key) => ({
    field: key,
    total: timePoints
      .flatMap((timePoint) => timePoint.groups)
      .map((item) => (item?.name === key ? Number(item?.cost) : 0))
      .reduce((a, b) => a + Number(b), 0),
    ...Object.fromEntries(
      timePoints.map(({ date }) => [
        date,
        Number(
          timePoints
            .find((item) => item?.date === date)
            ?.groups?.find((item) => item?.name === key)?.cost ?? 0
        ),
      ])
    ),
  }));

  const total = timePoints.reduce((a, b) => a + Number(b.total), 0);

  return [
    ...(showTotalRow
      ? [
          {
            id: CostAnalysisFields.TOTAL_COST,
            field: "",
            ...totalCostPerPeriod,
            total,
          },
        ]
      : []),
    ...generatedData,
  ];
}

function generateGroups(timePoints: ChartJobTableTimePoint[]) {
  const keys = [
    ...new Set(
      timePoints
        .flatMap(
          (timePoint) =>
            timePoint?.groups?.flatMap((group) => group?.name ?? "")
        )
        .filter(isNotNil)
    ),
  ];

  return keys.map((key) => {
    const groupId =
      timePoints
        .flatMap((timePoint) => timePoint.groups)
        .find((group) => group?.name === key)?.key ?? "";

    return {
      id: groupId,
      name: key,
    };
  });
}

export function isPeriodValueChanged(
  statePeriod: EstatePeriod | undefined,
  period: EstatePeriod
) {
  if (statePeriod === undefined) {
    return period !== EstatePeriod.DEFAULT_MONTH;
  }

  return period !== statePeriod;
}

export function isGroupByValueChanged(
  stateGroupBy: EstateGroupBy | undefined,
  groupBy?: EstateGroupBy
) {
  if (stateGroupBy === undefined) {
    return groupBy !== EstateGroupBy.NONE;
  }

  return groupBy !== stateGroupBy;
}

export function isDateValueChanged(
  stateValue: string | undefined,
  defaultValue: string,
  value?: string
) {
  if (stateValue === undefined) {
    return value !== defaultValue;
  }

  return value !== stateValue;
}

// useEstateChartParameters hook helpers
export function getStartDate(
  period: EstatePeriod,
  isPreviousBillingPeriod: boolean,
  range?: DateRange
) {
  const monthStart = isPreviousBillingPeriod
    ? generatePreviousPeriodDate(true)
    : generateCurrentPeriodDate(true);

  if (period === EstatePeriod.RANGE) {
    return range?.from ?? monthStart;
  }

  return period === EstatePeriod.PREVIOUS_MONTH
    ? dayjs(monthStart)
        .subtract(1, "month")
        .startOf("month")
        .format(dateFormat.shortDate)
    : monthStart;
}

export function getEndDate(
  period: EstatePeriod,
  isPreviousBillingPeriod: boolean,
  range?: DateRange
) {
  const monthEnd = isPreviousBillingPeriod
    ? generatePreviousPeriodDate(false)
    : generateCurrentPeriodDate(false);

  if (period === EstatePeriod.RANGE) {
    return range?.to ?? monthEnd;
  }

  return period === EstatePeriod.PREVIOUS_MONTH
    ? dayjs(monthEnd)
        .subtract(1, "month")
        .endOf("month")
        .format(dateFormat.shortDate)
    : monthEnd;
}

function generatePreviousPeriodDate(isStartDate: boolean) {
  if (isStartDate) {
    return dayjs()
      .subtract(1, "month")
      .startOf("month")
      .format(dateFormat.shortDate);
  }

  return dayjs()
    .subtract(1, "month")
    .endOf("month")
    .format(dateFormat.shortDate);
}

function generateCurrentPeriodDate(isStartDate: boolean) {
  if (isStartDate) {
    return dayjs().startOf("month").format(dateFormat.shortDate);
  }
  return dayjs().endOf("month").format(dateFormat.shortDate);
}

function isDateValid(endDate: string, date: string | undefined) {
  return (
    isNotNil(date) && dayjs(date).isBefore(dayjs(endDate).add(1, "day"), "date")
  );
}

export function areDatesInPeriod(
  isPreviousBillingPeriod: boolean,
  state: EstateChartsQueryState
) {
  const { periodStart, periodEnd } = state;
  const maxEndDate = getEndDate(EstatePeriod.RANGE, isPreviousBillingPeriod);

  return (
    isDateValid(maxEndDate, periodStart) && isDateValid(maxEndDate, periodEnd)
  );
}

export function getRange(
  state: EstateChartsQueryState,
  isPreviousBillingPeriod: boolean
) {
  const { estatePeriod, periodStart, periodEnd } = state;

  const isRangeDefined =
    isNotNil(estatePeriod) &&
    estatePeriod === EstatePeriod.RANGE &&
    isNotNil(periodStart) &&
    isNotNil(periodEnd);

  const isRangeValid =
    isRangeDefined && areDatesInPeriod(isPreviousBillingPeriod, state);

  if (isRangeValid) {
    return {
      from: periodStart,
      to: periodEnd,
    };
  }

  return undefined;
}

export function adjustRangeByPeriod(
  isPreviousBillingPeriod: boolean,
  state: EstateChartsQueryState
) {
  const { periodStart, periodEnd } = state;
  const maxEndDate = getEndDate(EstatePeriod.RANGE, isPreviousBillingPeriod);

  const isDefinedRangeValid =
    isNotNil(periodStart) &&
    isNotNil(periodEnd) &&
    areDatesInPeriod(isPreviousBillingPeriod, state);

  if (isDefinedRangeValid) {
    return {
      from: periodStart,
      to: periodEnd,
    };
  }

  const defaultStartDate = getStartDate(
    EstatePeriod.RANGE,
    isPreviousBillingPeriod
  );

  return {
    from: defaultStartDate,
    to: maxEndDate,
  };
}

export function getSortDirection(order: ChartTableSortOrder | undefined) {
  return order === ChartTableSortOrder.Asc
    ? SortDirection.Ascending
    : SortDirection.Descending;
}
