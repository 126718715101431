/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState, useMemo } from "react";
import { useCloudAccountsNidsQuery } from "~/generated/graphql";
import { useQueryState } from "~/hooks";
import {
  CloudAccountsQueryState,
  CloudAccountsResponse,
  ProviderTab,
} from "../types";
import { getAccountFilters, getAccountStatusFilter } from "../utils";

type SelectAccountsProps = {
  accounts: CloudAccountsResponse["accounts"];
  provider: ProviderTab;
};

export function useSelectCloudAccounts({
  accounts,
  provider,
}: SelectAccountsProps) {
  const [showClearSelectionButton, setShowClearSelectionButton] =
    useState(false);
  const [selectedAccountsNids, setSelectedAccountsNids] = useState<string[]>(
    []
  );
  const visibleAccountsNids = useMemo(
    () => (accounts ?? []).map((account) => account?.nid),
    [accounts]
  );

  const {
    state: { status },
  } = useQueryState<CloudAccountsQueryState>();

  const providerFilter = useMemo(() => getAccountFilters(provider), [provider]);

  const { data: cloudAccountsNids } = useCloudAccountsNidsQuery({
    variables: {
      filter: {
        ...providerFilter,
        ...getAccountStatusFilter(status),
      },
    },
  });

  const handleSelectAccount = (nid: string) => {
    setSelectedAccountsNids((currentValue) =>
      currentValue.includes(nid)
        ? currentValue.filter((id) => id !== nid)
        : [...currentValue, nid]
    );
  };

  const handleSelectAllAccounts = () => {
    const allNids = (cloudAccountsNids?.cloudAccountNids?.accounts ?? []).map(
      (account) => account.nid
    );

    setShowClearSelectionButton(true);

    setSelectedAccountsNids(allNids);
  };

  const handleSelectPageOfAccounts = () => {
    const isPageSelected = visibleAccountsNids.every((nid) =>
      selectedAccountsNids.includes(nid)
    );

    setSelectedAccountsNids((currentValue) =>
      isPageSelected
        ? currentValue.filter((id) => !visibleAccountsNids.includes(id))
        : [
            ...new Set([
              ...currentValue,
              ...(accounts ?? []).map((account) => account.nid),
            ]),
          ]
    );
  };

  const handleUnselectAllAccounts = () => {
    setSelectedAccountsNids([]);
    setShowClearSelectionButton(false);
  };

  const isPageSelected = useMemo(
    () =>
      visibleAccountsNids.every((nid) => selectedAccountsNids.includes(nid)),
    [visibleAccountsNids, selectedAccountsNids]
  );

  const isAllAccountsSelected = useMemo(
    () =>
      selectedAccountsNids.length ===
      (cloudAccountsNids?.cloudAccountNids?.accounts ?? []).length,
    [cloudAccountsNids, selectedAccountsNids]
  );

  return {
    handleSelectAccount,
    handleSelectAllAccounts,
    handleSelectPageOfAccounts,
    handleUnselectAllAccounts,
    visibleAccountsNids,
    showClearSelectionButton,
    isPageSelected,
    isAllAccountsSelected,
    selectedAccountsNids,
    setSelectedAccountsNids,
  };
}
