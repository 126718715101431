/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { FlexContainer, Table, theme } from "@nordcloud/gnui";
import { MappedEnvironmentV2 } from "~/views/applications/ApplicationsPage/components/types";
import { EnvironmentCard, EnvironmentRow } from "./EnvironmentRow";

type Props = {
  environments: MappedEnvironmentV2[];
};

export function EnvironmentsList({ environments }: Props) {
  return (
    <>
      <TableWrapper>
        <Table.thead>
          <Table.tr>
            <Table.th width="20%">Environment Name</Table.th>
            <Table.th>Organizational Units</Table.th>
            <Table.th width="8%">Savings Suggestion</Table.th>
            <Table.th width="8%">Costs</Table.th>
            <Table.th width="8%">Monthly Forecast</Table.th>
            <Table.th width="8%">Monthly Budget</Table.th>
            <Table.th width="2%" />
          </Table.tr>
        </Table.thead>
        <Table.tbody>
          {environments.map((environment) => (
            <EnvironmentRow key={environment.id} environment={environment} />
          ))}
        </Table.tbody>
      </TableWrapper>
      <CardWrapper>
        {environments.map((environment) => (
          <EnvironmentCard key={environment.id} environment={environment} />
        ))}
      </CardWrapper>
    </>
  );
}

const TableWrapper = styled(Table)`
  table-layout: fixed;
  display: table;

  @media screen and (max-width: ${theme.breakpoints.xl}px) {
    display: none;
  }
`;

const CardWrapper = styled(FlexContainer)`
  display: none;

  @media screen and (max-width: ${theme.breakpoints.xl}px) {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.spacing02};
  }
`;
