/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { ParentSize } from "@visx/responsive";
import dayjs from "dayjs";
import { Box, Spacer, theme } from "@nordcloud/gnui";
import { useGlobalCostAllocationQuery } from "~/generated/graphql";
import {
  DataContainer,
  DataItem,
  DateRange,
  PeriodSelector,
} from "~/components";
import { dateFormat, EstatePeriod as Period } from "~/constants";
import { isNotNil } from "~/tools";
import { CostAllocationChart } from "~/views/applications/ApplicationsPage/components/CostAllocationChart";
import { CostAllocationLegend } from "~/views/applications/ApplicationsPage/components/CostAllocationLegend";

export function CostAllocationChartWrapper() {
  const [scale, setScale] = useState<{
    period: Period;
    range: DateRange | undefined;
  }>({
    period: Period.DEFAULT_MONTH,
    range: undefined,
  });
  const [time, setTime] = useState({
    startDate: dayjs().startOf("month").format(dateFormat.shortDate),
    endDate: dayjs().format(dateFormat.shortDate),
  });

  const { data, loading } = useGlobalCostAllocationQuery({
    variables: { startDate: time.startDate, endDate: time.endDate },
  });

  const costAllocation = data?.globalMetrics?.costAllocation;

  const handlePeriodChange = (
    selectedPeriod: Period,
    selectedRange?: DateRange
  ) => {
    setScale({
      period: selectedPeriod,
      range: selectedRange,
    });

    if (selectedPeriod === Period.RANGE && isNotNil(selectedRange)) {
      setTime({
        startDate: selectedRange.from,
        endDate: selectedRange.to,
      });
    }

    if (selectedPeriod === Period.DEFAULT_MONTH) {
      setTime({
        startDate: dayjs().startOf("month").format(dateFormat.shortDate),
        endDate: dayjs().format(dateFormat.shortDate),
      });
    }
    if (selectedPeriod === Period.PREVIOUS_MONTH) {
      setTime({
        startDate: dayjs()
          .subtract(1, "month")
          .startOf("month")
          .format(dateFormat.shortDate),
        endDate: dayjs()
          .subtract(1, "month")
          .endOf("month")
          .format(dateFormat.shortDate),
      });
    }
  };

  return (
    <>
      <section>
        <DataContainer hasBorder={false}>
          <DataItem
            grow
            padding="0"
            weight="medium"
            size="md"
            value="Cost Allocation"
            description="Allows to track the percentage of the cost allocation in the applications. We record a daily value of % of the allocated total cost."
          />
        </DataContainer>
      </section>
      <Box
        boxStyle="lightGrey"
        p={`${theme.spacing.spacing04} ${theme.spacing.spacing02}`}
      >
        <PeriodSelector
          period={scale.period}
          range={scale.range}
          onChange={handlePeriodChange}
        />
      </Box>
      <Spacer height={theme.spacing.spacing02} />
      <ParentSize>
        {(parent) => (
          <CostAllocationChart
            startDate={time.startDate}
            endDate={time.endDate}
            width={parent.width}
            height={250}
            data={costAllocation?.timeSeries ?? []}
            loading={loading}
          />
        )}
      </ParentSize>
      <Spacer height={theme.spacing.spacing02} />
      <CostAllocationLegend />
    </>
  );
}
