/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useMemo } from "react";
import { Else, If, Then } from "react-if";
import { BrickLoader, theme, Text } from "@nordcloud/gnui";
import {
  SortByOrder,
  useListReservationCoveredInstancesLazyQuery,
} from "~/generated/graphql";
import { NoData, Pagination, ReactTableV2, UniversalWrap } from "~/components";
import { useQueryState } from "~/hooks";
import { isNotEmpty } from "~/tools";
import { ReservationType } from "../types";
import { BenefitListColumns } from "./BenefitListColumns";
import { BenefitsContainer } from "./BenefitsContainer";

type Props = {
  nid: string;
  reservationId?: ReservationType | null;
};

export function ReservationBenefitedRecords({ nid, reservationId }: Props) {
  const { state, setQueryState } = useQueryState();
  const { page, limit, order = SortByOrder.Descending } = state;
  const isOrderDescending = order === SortByOrder.Descending;

  const [getBenefits, { data, loading, error }] =
    useListReservationCoveredInstancesLazyQuery({
      variables: {
        page,
        limit,
        reservationId: nid,
        costSortKey: order as SortByOrder,
        reservationResourceId: reservationId?.value ?? "",
      },
    });

  const rows = data?.listReservationCoveredInstances?.instances ?? [];

  const count = data?.listReservationCoveredInstances?.count ?? 0;
  const hasBenefitResources = isNotEmpty(rows);
  const columns = useMemo(() => BenefitListColumns(), []);
  const onSort = () => {
    setQueryState({
      ...state,
      page: 0,
      limit: 20,
      order: isOrderDescending ? SortByOrder.Ascending : SortByOrder.Descending,
    });
  };

  useEffect(() => {
    getBenefits();
  }, [getBenefits, reservationId]);

  return (
    <BenefitsContainer>
      <UniversalWrap
        loaderProps={{ loading, Component: BrickLoader }}
        errorProps={{ error }}
      >
        <If condition={hasBenefitResources}>
          <Then>
            <ReactTableV2
              striped
              sort
              columns={columns}
              data={rows}
              headerBackground={theme.color.background.ui03}
              onSort={onSort}
            />
            <Pagination count={count} />
          </Then>
          <Else>
            <NoData
              message={<Text>There are no benefited estate records.</Text>}
            />
          </Else>
        </If>
      </UniversalWrap>
    </BenefitsContainer>
  );
}
