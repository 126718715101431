/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { ApolloError, ApolloQueryResult } from "@apollo/client";
import { Row, Col } from "react-awesome-styled-grid";
import { Else, If, Then, Unless, When } from "react-if";
import {
  Box,
  FlexContainer,
  Spacer,
  SVGIcon,
  Text,
  theme,
} from "@nordcloud/gnui";
import { Exact, Provider, ResourceQuery } from "~/generated/graphql";
import {
  BreadcrumbsBox,
  CollapsibleBox,
  Details,
  ErrorMessage,
  IconBox,
  LoaderWrap,
} from "~/components";
import {
  CostBox,
  CustomComponents,
  getOriginalCost,
} from "~/components/CostBox";
import { Can, ACTIONS } from "~/services/auth/RBAC";
import { useCustomers } from "~/services/customers";
import {
  Currency,
  getProviderIconName,
  isCloudResource,
  isCostResource,
  isNotEmpty,
  noop,
} from "~/tools";
import { Application } from "../Application";
import { ApplicationsList } from "./ApplicationsList";
import { SavingSuggestions } from "./components";
import { CostResourceSplitPerEnvironments } from "./CostResourceSplitPerEnvironments";
import { EstateDetailsTabs } from "./EstateDetailsTabs";
import { LinkedResources } from "./EstateDetailsTabs/components";
import { useEstateDetails } from "./hooks";
import { KubernetesOpenshiftDetails } from "./KubernetesOpenshiftDetails";
import { ApplicationProps, Costs } from "./types";
import { Utilization } from "./Utilization";
import { getProvider, isNodeInstance, isPodInstance } from "./utils";

type Props = {
  loading: boolean;
  error: ApolloError | undefined;
  data: ReturnType<typeof useEstateDetails>["data"];
  currency: Currency;
  nordcloudId: string;
  refetch: (
    variables?: Partial<Exact<{ id: string }>> | undefined
  ) => Promise<ApolloQueryResult<ResourceQuery>>;
  cloudResourceData: {
    name: string;
    providerId: string;
    provider: Provider;
    providerType: string | null | undefined;
  } | null;
  costs: Costs;
  applications: ApplicationProps;
};

// TODO: Refactor to reduce size and complexity
export function DefaultEstateDetailsPage({
  loading,
  error,
  data,
  costs,
  applications,
  nordcloudId,
  refetch,
  cloudResourceData,
}: Props) {
  const { isAdminLite } = useCustomers();

  const estateRecord = data.estateRecord ?? {
    __typename: "CostResource",
    providerType: "",
    id: "",
    provider: undefined,
  };

  const provider = getProvider(estateRecord?.provider);

  const showCostResourceSplit = isCostResource(estateRecord);
  const showProvider = isNotEmpty(provider);

  const cloudAccountId = React.useMemo(() => {
    if (estateRecord && isCloudResource(estateRecord)) {
      return estateRecord.cloudAccountId?.toString();
    }
  }, [estateRecord]);

  const kubernetesDetails = React.useMemo(
    () =>
      isCloudResource(estateRecord)
        ? {
            nordcloudId,
            cloudAccountId,
            providerType: estateRecord.providerType,
            linkedResources: data?.links,
          }
        : { nordcloudId },
    [estateRecord]
  );

  const hasOriginalCost = getOriginalCost(data.estateRecord?.cost);

  const costSavings = isCloudResource(estateRecord)
    ? estateRecord.costSavings ?? []
    : [];

  const hasSavingSuggestions = isNotEmpty(costSavings);

  return (
    <Row>
      <Col>
        <LoaderWrap loading={loading} data-testid="estate-resource-loader">
          <ErrorMessage error={error} />
          <BreadcrumbsBox
            tags={data.tags}
            labels={data.breadcrumbLabels}
            title={
              (isCloudResource(estateRecord) && estateRecord.name) ||
              nordcloudId
            }
          />
          <Box spacing="spacing04">
            <Row>
              <Col xs={8} sm={8} md={5} lg={8}>
                <FlexContainer
                  columnGap={theme.spacing.spacing05}
                  css={{ height: "100%" }}
                >
                  <When condition={showProvider}>
                    <IconBox
                      icon={
                        <SVGIcon
                          size="xxl"
                          name={getProviderIconName(
                            provider || Provider.External
                          )}
                        />
                      }
                    />
                  </When>
                  <FlexContainer
                    direction="column"
                    alignItems="start"
                    data-testid="estate-record-details"
                  >
                    <Text isTitle weight="medium">
                      Estate Record Details
                    </Text>
                    <Details details={data.details} />
                    <Can action={ACTIONS.visitEstateRecords}>
                      <LinkedResources
                        isCloudResource={isCloudResource(estateRecord)}
                        provider={provider}
                        nordcloudId={nordcloudId}
                        linkedResources={data?.links}
                        cloudResourceData={cloudResourceData}
                        refetch={refetch}
                      />
                    </Can>
                    <When
                      condition={showKubernetesOpenshiftDetails(
                        provider,
                        isCloudResource(estateRecord)
                      )}
                    >
                      <KubernetesOpenshiftDetails
                        onChange={async () => {
                          await refetch();
                        }}
                        {...kubernetesDetails}
                      />
                    </When>
                  </FlexContainer>
                </FlexContainer>
              </Col>
              <Col sm={8} md={3} lg={4}>
                <CostBox
                  numericalValues={costs}
                  linkedRecords={data.links}
                  provider={data.estateRecord?.provider}
                  customComponent={
                    hasOriginalCost ? CustomComponents.ORIGINAL_COST : undefined
                  }
                />
              </Col>
            </Row>
          </Box>
          <When condition={showUtilPanel(getResourceType(estateRecord))}>
            <Utilization
              nordcloudId={nordcloudId}
              k8sResourceType={getResourceType(estateRecord)}
            />
          </When>
          <When condition={hasSavingSuggestions}>
            <CollapsibleBox title="Savings Suggestions">
              <SavingSuggestions
                costSavings={costSavings}
                provider={estateRecord?.provider ?? ""}
                onSuggestionCountChanged={noop}
              />
            </CollapsibleBox>
            <Spacer height={theme.spacing.spacing04} />
          </When>
          <Unless condition={isAdminLite}>
            <LoaderWrap loading={applications.loading}>
              <If condition={applications.showApplicationsList}>
                <Then>
                  <ApplicationsList
                    recordNid={applications.id ?? ""}
                    refetch={applications.onApplicationUpdate}
                    resourceProvider={applications.provider}
                  />
                  <Spacer height={theme.spacing.spacing04} />
                </Then>
                <Else>
                  <Application
                    refetch={applications.onApplicationUpdate}
                    estateRecord={applications.resource}
                  />
                  <Spacer height={theme.spacing.spacing04} />
                </Else>
              </If>
            </LoaderWrap>
          </Unless>
          <When condition={showCostResourceSplit}>
            <CostResourceSplitPerEnvironments
              estateRecordId={data?.estateRecord?.id ?? ""}
              showCostResourceSplit={showCostResourceSplit}
            />
            <Spacer height={theme.spacing.spacing04} />
          </When>
          <EstateDetailsTabs data={data} />
        </LoaderWrap>
      </Col>
    </Row>
  );
}

function showKubernetesOpenshiftDetails(
  provider: Provider | "",
  isCloudRecord: boolean
) {
  return (
    (provider === Provider.Kubernetes || provider === Provider.Openshift) &&
    isCloudRecord
  );
}

function showUtilPanel(providerType: string) {
  return isPodInstance(providerType) || isNodeInstance(providerType);
}

function getResourceType<T extends { providerType?: string | null }>(
  resource: T
) {
  return resource.providerType ?? "";
}
