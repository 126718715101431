/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useAccountsV2CountsQuery } from "~/generated/graphql";

export function useGetProviderAccountsCount() {
  const { data, loading } = useAccountsV2CountsQuery();
  const hasAWS = Number(data?.AWS?.count) > 0;
  const hasAzure = Number(data?.AZURE?.count) > 0;

  const hasReservationsMenu = hasAWS || hasAzure;

  return {
    hasAWS,
    hasAzure,
    hasReservationsMenu,
    loading,
  };
}
