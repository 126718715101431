/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  FlexContainer,
  MultipleSelect,
  SelectButton,
  Text,
  theme,
} from "@nordcloud/gnui";
import { PreviousPeriod } from "~/constants";
import { MONTHLY_BUTTONS, YEARLY_BUTTONS } from "./constants";

type Props = {
  periodSelected: PreviousPeriod | undefined;
  handleClick: (value: PreviousPeriod) => void;
};

export function DatePreselects({ periodSelected, handleClick }: Props) {
  return (
    <>
      <Text weight="bold" mb={theme.spacing.spacing00}>
        Auto-select range
      </Text>
      <Text color={theme.color.text.text02} size="sm">
        Choose from the range we’ve selected for you.
      </Text>
      <FlexContainer mb={theme.spacing.spacing04}>
        <Text size="sm" mb="0" mr={theme.spacing.spacing04} weight="medium">
          Past:
        </Text>
        <MultipleSelect size="small">
          {MONTHLY_BUTTONS.map(({ name, value, labelText, enumValue }) => (
            <SelectButton
              key={`monthly-button-${value}`}
              name={name}
              labelText={labelText}
              value={value}
              isActive={periodSelected === enumValue}
              style={{
                borderRight: `1px solid ${theme.color.border.border02}`,
                minWidth: "6rem",
              }}
              onClick={() => handleClick(enumValue)}
            />
          ))}
        </MultipleSelect>
      </FlexContainer>
      <FlexContainer mb={theme.spacing.spacing04}>
        <Text size="sm" mb="0" mr={theme.spacing.spacing04} weight="medium">
          Year:
        </Text>
        <MultipleSelect size="small">
          {YEARLY_BUTTONS.map(({ name, value, labelText, enumValue }) => (
            <SelectButton
              key={`yearly-button-${value}`}
              name={name}
              labelText={labelText}
              value={value}
              isActive={periodSelected === enumValue}
              style={{
                borderRight: `1px solid ${theme.color.border.border02}`,
                minWidth: "6rem",
              }}
              onClick={() => handleClick(enumValue)}
            />
          ))}
        </MultipleSelect>
      </FlexContainer>
    </>
  );
}
