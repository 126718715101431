/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import {
  Box,
  ExtendedPaginationBox,
  FlexContainer,
  Input,
  Sidebar,
  Text,
  theme,
} from "@nordcloud/gnui";
import { useTagKeysQuery } from "~/generated/graphql";
import { LoaderWrap, Option } from "~/components";
import { SidebarItemList } from "~/components/SidebarSelector";
import { useToggle } from "~/hooks";
import { getFirstItem, isEmpty, isNotEmpty, isNotNil, noop } from "~/tools";
import { EstateGroupBy } from "./types";

type Props = {
  activeChart: EstateGroupBy;
  tagKey: string;
  periodStart: string;
  periodEnd: string;
  selectTagKey: (id: string) => void;
  isDisabled?: boolean;
};

const FETCH_LIMIT = 20;

const initialFilter = {
  page: 0,
  size: FETCH_LIMIT,
  search: "",
};

type Filter = {
  page: number;
  size: number;
  search: string;
};

export function TagKeySelector({
  activeChart,
  tagKey,
  periodStart,
  periodEnd,
  selectTagKey,
  isDisabled = false,
}: Props) {
  const [isKeySelectorOpen, toggleKeySelector] = useToggle(false);
  const [filter, setFilter] = useState<Filter>(initialFilter);

  const { data, loading, refetch } = useTagKeysQuery({
    variables: {
      startDate: periodStart ?? "",
      endDate: periodEnd ?? "",
      limit: FETCH_LIMIT,
      page: filter.page,
      query: filter.search,
    },
    skip: periodStart === undefined || periodEnd === undefined,
  });

  useEffect(() => {
    void refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodStart, periodEnd]);

  const tags = data?.listTagKeys?.tagKeys?.filter(isNotNil) ?? [];
  const options: Option[] = tags.map((tag) => {
    return {
      label: tag,
      value: tag,
    };
  });

  useEffect(() => {
    if (
      loading === false &&
      isNotEmpty(tags) &&
      activeChart === EstateGroupBy.TAG &&
      isEmpty(tagKey)
    ) {
      selectTagKey(getFirstItem(tags));
    }
    setFilter((prevFilter) => ({
      ...prevFilter,
      size: data?.listTagKeys?.count ?? 0,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, activeChart]);

  const handleSelect = (id: string) => {
    selectTagKey(id);
    toggleKeySelector();
  };

  const onPageChange = (page: number) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      page: Math.ceil(page / FETCH_LIMIT),
    }));
  };

  return (
    <>
      <Text
        size="sm"
        mb="0"
        mr={theme.spacing.spacing04}
        ml={theme.spacing.spacing04}
        weight="medium"
        color={theme.color.text.text02}
      >
        Tag key:
      </Text>
      <Input
        readOnly
        type="text"
        value={tagKey}
        placeholder="Select Tag Key"
        icon="sidebar"
        disabled={isDisabled}
        css={{ height: "20px" }}
        onClick={toggleKeySelector}
      />
      <Sidebar
        title="Select Tag Key"
        isOpen={isKeySelectorOpen}
        footer={
          <ExtendedPaginationBox
            sidebar
            small
            count={filter.size}
            from={filter.page * FETCH_LIMIT}
            setPage={onPageChange}
            setSize={noop}
            size={FETCH_LIMIT}
          />
        }
        onClick={toggleKeySelector}
      >
        <Box boxStyle="lightGrey">
          <FlexContainer>
            <div css={{ flexGrow: 1 }}>
              <Input
                small
                value={filter.search}
                type="text"
                placeholder="Search"
                onChange={(event) =>
                  setFilter((prevFilter) => ({
                    ...prevFilter,
                    search: event.target.value,
                    page: 0,
                  }))
                }
              />
            </div>
          </FlexContainer>
        </Box>
        <LoaderWrap inContent loading={loading}>
          <SidebarItemList items={options} onSubmit={handleSelect} />
        </LoaderWrap>
      </Sidebar>
    </>
  );
}
