/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Row, Col } from "react-awesome-styled-grid";
import { Link } from "react-router-dom";
import { Message, SVGIcon, Heading, theme } from "@nordcloud/gnui";
import { InfoCard } from "~/components";
import { ROUTES } from "~/routing/routes";

type Props = {
  id: string;
  onAddAnotherClick: () => void;
};

export function AddIntegrationSummary({ id, onAddAnotherClick }: Props) {
  return (
    <Row justify="center">
      <Col sm={6} md={6} lg={8}>
        <Message status="success" image="success">
          Turbonomic integration has been added
        </Message>
        <Row>
          <Col lg={4}>
            <InfoCard
              align="center"
              as={Link}
              variant="link"
              to={`${ROUTES.integrationPlugins.index}/${id}`}
            >
              <SVGIcon name="detailsView" size="xl" />
              <Heading marginTop={theme.spacing.spacing04} level={4}>
                Integration Details
              </Heading>
            </InfoCard>
          </Col>
          <Col lg={4}>
            <InfoCard
              align="center"
              as={Link}
              variant="link"
              to={ROUTES.integrationPlugins.index}
            >
              <SVGIcon name="listView" size="xl" />
              <Heading marginTop={theme.spacing.spacing04} level={4}>
                Integrations List
              </Heading>
            </InfoCard>
          </Col>
          <Col lg={4}>
            <InfoCard align="center" onClick={onAddAnotherClick}>
              <SVGIcon name="wizardView" size="xl" />
              <Heading marginTop={theme.spacing.spacing04} level={4}>
                Add Another Integration
              </Heading>
            </InfoCard>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
