/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { theme } from "@nordcloud/gnui";
import { QueryStateFilterValue } from "~/hooks";
import { getFirstItem, isNotNil } from "~/tools";
import { DataType, EnvType } from "./types";

const pluralFilters = ["category", "environment", "provider", "region"];
export const keyValueFilters = ["tags", "metadata"];

export function formatActiveFilterName(name: string) {
  switch (name) {
    case "category":
      return "Categories";
    case "wastePolicy":
      return "Waste Policies";
    case "tags":
      return "Resource Tags";
    case "metadata":
      return "Resource Metadata";
    case "discoveryTime":
      return "Discovery Time";
    case "lifeSpan":
      return "Resource Life Span";
    case "unallocated":
      return "Resource Unallocated";
    case "unassigned":
      return "Unallocated Estate Records";
    default:
      return formatCamelCase(name);
  }
}

function formatCamelCase(name: string) {
  const isNamePlural = pluralFilters.includes(name);
  const separatedName = name.replaceAll(/[A-Z]/g, " $&");
  const formattedName = separatedName[0].toUpperCase() + separatedName.slice(1);
  return isNamePlural ? `${formattedName}s` : formattedName;
}

export function getIndex(nid: string, data: EnvType[]) {
  return data.findIndex((item) => item.nid === nid);
}

export function mapValuesToArray(value: QueryStateFilterValue, filter: string) {
  if (keyValueFilters.includes(filter)) {
    return Array.isArray(value)
      ? value.map((item) => item.toString().replace(",", ": "))
      : [];
  }

  return Array.isArray(value) ? value.map((item) => item.toString()) : [];
}

export function mapValues(valueArray: string[], data: DataType[]) {
  return valueArray
    .map((element) => data.find((d) => d.nid === element))
    .filter(isNotNil);
}

export function mapAppEnvs(data: EnvType[]) {
  const apps = data.flatMap(({ appNid }) => appNid);
  const uniqueApps = [...new Set(apps)];

  return uniqueApps.map((app) => {
    const appEnvs = data.filter((env) => env.appNid === app);
    return {
      nid: app ?? "",
      name: getFirstItem(appEnvs).appName ?? "",
      envs: [...appEnvs],
    };
  });
}

export function getTextColor(isFilterExcluded: boolean) {
  return isFilterExcluded ? theme.color.support.red : undefined;
}
