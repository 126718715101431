/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { When } from "react-if";
import { generatePath, Link } from "react-router-dom";
import {
  Box,
  Button,
  ExtendedTooltip,
  FlexContainer,
  Spacer,
  Text,
  theme,
} from "@nordcloud/gnui";
import { DataContainer, DataItem } from "~/components";
import { dateFormat } from "~/constants";
import { ROUTES } from "~/routing/routes";
import { noop } from "~/tools";
import { useApplications } from "~/views/applications/ApplicationsPage/components/ApplicationsProvider";
import { MappedEnvironmentV2 } from "../types";
import { OrganizationalUnitCell } from "./OrganizationalUnitCell";

type Props = {
  environment: MappedEnvironmentV2;
};

export function EnvironmentCard({ environment }: Props) {
  const { applicationId } = useApplications();
  const today = dayjs().format(dateFormat.dayMonthYearShortNoComma);

  return (
    <Box boxStyle="lightGrey">
      <FlexContainer justifyContent="space-between">
        <Text as="header" mb="0">
          <Link
            to={generatePath(ROUTES.applications.environment, {
              application: applicationId,
              environment: environment.id,
            })}
          >
            {environment.name}
          </Link>
        </Text>
        <OrganizationalUnitCell
          orgUnits={environment.orgUnits}
          environmentId={environment.id}
        />
        <ExtendedTooltip caption="Delete Environment">
          <Button
            disabled
            type="button"
            icon="trash"
            severity="low"
            size="md"
            onClick={noop}
          />
        </ExtendedTooltip>
      </FlexContainer>
      <Spacer height={theme.spacing.spacing04} />
      <DataContainer marginBottom="0">
        <When condition={Number(environment.savingsSuggestion) > 0}>
          <DataItem
            value={environment.savingsSuggestion}
            label="Savings Suggestion"
            valueColor={
              Number(environment.savingsSuggestion) > 0
                ? theme.color.text.success
                : undefined
            }
          />
        </When>
        <DataItem value={environment.cost} label={`Costs (1 - ${today})`} />
        <DataItem value={environment.forecast} label="Monthly Forecast" />
        <DataItem value={environment.budget} label="Monthly Budget" />
      </DataContainer>
    </Box>
  );
}
