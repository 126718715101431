/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import jwt_decode from "jwt-decode";
import * as yup from "yup";
import { REQUIRED_FIELD_WARNING } from "../../constants";

type AzureJwtToken = {
  accountId?: string;
  departmentId?: string;
  enrollmentNumber?: string;
  id?: string;
  partnerId?: string;
  reportView?: string;
  aud: string;
  exp: number;
  iss: string;
  nbf?: number;
};

function validateAzureJwt(token: string) {
  const isJwtTokenQuery =
    "^([a-zA-Z0-9_=]+)\\.([a-zA-Z0-9_=]+)\\.([a-zA-Z0-9_\\-+\\/=]*)";

  if (!new RegExp(isJwtTokenQuery).test(token)) {
    return "This token is invalid.";
  }

  const decoded: AzureJwtToken = jwt_decode(token);
  const { iss, aud, exp } = decoded;

  if (iss !== "ea.microsoftazure.com") {
    return "This token is invalid, wrong issuer field.";
  }

  if (aud !== "client.ea.microsoftazure.com") {
    return "This token is invalid, wrong audience field.";
  }

  if (dayjs.unix(exp).diff(dayjs()) < 0) {
    return "This token is expired.";
  }

  if (dayjs.unix(exp).subtract(48, "hours").diff(dayjs()) < 0) {
    return "This token will expire in less than 48 hours.";
  }

  return true;
}

export const enum AzureFormFields {
  ENROLLMENT_NUMBER = "enrollmentNumber",
  ENROLLMENT_ACCESS_KEY = "enrollmentAccessKey",
  CAPACITOR_CUSTOMER_ID = "capacitorCustomerId",
}

export type FormData = {
  [AzureFormFields.ENROLLMENT_NUMBER]: string;
  [AzureFormFields.ENROLLMENT_ACCESS_KEY]: string;
};

export type UpdateFormData = FormData & {
  [AzureFormFields.CAPACITOR_CUSTOMER_ID]: string;
};

export const AzureFormSchema = yup.object().shape({
  [AzureFormFields.ENROLLMENT_NUMBER]: yup
    .string()
    .test({
      name: "is string of digits",
      message: "Enrollment number must be 7-8 digits.",
      test: (value) => new RegExp(/^\d{7,8}$/).test(value ?? ""),
    })
    .min(7, "Enrollment number must be 7-8 digits.")
    .max(8, "Enrollment number must be 7-8 digits.")
    .required(REQUIRED_FIELD_WARNING),
  [AzureFormFields.ENROLLMENT_ACCESS_KEY]: yup
    .string()
    .required(REQUIRED_FIELD_WARNING)
    .test("azureJwt", "This token is invalid.", function (value = "") {
      const result = validateAzureJwt(value);
      return result === true ? true : this.createError({ message: result });
    }),
});
