/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Case, Default, Switch } from "react-if";
import {
  ExtendedPopover,
  ExtendedTooltip,
  FlexContainer,
  ModalConfirm,
  SVGIcon,
  Text,
  theme,
} from "@nordcloud/gnui";
import { useRemoveEnvFromOrgUnitV2Mutation } from "~/generated/graphql";
import { SUCCESS_TEXT } from "~/constants";
import { useMutationHandler } from "~/hooks";
import { isNotNil } from "~/tools";
import { useApplications } from "~/views/applications/ApplicationsPage/components/ApplicationsProvider";
import { LinkButton } from "../styled";
import { MappedOrgUnitV2 } from "../types";
import { OrgUnitPopover } from "./OrgUnitPopover";
import { OrgUnitTag } from "./OrgUnitTag";

type Props = {
  orgUnits: MappedOrgUnitV2[];
  environmentId: string;
};

export function OrganizationalUnitCell({ orgUnits, environmentId }: Props) {
  const { runMutation } = useMutationHandler();
  const { openSidebar, setEnvironmentId, setApplicationId, applicationId } =
    useApplications();
  const [removeEnvFromOrgUnit] = useRemoveEnvFromOrgUnitV2Mutation();
  const [detachItem, setDetachItem] = useState<MappedOrgUnitV2 | null>(null);

  const { refetchEnvironments } = useApplications();

  const handleRemove = async (envId: string, orgUnitId: string) => {
    await runMutation({
      mutation: () =>
        removeEnvFromOrgUnit({
          variables: {
            envId,
            orgUnitId,
          },
        }).then(() => refetchEnvironments()),
      successText: SUCCESS_TEXT.environmentRemovedFromOrgUnit,
    });
    setDetachItem(null);
  };

  const onClick = () => {
    openSidebar();
    setEnvironmentId(environmentId);
    setApplicationId(applicationId);
  };

  return (
    <>
      <Switch>
        <Case condition={orgUnits.length < 2}>
          <FlexContainer columnGap={theme.spacing.spacing02}>
            {orgUnits.map((orgUnit) => (
              <OrgUnitTag
                key={orgUnit.id}
                orgUnit={orgUnit}
                onDetach={(item) => setDetachItem(item)}
              />
            ))}
            <ExtendedTooltip caption="Attach Environment">
              <LinkButton onClick={onClick}>
                <SVGIcon name="link" size="sm" />
              </LinkButton>
            </ExtendedTooltip>
          </FlexContainer>
        </Case>
        <Default>
          <ExtendedPopover
            position="center"
            placement="top"
            triggerOn="click"
            closeOn="hover"
            content={
              <OrgUnitPopover
                orgUnits={orgUnits}
                environmentId={environmentId}
                applicationId={applicationId}
                onDetach={(item) => setDetachItem(item)}
              />
            }
            trigger={
              <Text mb="0" css={{ cursor: "pointer" }} size="sm">
                {orgUnits.length} Organizational Units
              </Text>
            }
          />
        </Default>
      </Switch>
      <ModalConfirm
        isOpen={isNotNil(detachItem)}
        contentLabel="Detachment"
        actionLabel="Confirm"
        confirm={() => handleRemove(environmentId, detachItem?.id ?? "")}
        onClose={() => setDetachItem(null)}
      >
        Do you want to detach {detachItem?.name} from {detachItem?.envName}?
      </ModalConfirm>
    </>
  );
}
