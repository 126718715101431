/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col } from "react-awesome-styled-grid";
import { When } from "react-if";
import { generatePath, Link } from "react-router-dom";
import { FlexContainer, Text, theme } from "@nordcloud/gnui";
import { useUnallocatedSummaryQuery } from "~/generated/graphql";
import { DataContainer, DataItem, UniversalWrap } from "~/components";
import { useCurrency } from "~/hooks";
import { ROUTES } from "~/routing/routes";
import { formatMoney, isNotNil } from "~/tools";

export function UnallocatedSummary() {
  const { currency } = useCurrency();
  const { data, loading, error } = useUnallocatedSummaryQuery();

  const defaultApplication = data?.defaultApplication;
  const defaultEnvironment = data?.defaultEnvironment;
  const costs = defaultApplication?.cost;
  const savingsSuggestions = defaultApplication?.cloudWasteAggregate;
  const unallocatedCount = defaultEnvironment?.resourceCount ?? 0;

  return (
    <UniversalWrap loaderProps={{ loading }} errorProps={{ error }}>
      <DataContainer width="100%">
        <DataItem
          grow={false}
          label="Unallocated Estate Records Cost"
          value={formatMoney(costs?.currentMonth, currency)}
          valueFontWeight={theme.fontWeights.medium}
          itemWidth="20%"
        />
        <DataItem
          grow={false}
          label="Unallocated Estate Records Forecast"
          value={formatMoney(costs?.forecast, currency)}
          valueFontWeight={theme.fontWeights.medium}
          itemWidth="20%"
        />
        <Col
          css={{
            flexGrow: 0,
            minWidth: "20%",
            width: "20%",
          }}
        >
          <Text isTitle size="sm" tag="span" color={theme.color.text.text02}>
            Corresponding Savings Suggestions
          </Text>
          <Text
            weight="medium"
            css={{ overflowWrap: "break-word" }}
            mb={0}
            color={
              savingsSuggestions?.hasWaste
                ? theme.color.text.success
                : undefined
            }
          >
            {formatMoney(savingsSuggestions?.cost, currency)}
          </Text>
        </Col>
        <Col
          css={{
            flexGrow: 0,
            minWidth: "20%",
            width: "20%",
          }}
        >
          <Text isTitle size="sm" tag="span" color={theme.color.text.text02}>
            Unallocated Estate Records
          </Text>
          <FlexContainer columnGap={theme.spacing.spacing03}>
            <Text weight="medium" css={{ overflowWrap: "break-word" }} mb={0}>
              {unallocatedCount}
            </Text>
            <When
              condition={isNotNil(unallocatedCount) && unallocatedCount > 0}
            >
              <Link
                to={`${generatePath(
                  ROUTES.estateRecords.index
                )}?page=0&unassigned=true`}
              >
                <Text size="sm" mb={0} color={theme.color.interactive.link}>
                  Show Resources
                </Text>
              </Link>
            </When>
          </FlexContainer>
        </Col>
      </DataContainer>
    </UniversalWrap>
  );
}
