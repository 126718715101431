/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState, useRef, useEffect, useCallback } from "react";
import { useApplicationsPaginatedListLazyQuery } from "~/generated/graphql";
import { getFirstItem } from "~/tools";
import { formatDataObject } from "~/utils";

type ApplicationOption = {
  label: string;
  value: string;
};

const FETCH_LIMIT = 20;

export function useInfiniteScroll() {
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [applications, setApplications] = useState<ApplicationOption[]>([]);

  const elementRef = useRef(null);

  const [getApplications, { loading }] = useApplicationsPaginatedListLazyQuery({
    variables: {
      limit: FETCH_LIMIT,
      page,
    },
  });

  const fetchData = useCallback(async () => {
    const { data } = await getApplications();

    const formattedData = formatDataObject(data);

    setApplications((prevApplications) => {
      const newApplications = formattedData.applicationsPaginated.results.map(
        (app) => ({
          label: app.name,
          value: app.nid,
        })
      );

      return [...prevApplications, ...newApplications].sort((a, b) =>
        a.label.localeCompare(b.label)
      );
    });

    const hasMoreData =
      (formattedData.applicationsPaginated.results ?? []).length >= FETCH_LIMIT;
    setHasMore(hasMoreData);

    if (hasMoreData) {
      setPage((prevPage) => prevPage + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (getFirstItem(entries).isIntersecting && hasMore) {
          void fetchData();
        }
      },
      { threshold: 0 }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [fetchData, hasMore]);

  return {
    loading,
    elementRef,
    applications,
  };
}
