/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback } from "react";
import { When } from "react-if";
import { ReservationsAndCommitmentsFilters } from "~/generated/graphql";
import {
  Fieldset,
  FilterWrapper,
  SelectableListWithSearch,
} from "~/components";
import { isArrayFilterSelected } from "~/utils";
import { ResourceLabel } from "../../constants";

type RegionOptions = {
  value: string;
  label: string;
};

type InstanceFamilies = {
  value: string;
  label: string;
};

type Props = {
  instanceFamilies: InstanceFamilies[];
  regionOptions: RegionOptions[];
  filters: ReservationsAndCommitmentsFilters;
  setFilters: (filter: ReservationsAndCommitmentsFilters) => void;
  showInstanceFamilyFilter: boolean;
  familyLabel: ResourceLabel;
};

export function CommonFilters({
  instanceFamilies,
  regionOptions,
  filters,
  setFilters,
  showInstanceFamilyFilter,
  familyLabel,
}: Props) {
  const handleInstanceMultiSelect = useCallback(
    (instanceFamily: string[]) => {
      setFilters({
        ...filters,
        instanceFamily,
      });
    },
    [filters, setFilters]
  );
  const handleRegionMultiSelect = useCallback(
    (region: string[]) => {
      setFilters({
        ...filters,
        region,
      });
    },
    [filters, setFilters]
  );

  const handleInstanceClear = useCallback(() => {
    setFilters({ ...filters, instanceFamily: undefined });
  }, [setFilters, filters]);

  const handleRegionClear = useCallback(() => {
    setFilters({ ...filters, region: undefined });
  }, [setFilters, filters]);

  return (
    <>
      <When condition={showInstanceFamilyFilter}>
        <Fieldset>
          <FilterWrapper
            name={familyLabel}
            isSelected={isArrayFilterSelected(filters?.instanceFamily ?? [])}
            selectedOptions={filters?.instanceFamily ?? []}
            onClear={handleInstanceClear}
          >
            <SelectableListWithSearch
              selectableItems={instanceFamilies}
              selectedOptions={filters?.instanceFamily ?? []}
              onMultiSelect={handleInstanceMultiSelect}
            />
          </FilterWrapper>
        </Fieldset>
      </When>
      <Fieldset>
        <FilterWrapper
          name="Region"
          isSelected={isArrayFilterSelected(filters?.region ?? [])}
          selectedOptions={filters?.region ?? []}
          onClear={handleRegionClear}
        >
          <SelectableListWithSearch
            selectableItems={regionOptions}
            selectedOptions={filters?.region ?? []}
            onMultiSelect={handleRegionMultiSelect}
          />
        </FilterWrapper>
      </Fieldset>
    </>
  );
}
