/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useMemo } from "react";
import {
  useEnvironmentOtherCategoriesCostQuery,
  useEnvironmentTopCategoriesCostQuery,
} from "~/generated/graphql";
import {
  extractCategoryIds,
  getTableGranularity,
  mapGranularity,
  Granularity,
  Period,
  PlaceholderCategory,
  TableData,
  topSpendingGroup,
  generateTopGroupData,
  generateTopTableData,
} from "~/components";
import { isNotNil } from "~/tools";
import { getCostSplitGroupNames } from "~/views/applications/ApplicationCostTabs/hooks/utils";

type Props = {
  environmentId: string;
  period: Period;
  startDate: string;
  endDate: string;
  granularity: Granularity;
};

export function useEnvCostPerCategoriesChart({
  environmentId,
  period,
  startDate,
  endDate,
  granularity,
}: Props) {
  const commonCostQueryVariables = {
    top: topSpendingGroup.CATEGORIES,
    environmentId,
    startDate,
    endDate,
  };

  const {
    data: environmentTopCategoriesCostResponse,
    loading: isCostLoading,
    error: costError,
  } = useEnvironmentTopCategoriesCostQuery({
    variables: {
      ...commonCostQueryVariables,
      granularity: mapGranularity(granularity),
    },
  });

  const {
    data: environmentTopCategoriesCostTableResponse,
    loading: isCostTableLoading,
    error: costTableError,
  } = useEnvironmentTopCategoriesCostQuery({
    variables: {
      ...commonCostQueryVariables,
      granularity: getTableGranularity(granularity, period),
    },
    skip: !environmentTopCategoriesCostResponse,
  });

  const {
    data: environmentOtherCategoriesCostTableResponse,
    loading: isOtherCategoriesLoading,
    error: otherCategoriesError,
  } = useEnvironmentOtherCategoriesCostQuery({
    variables: {
      input: {
        top: topSpendingGroup.CATEGORIES,
        environmentId,
        startDate,
        endDate,
        granularity: mapGranularity(Granularity.MONTHS),
      },
    },
  });

  const environmentTopCategoriesCostTableTimePoints = useMemo(
    () =>
      (
        environmentTopCategoriesCostTableResponse?.environmentTopCategoriesCost
          .timePoints ?? []
      ).filter(isNotNil),
    [environmentTopCategoriesCostTableResponse]
  );

  const costPerCategoryTableData: TableData[] = useMemo(
    () =>
      generateTopGroupData(environmentTopCategoriesCostTableTimePoints ?? []),
    [environmentTopCategoriesCostTableTimePoints]
  );

  const environmentTopCategoriesCostChartData = useMemo(
    () =>
      (
        environmentTopCategoriesCostResponse?.environmentTopCategoriesCost
          ?.timePoints ?? []
      ).filter(isNotNil),
    [environmentTopCategoriesCostResponse]
  );

  const costPerCategoryTotal = Number(
    environmentTopCategoriesCostTableResponse?.environmentTopCategoriesCost
      ?.total ?? "0"
  );

  const environmentOtherCategoriesCostTableData = (
    environmentOtherCategoriesCostTableResponse?.environmentOtherCategoriesCost
      ?.timePoints ?? []
  ).filter(isNotNil);

  const costPerOtherCategoryTableData: TableData[] = useMemo(
    () => generateTopTableData(environmentOtherCategoriesCostTableData ?? []),
    [environmentOtherCategoriesCostTableData]
  );

  const getSubRows = useCallback(
    (originalRow: TableData): TableData[] =>
      originalRow.field === PlaceholderCategory.OTHER.name
        ? costPerOtherCategoryTableData
        : [],
    [costPerOtherCategoryTableData]
  );

  const environments = getCostSplitGroupNames(costPerCategoryTableData);

  const otherCategoryIds = extractCategoryIds(
    environmentOtherCategoriesCostTableData
  );

  const isLoading =
    isCostLoading || isCostTableLoading || isOtherCategoriesLoading;
  const error = costError || costTableError || otherCategoriesError;

  return {
    environmentTopCategoriesCostChartData,
    costPerCategoryTableData,
    costPerCategoryTotal,
    loading: isLoading,
    error,
    getSubRows,
    environments,
    otherCategoryIds,
  };
}
