/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { Button } from "@nordcloud/gnui";
import { Role } from "~/generated/graphql";
import { BreadcrumbsBox, UniversalWrap, UnstyledLink } from "~/components";
import { ROUTES } from "~/routing/routes";
import { ACTIONS, Can } from "~/services/auth";
import { useCustomers } from "~/services/customers";
import { ApplicationListWrapper } from "./components/ApplicationListWrapper";
import { CostAllocationV2 } from "./components/CostAllocationV2";

export function ApplicationsPageV2() {
  const { userRole } = useCustomers();
  const isLoading = false;
  const error = undefined;

  return (
    <UniversalWrap loaderProps={{ loading: isLoading }} errorProps={{ error }}>
      <BreadcrumbsBox title="Applications">
        <Can action={ACTIONS.addApplication}>
          <UnstyledLink to={ROUTES.applications.create}>
            <Button icon="plus">Add new Application</Button>
          </UnstyledLink>
        </Can>
      </BreadcrumbsBox>
      <When condition={userRole !== Role.TeamLead}>
        <CostAllocationV2 />
      </When>
      <ApplicationListWrapper />
    </UniversalWrap>
  );
}
