/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const dateBasedFilters = [
  "firstSeenAtFrom",
  "firstSeenAtTo",
  "lifeSpanFrom",
  "lifeSpanTo",
  "assignmentUpdatedFrom",
  "assignmentUpdatedTo",
];

export const costBasedFilters = ["costFrom", "costTo"];

export enum MappedFilterName {
  DISCOVERY = "discoveryTime",
  LIFE_SPAN = "lifeSpan",
  MONTHLY_COST = "monthlyCost",
  UNALLOCATED = "unallocated",
}
