/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useUpdateAwsAccountCredentialsMutation } from "~/generated/graphql";
import { Provider } from "../../types";
import { UpdateHandlerVariables } from "../types";

export function useUpdateAwsAccountCredentials() {
  const [updateAwsCredentials, ...mutationState] =
    useUpdateAwsAccountCredentialsMutation();

  const handleAwsCredentialsUpdate = async (
    variables: UpdateHandlerVariables<Provider.Aws>,
    selectedNid: string
  ) => {
    const { roleName, externalId } = variables;

    await updateAwsCredentials({
      variables: {
        id: selectedNid,
        roles: [{ roleName, externalId }],
      },
    });
  };

  return [handleAwsCredentialsUpdate, mutationState] as const;
}
