/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import {
  ChartJobTableQuery,
  ChartJobTableTimePoint,
  Role,
} from "~/generated/graphql";
import {
  ChartType as CostAnalysisChartType,
  CostAnalysisFields,
  generateLinkParameters,
  getCostAnalysisTableColumns,
  Granularity,
  LinkQueryParameterName,
  mapStackBarChartColors,
  TableData,
} from "~/components";
import { EstatePeriod } from "~/constants";
import { useCurrency } from "~/hooks";
import { useCustomers } from "~/services/customers";
import { isNotNil } from "~/tools";
import {
  getExtraCostDetails,
  reorderGroups,
} from "~/views/estate/components/Charts/helpers";
import { mapEstatePeriodToPeriod } from "~/views/estate/helpers";
import { ChartJobData } from "../types";
import {
  generateDataWithSavingPlanCommitments,
  generateEstateRecordsGroups,
  getCategories,
} from "./helpers";

type Props = {
  chartData: ChartJobData | undefined;
  tableData: ChartJobTableQuery | undefined;
  granularity: Granularity;
  period: EstatePeriod;
  startDate: string;
  endDate: string;
  chartType: CostAnalysisChartType;
};

export function useEstateRecordsCost({
  chartData,
  tableData,
  granularity,
  period,
  startDate,
  endDate,
  chartType,
}: Props) {
  const { currency } = useCurrency();
  const { userRole } = useCustomers();
  const isAccumulatedCostChart =
    chartType === CostAnalysisChartType.ACCUMULATED_COST;

  const granularData = useMemo(
    () =>
      granularity === Granularity.DAYS
        ? chartData?.chartJob?.data
        : chartData?.chartMonthlyJob?.data,
    [chartData, granularity]
  );

  const costPerGroupChartData = useMemo(
    () =>
      generateDataWithSavingPlanCommitments(
        granularData?.timePoints ?? [],
        isAccumulatedCostChart
      ),
    [granularData, isAccumulatedCostChart]
  );

  const categoriesData = useMemo(
    () =>
      generateDataWithSavingPlanCommitments(
        chartData?.chartMonthlyJob?.data?.timePoints ?? [],
        isAccumulatedCostChart
      ),
    [chartData, isAccumulatedCostChart]
  );

  const costTableTimePoints = useMemo(
    () => (tableData?.chartJobTable?.data?.results ?? []).filter(isNotNil),
    [tableData]
  );

  const mappedPeriod = mapEstatePeriodToPeriod(period);

  const totalCost = Number(chartData?.chartJob?.data?.total ?? "0");

  const categories = getCategories(categoriesData, granularity);

  const costPerGroupTableData: TableData[] = useMemo(() => {
    const showTotalRow = period === EstatePeriod.RANGE;
    return isAccumulatedCostChart
      ? generateAccumulatedCostTableData(
          tableData?.chartJobTable?.data?.results?.filter(isNotNil),
          totalCost
        )
      : generateEstateRecordsGroups(costTableTimePoints ?? [], showTotalRow);
  }, [costTableTimePoints, period]);

  const tableCount = tableData?.chartJobTable?.data?.count ?? 0;

  const chartKeys = useMemo(() => [...reorderGroups(categories)], [categories]);

  const stackBarColors = useMemo(
    () => mapStackBarChartColors(chartKeys),
    [chartKeys]
  );

  const allColumns = useMemo(
    () =>
      getCostAnalysisTableColumns({
        startDate,
        endDate,
        currency,
        data: costPerGroupTableData,
        period: mappedPeriod,
        chartType,
        colors: stackBarColors,
        linkParameters: getLinkParameters(chartType, userRole),
        headerName: mapHeaderName(chartType),
      }),
    [
      startDate,
      endDate,
      currency,
      costPerGroupTableData,
      stackBarColors,
      chartType,
      mappedPeriod,
    ]
  );

  const extraDetails = getExtraCostDetails(
    chartData?.chartJob?.data?.extraDetails ?? []
  );

  const firstColumnWidth = period !== EstatePeriod.RANGE ? "30%" : undefined;

  return {
    costPerGroupChartData,
    costPerGroupTableData,
    totalCost,
    tableCount,
    extraDetails,
    allColumns,
    stackBarColors,
    chartKeys,
    firstColumnWidth,
  };
}

function mapHeaderName(chartType: CostAnalysisChartType) {
  switch (chartType) {
    case CostAnalysisChartType.COST_PER_PROVIDER:
      return "Provider";
    case CostAnalysisChartType.COST_PER_REGION:
      return "Region";
    case CostAnalysisChartType.COST_PER_APPLICATION:
      return "Application Name";
    case CostAnalysisChartType.COST_PER_ENVIRONMENT_TYPE:
      return "Environment Type";
    case CostAnalysisChartType.COST_PER_ACCOUNT:
      return "Cloud Account";
    default:
      return "";
  }
}

function getLinkParameters(chartType: CostAnalysisChartType, userRole: Role) {
  if (chartType === CostAnalysisChartType.COST_PER_APPLICATION) {
    return generateLinkParameters(LinkQueryParameterName.APPLICATION, []);
  }
  if (
    chartType === CostAnalysisChartType.COST_PER_ACCOUNT &&
    userRole !== Role.TeamLead
  ) {
    return generateLinkParameters(LinkQueryParameterName.ACCOUNT, []);
  }
  return undefined;
}

function generateAccumulatedCostTableData(
  timePoints: ChartJobTableTimePoint[] | undefined,
  accumulatedCostTotal: number
) {
  return timePoints
    ? [
        timePoints.reduce(
          (newPoint, currentPoint) => {
            return { ...newPoint, [currentPoint.date]: currentPoint.total };
          },
          {
            field: CostAnalysisFields.MONTHLY_COST,
            total: accumulatedCostTotal,
          }
        ),
      ]
    : [];
}
