/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Box } from "@nordcloud/gnui";
import { Provider } from "~/generated/graphql";
import { Costs } from "../types";
import { ReservationHeader } from "./ReservationHeader";
import { GridWrapper } from "./styles";
import { CloudIcon } from "./types";

type Props = {
  name: string;
  providerId: string;
  providerType: string;
  account: string;
  costs: Costs;
  cloudIcon: CloudIcon;
  color: string;
  provider: Provider;
  upfrontCost: number;
};

export function ReservationInstanceHeader({
  name,
  providerId,
  providerType,
  account,
  costs,
  cloudIcon,
  color,
  provider,
  upfrontCost,
}: Props) {
  return (
    <Box>
      <GridWrapper>
        <ReservationHeader
          name={name}
          providerId={providerId}
          providerType={providerType}
          account={account}
          costs={costs}
          cloudIcon={cloudIcon}
          color={color}
          provider={provider}
          upfrontCost={upfrontCost}
        />
      </GridWrapper>
    </Box>
  );
}
