/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Link } from "react-router-dom";
import { FlexContainer } from "@nordcloud/gnui";
import { Detail, DetailData } from "~/components/Details/Detail";
import {
  DetailsDescription,
  DetailsItem,
  DetailsTitle,
} from "~/components/Details/styles";
import { isNotNil } from "~/tools";
import { LinkedResource } from "~/views/estate/EstateDetailsPage/components/ReallocatedCostsSidebar";
import {
  getCloudResource,
  isPodInstance,
  isPVInstance,
} from "~/views/estate/EstateDetailsPage/utils";

type PVCProp = {
  linkedResources: LinkedResource[];
};

export function PersistentVolumeClaimDetails({ linkedResources }: PVCProp) {
  const pv = linkedResources
    ?.map(({ sourceRecord }) => getCloudResource(sourceRecord))
    .filter(isNotNil)
    .find(({ providerType }) => isPVInstance(providerType ?? ""));

  const pods = linkedResources
    ?.map(({ targetRecord }) => getCloudResource(targetRecord))
    .filter(isNotNil)
    .filter(({ providerType }) => isPodInstance(providerType ?? ""));

  const pvDetail: DetailData = {
    label: "Persistent Volume",
    value: pv?.name,
    target: `/estate/${pv?.id ?? ""}`,
  };

  return (
    <>
      <Detail key={pvDetail.label} detail={pvDetail} />
      <DetailsItem>
        <DetailsTitle>Pods</DetailsTitle>
        <DetailsDescription>
          <FlexContainer direction="column" alignItems="start">
            {pods.map((pod) => (
              <Link
                key={pod.id}
                data-testid={`link-${pod.id}`}
                to={`/estate/${pod.id}`}
              >
                {pod.name}
              </Link>
            ))}
          </FlexContainer>
        </DetailsDescription>
      </DetailsItem>
    </>
  );
}
