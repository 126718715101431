/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { PropsWithChildren } from "react";
import { Box, Text, theme } from "@nordcloud/gnui";
import { TableWrapper } from "../styles";

export function BenefitsContainer({ children }: PropsWithChildren<{}>) {
  return (
    <Box css={{ padding: theme.spacing.spacing00 }}>
      <Text weight="medium">
        Estate Records benefited from Reservations And Commitments
      </Text>
      <TableWrapper>{children}</TableWrapper>
    </Box>
  );
}
