/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import {
  ChartTableSortField,
  ChartType,
  useSubmitChartJobMutation,
} from "~/generated/graphql";
import { dateFormat, EstatePeriod } from "~/constants";
import { useFilteredQueryState, useQueryState } from "~/hooks";
import { estateV2GroupByFields } from "~/hooks/useEstateFetchV2";
import { filterWasteFilter } from "~/views/estate/components/helpers";
import { convertTags } from "~/views/estate/helpers";
import { EstateChartTableQueryState } from "../types";
import { ChartJobProps, EstateQueryState } from "./constants";
import { getSortDirection } from "./helpers";
import { useChartJob } from "./useChartJob";
import { useChartTableJob } from "./useChartJobTable";

export function useEstateRecordsGroupByChartJob({
  chartType,
  range,
}: ChartJobProps) {
  const startDate = range.from;
  const endDate = range.to;

  const [jobId, setJobId] = useState("");

  const {
    state: { estatePeriod },
  } = useQueryState<EstateChartTableQueryState>();

  const { state } = useFilteredQueryState(estateV2GroupByFields);

  // @ts-expect-error ignored temporary after migration to TypeScript 4.9
  const { limit, page, query, ...restFilters }: EstateQueryState = state;

  const estateFilters = {
    filter: {
      ...filterWasteFilter(restFilters),
      metadata: convertTags(restFilters.metadata),
      tags: convertTags(restFilters.tags),
    },
  };

  const [submitChartJob, { loading: isSubmitLoading }] =
    useSubmitChartJobMutation({
      context: {
        headers: {
          "x-billing-period": dayjs(range.from).format(dateFormat.yearMonth),
        },
      },
      onCompleted: (result) => setJobId(result?.submitChartJobV2?.jobId ?? ""),
    });

  const {
    isLoading: isChartLoading,
    data: chartData,
    error: chartError,
  } = useChartJob(jobId);

  const {
    isLoading: isTableLoading,
    data: tableData,
    error: tableError,
    field,
    order,
    onSortSelect,
    onSortOrderChange,
  } = useChartTableJob({
    jobId,
    limit,
    page,
    query,
    skip:
      chartType === ChartType.Accumulated &&
      estatePeriod !== EstatePeriod.RANGE,
  });

  type SubmitChartParams = Parameters<typeof submitChartJob>[0];

  const refetch = useCallback(
    (params?: Partial<NonNullable<SubmitChartParams>["variables"]>) => {
      void submitChartJob({
        variables: {
          filter: params?.filter,
          startDate: params?.startDate ?? "",
          endDate: params?.endDate ?? "",
          chartType: params?.chartType,
        },
      });
    },
    [submitChartJob]
  );

  useEffect(
    () => {
      refetch({
        startDate,
        endDate,
        chartType,
        ...estateFilters,
      });
    },
    // `estateFilters` might get recreated on every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startDate, endDate, chartType, JSON.stringify(estateFilters)]
  );

  const isChartDataLoading = isSubmitLoading || isChartLoading;
  const isTableDataLoading = isSubmitLoading || isTableLoading;
  const isDataLoading = isChartDataLoading && isTableDataLoading;

  return useMemo(
    () => ({
      startDate,
      endDate,
      chartData,
      chartError,
      isChartDataLoading,
      isTableDataLoading,
      isDataLoading,
      tableData,
      tableError,
      sortField: field ?? ChartTableSortField.Cost,
      sortDirection: getSortDirection(order),
      jobId,
      onSortSelect,
      onSortOrderChange,
    }),
    [
      startDate,
      endDate,
      chartData,
      chartError,
      isChartDataLoading,
      isTableDataLoading,
      isDataLoading,
      tableData,
      tableError,
      field,
      order,
      jobId,
      onSortOrderChange,
      onSortSelect,
    ]
  );
}
