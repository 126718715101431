/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import {
  FlexContainer,
  theme,
  Text,
  Label,
  Input,
  Button,
  Spacer,
} from "@nordcloud/gnui";
import { ExternalLink, FormGroup, FormHint } from "~/components";
import { SUCCESS_TEXT } from "~/constants";
import { showSuccess } from "~/services/toast";
import { getFirstItem, handleError, toInputError } from "~/tools";
import { AZURE_COMMAND } from "../../constants";
import { Provider } from "../../types";
import { CopyBox } from "../components";
import { AZURE_LINK, AzureFormField } from "../constants";
import { useBatchUpdateAzureAccountCredentials } from "../hooks/useBatchUpdateAzureAccountCredentials";
import { useUpdateAzureAccountCredentials } from "../hooks/useUpdateAzureAccountCredentials";
import { UpdateHandlerVariables } from "../types";
import { azureCredentialsSchema } from "../validators";

type Props = {
  onClose: () => void;
  nids: string[];
};

export function AzureUpdateCredentialsForm({ onClose, nids }: Props) {
  const [updateBatchCredentials] = useBatchUpdateAzureAccountCredentials();
  const [updateCredentials] = useUpdateAzureAccountCredentials();
  const formMethods = useForm<UpdateHandlerVariables<Provider.Azure>>({
    resolver: yupResolver(azureCredentialsSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const onSubmit = async ({
    tenantId,
    password,
    appId,
  }: UpdateHandlerVariables<Provider.Azure>) => {
    try {
      if (nids.length === 1) {
        await updateCredentials(
          {
            tenantId,
            password,
            appId,
          },
          getFirstItem(nids)
        );
      } else {
        await updateBatchCredentials(
          {
            tenantId,
            password,
            appId,
          },
          nids
        );
      }
      showSuccess(SUCCESS_TEXT.accountCredentialsUpdated);
      onClose();
    } catch (err) {
      handleError(toInputError(err));
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form
        css={{ width: "100%" }}
        id="AZURE-cred-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FlexContainer
          css={{ width: "100%" }}
          alignItems="flex-start"
          direction="column"
          gap={theme.spacing.spacing03}
        >
          <FormHint stepNumber={1}>
            <Text tag="div">
              Sign in to the{" "}
              <ExternalLink href={AZURE_LINK}>Microsoft Azure</ExternalLink>
            </Text>
          </FormHint>
          <FormHint stepNumber={2}>
            <FlexContainer direction="column" alignItems="flex-start">
              <Text tag="div">
                Run Azure Cloud Shell and execute the following command:
              </Text>
              <Spacer height={theme.spacing.spacing02} />
              <CopyBox>{AZURE_COMMAND}</CopyBox>
            </FlexContainer>
          </FormHint>
          <FormHint stepNumber={3}>
            <FlexContainer
              css={{ width: "100%" }}
              direction="column"
              alignItems="flex-start"
            >
              <Text tag="div">
                The output will include credentials that you should provide
                below
              </Text>
              <FlexContainer
                css={{ width: "100%" }}
                pt={theme.spacing.spacing04}
                pb={theme.spacing.spacing02}
                gap={theme.spacing.spacing02}
                direction="column"
                alignItems="flex-start"
              >
                <FormGroup error={errors[AzureFormField.APP_ID]}>
                  <Label
                    required
                    name="App ID"
                    htmlFor={AzureFormField.APP_ID}
                  />
                  <Input
                    {...register(AzureFormField.APP_ID)}
                    id={AzureFormField.APP_ID}
                    placeholder="e.g. b7cb6335-43b6-45ed-8bcc-5b3a6541ffb2"
                  />
                </FormGroup>
                <FormGroup error={errors[AzureFormField.PASSWORD]}>
                  <Label
                    required
                    name="Password"
                    htmlFor={AzureFormField.PASSWORD}
                  />
                  <Input
                    type="password"
                    {...register(AzureFormField.PASSWORD)}
                    id={AzureFormField.PASSWORD}
                  />
                </FormGroup>
                <FormGroup error={errors[AzureFormField.TENANT_ID]}>
                  <Label
                    required
                    name="Tenant"
                    htmlFor={AzureFormField.TENANT_ID}
                  />
                  <Input
                    {...register(AzureFormField.TENANT_ID)}
                    id={AzureFormField.TENANT_ID}
                    placeholder="e.g. 4rcb6335-43b6-45ed-8bcc-5b3a6541fdd0"
                  />
                </FormGroup>
              </FlexContainer>
            </FlexContainer>
          </FormHint>
        </FlexContainer>
        <FlexContainer gap={theme.spacing.spacing04}>
          <Button type="submit" icon="checkmark">
            Apply
          </Button>
          <Button severity="low" onClick={onClose}>
            Cancel
          </Button>
        </FlexContainer>
      </form>
    </FormProvider>
  );
}
