/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import {
  ChartTableSortField,
  ChartType,
  useSubmitTagsChartJobMutation,
} from "~/generated/graphql";
import { dateFormat, EstatePeriod } from "~/constants";
import { useFilteredQueryState, useQueryState } from "~/hooks";
import { estateV2GroupByFields } from "~/hooks/useEstateFetchV2";
import { isNotEmpty } from "~/tools";
import { filterWasteFilter } from "~/views/estate/components/helpers";
import { convertTags } from "~/views/estate/helpers";
import { EstateChartTableQueryState } from "../types";
import { ChartJobProps, EstateQueryState } from "./constants";
import { getSortDirection } from "./helpers";
import { useChartJob } from "./useChartJob";
import { useChartTableJob } from "./useChartJobTable";

export function useEstateRecordsTagsChartJob({
  chartType,
  range,
}: ChartJobProps) {
  const startDate = range.from;
  const endDate = range.to;

  const [jobId, setJobId] = useState("");

  const {
    state: { estatePeriod, groupByTagKey },
  } = useQueryState<EstateChartTableQueryState>();

  const tagKeyValue = groupByTagKey ?? "";

  const { state } = useFilteredQueryState(estateV2GroupByFields);

  // @ts-expect-error ignored temporary after migration to TypeScript 4.9
  const { limit, page, query, ...restFilters }: EstateQueryState = state;

  const estateFilters = {
    filter: {
      ...filterWasteFilter(restFilters),
      metadata: convertTags(restFilters.metadata),
      tags: convertTags(restFilters.tags),
    },
  };

  const [submitTagsChartJob, { loading: isTagsSubmitLoading }] =
    useSubmitTagsChartJobMutation({
      context: {
        headers: {
          "x-billing-period": dayjs(range.from).format(dateFormat.yearMonth),
        },
      },
      onCompleted: (result) =>
        setJobId(result?.submitTagsChartJob?.jobId ?? ""),
    });

  const {
    isLoading: isChartLoading,
    data: chartData,
    error: chartError,
  } = useChartJob(jobId);

  const {
    isLoading: isTableLoading,
    data: tableData,
    error: tableError,
    field,
    order,
    onSortSelect,
    onSortOrderChange,
  } = useChartTableJob({
    jobId,
    limit,
    page,
    query,
    skip:
      chartType === ChartType.Accumulated &&
      estatePeriod !== EstatePeriod.RANGE,
  });

  type SubmitTagChartParams = Parameters<typeof submitTagsChartJob>[0];

  const refetchTags = useCallback(
    (params?: Partial<NonNullable<SubmitTagChartParams>["variables"]>) => {
      void submitTagsChartJob({
        variables: {
          filter: params?.filter,
          startDate: params?.startDate ?? "",
          endDate: params?.endDate ?? "",
          tagKey: params?.tagKey ?? "",
        },
      });
    },
    [submitTagsChartJob]
  );

  useEffect(
    () => {
      if (isNotEmpty(tagKeyValue)) {
        refetchTags({
          startDate,
          endDate,
          tagKey: tagKeyValue,
          ...estateFilters,
        });
      }
    },
    // `estateFilters` might get recreated on every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      startDate,
      endDate,
      chartType,
      JSON.stringify(estateFilters),
      groupByTagKey,
    ]
  );

  const isChartDataLoading = isTagsSubmitLoading || isChartLoading;
  const isTableDataLoading = isTagsSubmitLoading || isTableLoading;
  const isDataLoading = isChartDataLoading && isTableDataLoading;

  return useMemo(
    () => ({
      startDate,
      endDate,
      chartData,
      chartError,
      isChartDataLoading,
      isTableDataLoading,
      isDataLoading,
      tableData,
      tableError,
      sortField: field ?? ChartTableSortField.Cost,
      sortDirection: getSortDirection(order),
      jobId,
      onSortSelect,
      onSortOrderChange,
    }),
    [
      startDate,
      endDate,
      chartData,
      chartError,
      isChartDataLoading,
      isTableDataLoading,
      isDataLoading,
      tableData,
      tableError,
      field,
      order,
      jobId,
      onSortOrderChange,
      onSortSelect,
    ]
  );
}
