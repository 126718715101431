/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState, useEffect } from "react";
import { ApolloError } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useForm,
  useWatch,
  FormProvider,
  SubmitHandler,
} from "react-hook-form";
import { Else, If, Then } from "react-if";
import { useToggle } from "react-use";
import styled from "styled-components";
import {
  FlexContainer,
  theme,
  MultipleSelect,
  SelectButton,
  BrickLoader,
  Button,
  Text,
  Spacer,
} from "@nordcloud/gnui";
import { UniversalWrap } from "~/components";
import { awsAutoFormDefaultValues } from "../constants";
import { AwsAutoForm } from "./AwsAutoForm";
import { AwsManualForm } from "./AwsManualForm";
import { AwsFormSchema, AwsFormFields, FormData } from "./validators";

type Props = {
  showRegionDisclaimer?: boolean;
  loading: boolean;
  defaultValues?: FormData;
  disabledFields?: (keyof FormData)[];
  showDivider?: boolean;
  error?: ApolloError;
  onSubmit: SubmitHandler<FormData>;
  onClose: () => void;
};

export function Form({
  defaultValues,
  disabledFields,
  showRegionDisclaimer,
  showDivider,
  loading,
  error,
  onSubmit,
  onClose,
}: Props) {
  const [isFormManual, toggleIsFormManual] = useToggle(false);
  const [preservedEditValues, setPreservedEditValues] = useState<FormData>({
    region: defaultValues?.region ?? "",
    reportName: defaultValues?.reportName ?? "",
    reportPathPrefix: defaultValues?.reportPathPrefix ?? "",
    bucketId: defaultValues?.bucketId ?? "",
    accountId: defaultValues?.accountId ?? "",
  });

  const autoFormDefaultValues = {
    ...awsAutoFormDefaultValues,
    accountId: defaultValues?.accountId ?? "",
    bucketId: defaultValues?.bucketId ?? "",
  };

  const formMethods = useForm<FormData>({
    resolver: yupResolver(AwsFormSchema),
    mode: "onChange",
    defaultValues: autoFormDefaultValues,
  });

  const { setValue, control } = formMethods;
  const [
    regionValue,
    reportNameValue,
    reportPathPrefixValue,
    accountIdValue,
    bucketIdIdValue,
  ] = useWatch({
    control,
    name: ["region", "reportName", "reportPathPrefix", "accountId", "bucketId"],
  });

  const AwsForm = isFormManual ? AwsManualForm : AwsAutoForm;

  const handleAutoValuesCleanup = () => {
    setValue(AwsFormFields.REGION, preservedEditValues.region);
    setValue(AwsFormFields.REPORT_NAME, preservedEditValues.reportName);
    setValue(
      AwsFormFields.REPORT_PATH_PREFIX,
      preservedEditValues.reportPathPrefix
    );
  };

  const handleAssignAutoValues = () => {
    setValue(AwsFormFields.REGION, awsAutoFormDefaultValues.region);
    setValue(AwsFormFields.REPORT_NAME, awsAutoFormDefaultValues.reportName);
    setValue(
      AwsFormFields.REPORT_PATH_PREFIX,
      awsAutoFormDefaultValues.reportPathPrefix
    );
  };

  useEffect(() => {
    if (isFormManual) {
      setPreservedEditValues(() => ({
        accountId: accountIdValue,
        bucketId: bucketIdIdValue,
        region: regionValue,
        reportName: reportNameValue,
        reportPathPrefix: reportPathPrefixValue,
      }));
    }
  }, [
    regionValue,
    reportNameValue,
    reportPathPrefixValue,
    accountIdValue,
    bucketIdIdValue,
    isFormManual,
  ]);

  return (
    <FlexContainer
      marginTop={theme.spacing.spacing04}
      direction="column"
      alignItems="flex-start"
      gap={theme.spacing.spacing01}
    >
      <Text weight="medium">Billing Data Settings</Text>
      <MultipleSelect>
        <SelectButton
          name="auto"
          value="auto"
          labelText="Automatic (Recommended)"
          isActive={!isFormManual}
          onClick={() => {
            toggleIsFormManual();
            handleAssignAutoValues();
          }}
        />
        <SelectButton
          style={{ paddingInline: "6rem" }}
          name="manual"
          value="manual"
          labelText="Manual"
          isActive={isFormManual}
          onClick={() => {
            toggleIsFormManual();
            handleAutoValuesCleanup();
          }}
        />
      </MultipleSelect>
      <If condition={showDivider}>
        <Then>
          <Seperator />
        </Then>
        <Else>
          <Spacer height={theme.spacing.spacing04} />
        </Else>
      </If>
      <UniversalWrap
        loaderProps={{ loading, Component: BrickLoader }}
        errorProps={{ error }}
      >
        <FormProvider {...formMethods}>
          <AwsForm
            disabledFields={disabledFields}
            showRegionDisclaimer={showRegionDisclaimer}
            onSubmit={onSubmit}
          >
            <FlexContainer gap={theme.spacing.spacing03}>
              <Button type="submit" icon="checkmark">
                Apply
              </Button>
              <Button severity="low" onClick={onClose}>
                Cancel
              </Button>
            </FlexContainer>
          </AwsForm>
        </FormProvider>
      </UniversalWrap>
    </FlexContainer>
  );
}

const Seperator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.color.border.input};
  margin-bottom: ${theme.spacing.spacing02};
  margin-top: ${theme.spacing.spacing02};
`;
