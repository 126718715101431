/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { useApplicationsPaginatedV2Query } from "~/generated/graphql";
import { dateFormat } from "~/constants";
import { useCurrency, useQueryState } from "~/hooks";
import { Currency, formatMoney, isNotNil } from "~/tools";
import {
  ApplicationV2,
  OptimisationLevel,
} from "~/views/applications/ApplicationsPage/components/types";

export function useApplications() {
  const { currency } = useCurrency();
  const {
    state: { page, limit },
  } = useQueryState();

  const { data, loading, error } = useApplicationsPaginatedV2Query({
    variables: {
      page,
      limit,
      year: dayjs().format(dateFormat.year),
    },
    errorPolicy: "all",
  });

  const applications = data?.applicationsPaginated;

  return {
    applications: (applications?.results ?? []).map((application) =>
      mapApplication(application, currency)
    ),
    count: applications?.count ?? 0,
    loading,
    error,
  };
}

function mapApplication(application: ApplicationV2, currency: Currency) {
  const currentMonthIndex = dayjs().month();

  return {
    name: application.name,
    id: application.id,
    description: application.description ?? "",
    owner: application.owner ?? undefined,
    contacts: (application.contacts ?? []).filter(isNotNil),
    resourceCount: application.resourceCount,
    budget: formatMoney(
      application.budgetYearly.budgetByMonth[currentMonthIndex],
      currency
    ),
    cost: formatMoney(application.cost?.currentMonth ?? "0", currency),
    forecast: formatMoney(application.cost?.forecast ?? "0", currency),
    savingsSuggestion: formatMoney(
      application.cloudWasteAggregate?.cost ?? "0",
      currency
    ),
    environmentsCount: application.environmentsV2?.count ?? 0,
    hasSavingsSuggestion: Number(application.cloudWasteAggregate?.cost) > 0,
    finOpsOptimisation: {
      ...application.finOpsOptimisation,
      optimisationLevel: mapOptimisationLevel(
        application.finOpsOptimisation.optimisationLevel as OptimisationLevel
      ),
    },
  };
}

function mapOptimisationLevel(
  level: keyof typeof OptimisationLevel | (string & {})
) {
  switch (level) {
    case "NEEDS_OPTIMISATION":
      return OptimisationLevel.NEEDS_OPTIMISATION;
    case "MODERATE_OPTIMISATION":
      return OptimisationLevel.MODERATE_OPTIMISATION;
    case "PARTIALLY_OPTIMISED":
      return OptimisationLevel.PARTIALLY_OPTIMISED;
    case "FULLY_OPTIMISED":
      return OptimisationLevel.FULLY_OPTIMISED;
    default:
      return OptimisationLevel.NEEDS_OPTIMISATION;
  }
}
