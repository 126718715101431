/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useToggle } from "react-use";
import {
  Input,
  Sidebar,
  ExtendedPaginationBox,
  FlexContainer,
  Box,
  theme,
} from "@nordcloud/gnui";
import { useBusinessContextsQuery } from "~/generated/graphql";
import { LoaderWrap } from "~/components";
import { SidebarItemList } from "~/components/SidebarSelector";
import { getFirstItem, isEmpty, isNotEmpty, isNotNil, noop } from "~/tools";
import { BusinessContext, EstateGroupBy } from "./types";

type Props = {
  activeChart: EstateGroupBy;
  businessContext: BusinessContext;
  selectBusinessContext: Dispatch<SetStateAction<BusinessContext>>;
  isDisabled?: boolean;
};

const FETCH_LIMIT = 100;

const initialFilter = {
  page: 0,
  size: FETCH_LIMIT,
  search: "",
};

type Filter = {
  page: number;
  size: number;
  search: string;
};

export function BusinessContextSelector({
  activeChart,
  businessContext,
  selectBusinessContext,
  isDisabled,
}: Props) {
  const [isBusinessContextOpen, toggleBusinessContextSelector] =
    useToggle(false);
  const [filter, setFilter] = useState<Filter>(initialFilter);

  const { data, loading } = useBusinessContextsQuery({
    variables: {
      page: filter.page,
      limit: FETCH_LIMIT,
    },
  });

  const { businessContexts, businessContextsCount } = useMemo(() => {
    return {
      businessContexts:
        data?.businessContexts?.businessContexts?.filter(isNotNil) ?? [],
      businessContextsCount: data?.businessContexts?.count ?? 0,
    };
  }, [data]);

  const primaryBusinessContext = businessContexts.find(
    (context) => context.isPrimary === true
  );

  const options = useMemo(
    () =>
      businessContexts
        .filter((bc) => bc.name.toLowerCase().includes(filter.search))
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((context) => {
          return {
            label: context.name,
            value: context.id,
          };
        }),
    [businessContexts, filter.search]
  );

  const mappedName = businessContexts.find(
    (ctx) => ctx.id === businessContext?.nid
  )?.name;

  const displayContextName = isNotNil(mappedName)
    ? mappedName
    : primaryBusinessContext?.name;

  useEffect(() => {
    if (
      loading === false &&
      isNotEmpty(businessContexts) &&
      activeChart === EstateGroupBy.ORG &&
      isEmpty(businessContext.nid)
    ) {
      if (primaryBusinessContext !== undefined) {
        selectBusinessContext({
          name: primaryBusinessContext.name,
          nid: primaryBusinessContext.id,
        });
      } else {
        const firstItem = getFirstItem(businessContexts);
        selectBusinessContext({
          name: firstItem.name,
          nid: firstItem.id,
        });
      }
    }
    setFilter((prevFilter) => ({
      ...prevFilter,
      size: businessContextsCount,
    }));
  }, [
    primaryBusinessContext,
    businessContexts,
    businessContextsCount,
    activeChart,
    loading,
    selectBusinessContext,
  ]);

  const handleSelect = (id: string) => {
    selectBusinessContext({
      name: id,
      nid: id,
    });
    toggleBusinessContextSelector();
  };

  const onPageChange = (page: number) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      page: Math.ceil(page / FETCH_LIMIT),
    }));
  };

  const handleClose = () => {
    setFilter(initialFilter);
    toggleBusinessContextSelector();
  };

  return (
    <>
      <div css={{ marginLeft: theme.spacing.spacing04 }}>
        <Input
          readOnly
          type="text"
          value={displayContextName}
          placeholder="Select Business Context"
          icon="sidebar"
          disabled={isDisabled}
          css={{ height: "20px" }}
          onClick={toggleBusinessContextSelector}
        />
      </div>
      <Sidebar
        title="Select Business Context"
        isOpen={isBusinessContextOpen}
        footer={
          <ExtendedPaginationBox
            sidebar
            small
            count={filter.size}
            from={filter.page * FETCH_LIMIT}
            setPage={onPageChange}
            setSize={noop}
            size={FETCH_LIMIT}
          />
        }
        onClick={handleClose}
      >
        <Box boxStyle="lightGrey">
          <FlexContainer>
            <div css={{ flexGrow: 1 }}>
              <Input
                small
                value={filter.search}
                type="text"
                placeholder="Search"
                onChange={(event) =>
                  setFilter((prevFilter) => ({
                    ...prevFilter,
                    search: event.target.value,
                    page: 0,
                  }))
                }
              />
            </div>
          </FlexContainer>
        </Box>
        <LoaderWrap inContent loading={loading}>
          <SidebarItemList items={options} onSubmit={handleSelect} />
        </LoaderWrap>
      </Sidebar>
    </>
  );
}
