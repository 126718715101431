import {
  ChartType,
  EstateV2Filter,
  SearchSortField,
  SearchSortOrder,
} from "~/generated/graphql";
import { DateRange, PaginationState } from "~/components";

export const POLL_INTERVAL_MS = 1000; // 1 second
export const POLL_INTERVAL_ON_FAIL_MS = 30_000; // 30 seconds

export type ChartJobProps = {
  chartType: ChartType;
  range: DateRange;
};

type SortingProp = {
  field?: SearchSortField;
  order?: SearchSortOrder;
};

export type EstateQueryState = EstateV2Filter &
  PaginationState &
  SortingProp & {
    tags: string[];
    metadata: string[];
    query: string;
  };
