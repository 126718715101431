/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloCache } from "@apollo/client";
import {
  useRemoveIntegrationMutation,
  ExternalIntegrationsQuery,
  ExternalIntegrationsQueryVariables,
  ExternalIntegrationsDocument,
} from "~/generated/graphql";
import { ERROR_TEXT } from "~/constants";
import { showError } from "~/services/toast";
import { isNil } from "~/tools";

export function useRemoveExternalIntegration(id?: string) {
  const [
    removeIntegration,
    {
      loading,
      error,
      client: { cache },
    },
  ] = useRemoveIntegrationMutation();

  const handleIntegrationDelete = async (callback: () => void) => {
    if (isNil(id)) {
      return;
    }

    try {
      const response = await removeIntegration({
        variables: { integrationId: id },
      });

      handleCacheUpdate(
        cache,
        response.data?.removeExternalIntegration?.id ?? ""
      );
    } catch {
      showError(ERROR_TEXT.default);
    }

    callback();
  };

  return [handleIntegrationDelete, { loading, error }] as const;
}

function handleCacheUpdate(
  cache: ApolloCache<unknown>,
  integrationId?: string
) {
  cache.evict({
    id: "ROOT_QUERY",
    fieldName: "thresholds",
  });
  cache.updateQuery<
    ExternalIntegrationsQuery,
    ExternalIntegrationsQueryVariables
  >(
    {
      query: ExternalIntegrationsDocument,
    },
    (integrations) => {
      return {
        externalIntegrations: {
          integrations: (
            integrations?.externalIntegrations?.integrations ?? []
          ).filter((integration) => integration?.id !== integrationId),
        },
      };
    }
  );
  cache.gc();
}
