/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo, useState } from "react";
import { If, Then, Else } from "react-if";
import {
  FlexContainer,
  InputSearch,
  ModalConfirm,
  Spacer,
  Text,
  theme,
} from "@nordcloud/gnui";
import { useAddEnvToOrgUnitV2Mutation } from "~/generated/graphql";
import { NoData, ReactTable, TableLoader, UniversalWrap } from "~/components";
import { SUCCESS_TEXT } from "~/constants";
import { useDisclosure, useMutationHandler, useQueryState } from "~/hooks";
import { isEmpty, isNotEmpty } from "~/tools";
import { useApplications } from "~/views/applications/ApplicationsPage/components/ApplicationsProvider";
import { useBusinessContext } from "~/views/businessContexts/hooks";
import { SearchParams } from "~/views/businessContexts/OrganizationUnit/Lists";
import { OrganizationUnitsTableColumns } from "./OrganizationUnitsTableColumns";

type Props = {
  businessContextId: string;
  environmentId?: string;
};

export function OrganizationUnitsList({
  businessContextId,
  environmentId,
}: Props) {
  const [orgUnitId, setOrgUnitId] = useState("");
  const [orgUnitName, setOrgUnitName] = useState("");
  const { isOpen, close, open } = useDisclosure();
  const { units, loading, error } = useBusinessContext(businessContextId);
  const [addEnvToOrgUnit] = useAddEnvToOrgUnitV2Mutation();
  const { runMutation } = useMutationHandler();

  const { refetchEnvironments } = useApplications();

  const onAttach = async () => {
    await runMutation({
      mutation: () =>
        addEnvToOrgUnit({
          variables: {
            envId: environmentId ?? "",
            orgUnitId,
          },
        }).then(() => refetchEnvironments()),
      successText: SUCCESS_TEXT.envsAttached,
    });
    close();
  };

  const columns = useMemo(
    () =>
      OrganizationUnitsTableColumns({
        structureId: businessContextId,
        environmentId,
        open,
        setOrgUnitId,
        setOrgUnitName,
      }),
    [businessContextId, open, environmentId]
  );

  const {
    set,
    removeFields,
    state: { search = "" },
  } = useQueryState<SearchParams>();

  const handleSearch = (value: string) => {
    if (isEmpty(value)) {
      removeFields(["search"]);
    } else {
      set("search")(value);
    }
  };

  const placeholder = units[0]?.name ?? "Search";

  return (
    <>
      <FlexContainer
        justifyContent="flex-start"
        alignItems="center"
        columnGap={theme.spacing.spacing04}
      >
        <div css={{ flex: 1 }}>
          <InputSearch
            value={search}
            placeholder={placeholder}
            name="search"
            onChange={(event) => handleSearch(event.target.value)}
          />
        </div>
      </FlexContainer>
      <Spacer height={theme.spacing.spacing04} />
      <UniversalWrap
        loaderProps={{ loading, Component: Loader }}
        errorProps={{ error }}
      >
        <If condition={isNotEmpty(units)}>
          <Then>
            <ReactTable columns={columns} data={units} />
            <ModalConfirm
              isOpen={isOpen}
              confirm={onAttach}
              actionLabel="Attach"
              contentLabel="Attach environment to the Organizational Unit"
              onClose={close}
            >
              <Text>
                If you proceed with attaching it to the{" "}
                <strong>{orgUnitName}</strong>, the previous attachments will be
                lost.
              </Text>
              <Text>Are you sure you want to proceed?</Text>
            </ModalConfirm>
          </Then>
          <Else>
            <NoData message={<Text>There is no data available.</Text>} />
          </Else>
        </If>
      </UniversalWrap>
    </>
  );
}

function Loader() {
  return (
    <>
      <TableLoader
        gapY={6}
        gapX={0}
        rows={[
          {
            count: 6,
            height: 100,
            items: [
              {
                widthPercent: 100,
              },
            ],
          },
        ]}
      />
    </>
  );
}
