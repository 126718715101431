/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const CLOUD_FORMATION_URL =
  "https://console.aws.amazon.com/cloudformation/home";
export const CLOUD_FORMATION_TEMPLATE_URL =
  "https://drive.google.com/file/d/1--HX4Ik4Rejm-YuGVzGjeoceS6Ad9vo1/view?pli=1";
export const MORE_INFO_EXTERNAL_IDS_URL =
  "https://aws.amazon.com/blogs/security/how-to-use-external-id-when-granting-access-to-your-aws-resources/";

export const MICROSOFT_AZURE = "https://portal.azure.com/#home";

export const GCP_CLOUD_STORAGE =
  "https://console.cloud.google.com/storage/browser";
export const GCP_SERVICE_ACCOUNTS =
  "https://console.cloud.google.com/iam-admin/serviceaccounts";
export const GCP_IAM =
  "https://console.cloud.google.com/iam-admin/serviceaccounts";

export const IBM_CLOUD_PLATFORM = "https://cloud.ibm.com/";
export const IBM_CLOUD_IAM = "https://cloud.ibm.com/iam/users";
export const IBM_CLOUD_NOTIFICATION = "https://cloud.ibm.com/notifications";
export const IBM_CLOUD_API_KEYS = "https://cloud.ibm.com/iam/apikeys";

export const REQUIRED_FIELD_WARNING = "This field is required.";

export const AZURE_COMMAND = `az account list --query '[].id' -o tsv | xargs -I {} bash -c 'az account set --subscription {} && az ad sp create-for-rbac --name spn-ibm-mca-prod --role Reader --scopes /subscriptions/{}'`;
