/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { If, Then, Else } from "react-if";
import styled from "styled-components";
import { FlexContainer, IconBox, theme, Text, SVGIcon } from "@nordcloud/gnui";
import { QueryStateFilterValue } from "~/hooks";
import { isEmpty } from "~/tools";
import { formatActiveFilterName } from "./helpers";
import { FilterButton } from "./styles";
import { TagValue } from "./TagValue";

type Props = {
  name: string;
  value: QueryStateFilterValue;
  isFilterExcluded: boolean;
  valueArray: string[];
  onRemoveKey: () => void;
};

export function FilterTag({
  name,
  value,
  isFilterExcluded,
  valueArray,
  onRemoveKey,
}: Props) {
  return (
    <FlexContainer
      direction="row"
      css={{
        marginRight: theme.spacing.spacing02,
      }}
    >
      <FilterButton
        size="md"
        severity="low"
        isFilterExcluded={isFilterExcluded}
      >
        <FlexContainer direction="row">
          <If condition={isEmpty(valueArray)}>
            <Then>
              <TagValue name={name} value={value} />
            </Then>
            <Else>
              <Text size="sm" mr={theme.spacing.spacing02} mb={0}>
                {`${valueArray.length} ${formatActiveFilterName(name)}`}
              </Text>
            </Else>
          </If>
          <IconBox onClick={onRemoveKey}>
            <OptionIcon name="trash" />
          </IconBox>
        </FlexContainer>
      </FilterButton>
    </FlexContainer>
  );
}

const OptionIcon = styled(SVGIcon)`
  width: ${theme.spacing.spacing03};
  height: ${theme.spacing.spacing03};
`;
