/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ColumnDef, Row } from "@tanstack/react-table";
import { formatChartDates } from "~/components/Charts/CostAnalysis/components/utils";
import {
  ChartType,
  CostAnalysisFields,
  LinkParameters,
  TableData,
} from "~/components/Charts/CostAnalysis/types";
import { EstatePeriod } from "~/constants";
import { formatMoney } from "~/tools";
import { EstateTotalsTableCell } from "./EstateTotalsTableCell";

type ColumnsProps = {
  startDate: string;
  endDate: string;
  currency: string;
  data: TableData[];
  chartType: ChartType;
  period: EstatePeriod;
  colors: { [k: string]: string };
  linkParameters?: LinkParameters;
};

type GeneratedColumnProps = {
  header: { date: string; displayDate: string };
  currency: string;
  data: TableData[];
};

function generateDateColumn({
  header,
  currency,
}: GeneratedColumnProps): ColumnDef<TableData>[] {
  return [
    {
      header: header.displayDate,
      accessorKey: header.date,
      cell: ({ row }) => getStyledText(row?.original, currency, header.date),
    },
  ];
}

export function getEstateTotalsTableColumns({
  startDate,
  endDate,
  currency,
  data,
  chartType,
  colors,
  period,
  linkParameters,
}: ColumnsProps): ColumnDef<TableData>[] {
  const headers = formatChartDates(startDate, endDate, period);

  return [
    {
      header: "",
      accessorKey: "field",
      cell: ({ row }) => {
        const color = colors[row.original?.field ?? ""] ?? "primary";

        return (
          <EstateTotalsTableCell
            row={row}
            showColorIcon={showColorBox(row, colors)}
            chartType={chartType}
            color={color}
            linkParameters={linkParameters}
          />
        );
      },
    },
    ...headers.flatMap((header) =>
      generateDateColumn({
        header,
        currency,
        data,
      })
    ),
  ];
}

function getStyledText(
  tableData: TableData | undefined,
  currency: string,
  date?: string
) {
  const value = date
    ? Number(tableData?.[date] ?? 0)
    : Number(tableData?.total ?? 0);
  return value ? formatMoney(value, currency) : "-";
}

function showColorBox(row: Row<TableData>, colors: { [k: string]: string }) {
  return (
    Object.keys(colors).length > 0 &&
    row?.original?.field !== CostAnalysisFields.TOTAL_COST
  );
}
