/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { createContext, useContext } from "react";

export function createContext_<ContextType>() {
  const ctx = createContext<ContextType | undefined>(undefined);

  function useContext_() {
    const c = useContext(ctx);
    if (!c) {
      throw new Error("useCtx must be inside a Provider with a value");
    }

    return c;
  }

  return [useContext_, ctx.Provider] as const;
}
