/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { generatePath, Link } from "react-router-dom";
import { Button, FlexContainer, Table, Text, theme } from "@nordcloud/gnui";
import { ROUTES } from "~/routing/routes";
import { noop } from "~/tools";
import { useApplications } from "~/views/applications/ApplicationsPage/components/ApplicationsProvider";
import { MappedEnvironmentV2 } from "../types";
import { OrganizationalUnitCell } from "./OrganizationalUnitCell";

type Props = {
  environment: MappedEnvironmentV2;
};

export function EnvironmentRow({ environment }: Props) {
  const { applicationId } = useApplications();

  return (
    <Table.tr>
      <Table.td>
        <Link
          to={generatePath(ROUTES.applications.environment, {
            application: applicationId,
            environment: environment.id,
          })}
        >
          {environment.name}
        </Link>
      </Table.td>
      <Table.td>
        <OrganizationalUnitCell
          orgUnits={environment.orgUnits}
          environmentId={environment.id}
        />
      </Table.td>
      <Table.td>
        <Text
          mb="0"
          color={
            environment.savingsSuggestion !== "-"
              ? theme.color.text.success
              : undefined
          }
        >
          {environment.savingsSuggestion}
        </Text>
      </Table.td>
      <Table.td>{environment.cost}</Table.td>
      <Table.td>{environment.forecast}</Table.td>
      <Table.td>{environment.budget}</Table.td>
      <Table.td>
        <FlexContainer columnGap={theme.spacing.spacing01}>
          <Button
            disabled
            type="button"
            icon="trash"
            severity="low"
            size="md"
            onClick={noop}
          />
        </FlexContainer>
      </Table.td>
    </Table.tr>
  );
}
