/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { CollapsibleBox } from "~/components";
import { Item } from "../components";
import { InstanceGridWrapper } from "./styles";

type Props = {
  displayName: string;
  skuName: string;
  location: string;
  quantity?: string;
  billingPlan: string;
  requestedDate: string;
  reservationDate: string;
  expirationDate: string;
  term: string;
  firstSeen: string | undefined;
  lastSeen: string | undefined;
};

export function AzureReservedInstanceDetails({
  displayName,
  skuName,
  term,
  location,
  quantity,
  billingPlan,
  requestedDate,
  reservationDate,
  expirationDate,
  firstSeen,
  lastSeen,
}: Props) {
  return (
    <CollapsibleBox title="SKU Details">
      <InstanceGridWrapper>
        <Item label="SKU" value={skuName} />
        <Item label="Display Name" value={displayName} />
        <Item label="Location" value={location} />
        <Item label="Quantity" value={quantity} />
        <Item label="Billing Plan" value={billingPlan} />
        <Item label="Term" value={term} />
        <Item label="Requested Date" value={requestedDate} />
        <Item value={reservationDate} label="Reservation Date" />
        <Item value={expirationDate} label="Expiration Date" />
        <Item label="First Seen" value={firstSeen} />
        <Item label="Last Seen" value={lastSeen} />
      </InstanceGridWrapper>
    </CollapsibleBox>
  );
}
