/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { FlexContainer, SVGIcon, theme } from "@nordcloud/gnui";
import { Provider } from "~/generated/graphql";
import { IconBox } from "~/components";
import { CostBox } from "~/components/CostBox";
import { useCloudAccount, getAccountUrl } from "~/hooks";
import { Item } from "~/views/estate/EstateDetailsPage/components/Item";
import { Costs } from "~/views/estate/EstateDetailsPage/types";
import { LinkedResources } from "../Ec2Instance/types";
import { InlineCloudResourcesMultiLink } from "./InlineCloudResourcesMultiLink";

type Props = {
  name: string;
  providerId: string;
  providerType: string;
  account: string;
  costs: Costs;
  linkedResources?: LinkedResources;
};

export function Ec2Header({
  name,
  providerId,
  providerType,
  account,
  costs,
  linkedResources,
}: Props) {
  const onChange = () => linkedResources?.onChange();

  const LinkedResourcesVisual =
    linkedResources == null ? null : (
      <Item
        label="Linked Resources"
        component={
          <InlineCloudResourcesMultiLink
            {...linkedResources}
            {...linkedResources.cloudResourceData}
            onChange={onChange}
          />
        }
      />
    );

  const cloudAccount = useCloudAccount(account, Provider.Aws);

  return (
    <>
      <NameWrapper>
        <FlexContainer columnGap={theme.spacing.spacing04}>
          <IconBox icon={<SVGIcon name="aws" size="lg" />} />
          <Item value={name} label="Resource Name" />
        </FlexContainer>
      </NameWrapper>
      <Line />
      <DetailsWrapper>
        <FlexContainer
          direction="column"
          alignItems="flex-start"
          rowGap={theme.spacing.spacing01}
        >
          <Item value={providerId} label="AWS ID" />
          <Item value={providerType} label="AWS type" />
          <Item
            value={cloudAccount?.urlLabel ?? account}
            label="Account"
            url={cloudAccount?.url ?? getAccountUrl(account, "")}
          />
          {LinkedResourcesVisual}
        </FlexContainer>
      </DetailsWrapper>
      <CostsWrapper>
        <CostBox numericalValues={costs} />
      </CostsWrapper>
    </>
  );
}

const NameWrapper = styled.div`
  grid-area: name;
`;

const CostsWrapper = styled.div`
  grid-area: costs;
`;

const DetailsWrapper = styled.div`
  grid-area: details;
`;

const Line = styled.div`
  grid-area: line;
  border-top: solid 1px ${theme.color.border.border01};
  margin: 0 ${theme.spacing.spacing03} 0 0;
`;
