/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Fragment } from "react";
import { flexRender, Row } from "@tanstack/react-table";
import { TableData } from "~/components";
import { EstatePeriod } from "~/constants";
import { Td } from "./styles";

type Props = {
  period: EstatePeriod;
  rows: Row<TableData>[];
};

export function EstateTotalsCostTableBody({ period, rows }: Props) {
  return (
    <tbody>
      {rows.map((row) => {
        return (
          <Fragment key={`data-row-${row.id}`}>
            <tr>
              {row.getVisibleCells().map((cell) => (
                <Td key={`cell-${cell.id}`} period={period}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </tr>
          </Fragment>
        );
      })}
    </tbody>
  );
}
