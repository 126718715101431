/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useReducer } from "react";
import { useToggle } from "react-use";
import { SearchSortField, SearchSortOrder } from "~/generated/graphql";
import {
  estateSearchJobFields,
  useFilteredQueryState,
  useQueryStateWithFilters,
} from "~/hooks";
import { SearchV2Filters } from "../../types";
import { ACTION, reducer } from "../Charts/reducer";
import { EstateGroupBy } from "../Charts/types";

type SortingProp = {
  field?: SearchSortField;
  order?: SearchSortOrder;
};

type EstateQueryState = SearchV2Filters &
  SortingProp & {
    page: number;
    limit: number;
    query: string;
    groupBy: EstateGroupBy;
    groupByTagKey: string;
    groupByBusinessContext: string;
  };

export function useEstateFilters() {
  const [isSidebarOpen, toggleIsSidebarOpen] = useToggle(false);
  const { state, updateQueryState, setQueryState } = useFilteredQueryState(
    estateSearchJobFields
  );

  // @ts-expect-error ignored temporary after migration to TypeScript 4.9
  const {
    query,
    field,
    order,
    groupBy,
    groupByTagKey,
    groupByBusinessContext,
    page: ignoredPage,
    limit: ignoredLimit,
    ...restFilters
  }: EstateQueryState = state;

  const [filters, updateFilters] = useReducer(reducer, {
    ...restFilters,
  });
  const handleUpdate = (newFilters: SearchV2Filters) => {
    updateFilters({ type: ACTION.UPDATE, payload: { filters: newFilters } });
  };

  const clearFilters = () => {
    updateFilters({ type: ACTION.CLEAR });
  };

  const toggleFilters = (openFilters: boolean) => {
    updateFilters({
      type: ACTION.UPDATE_ALL,
      payload: { filters: restFilters },
    });
    toggleIsSidebarOpen(openFilters);
  };

  return {
    filters,
    restFilters,
    isSidebarOpen,
    field,
    order,
    query,
    groupBy,
    groupByTagKey,
    groupByBusinessContext,
    updateFilters,
    updateQueryState,
    setQueryState,
    handleUpdate,
    clearFilters,
    toggleFilters,
    toggleIsSidebarOpen,
  };
}

export function useActiveFilters<T>(filters: T) {
  const { setFilters, ...rest } = useQueryStateWithFilters(filters);

  const removeFilterValueV2 = (key: keyof SearchV2Filters, index: number) => {
    setFilters({
      ...filters,
      // @ts-expect-error ignored temporary after migration to TypeScript 4.9
      [key]: removeValueFromFilterV2(filters, key, index),
    });
  };

  return { setFilters, removeFilterValueV2, ...rest };
}

function removeValueFromFilterV2(
  filters: SearchV2Filters,
  key: keyof SearchV2Filters,
  index: number
) {
  const newValue = filters[key];
  if (Array.isArray(newValue)) {
    // Cast to unknown as specific type is not needed here
    return (newValue as unknown[]).filter(
      (_, index_: number) => index_ !== index
    );
  }

  return filters[key];
}
