/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { Else, If, Then, When } from "react-if";
import { FlexContainer, Text, theme } from "@nordcloud/gnui";
import { dateFormat } from "~/constants";
import { Currency, formatMoney, getFirstItem, isNotEmpty } from "~/tools";
import { TooltipIconBox } from "../../TooltipIconBox";
import { ChartDates, CostAnalysisFields, Granularity } from "../types";
import { invoiceCorrectionColor } from "./consts";
import { StyledItem, Line } from "./styles";
import { formatDisplayDate, getRangeDate } from "./utils";

type Props = {
  data: {
    date: string;
  }[];
  index: number;
  granularity: Granularity;
  currency: Currency;
  colors: { [k: string]: string };
  dateRangeDates?: ChartDates[];
};

const otherCategoriesFields = [
  CostAnalysisFields.OTHER_CATEGORIES_COST,
  CostAnalysisFields.OTHER_SERVICES_COST,
  CostAnalysisFields.OTHER_APPLICATIONS_COST,
];

export function StackTooltip({
  data,
  index,
  granularity,
  currency,
  colors,
  dateRangeDates,
}: Props) {
  const tooltipDisplayDate = dateRangeDates
    ? getRangeDate(dateRangeDates, data?.[index].date)
    : formatDisplayDate(data?.[index].date, granularity);

  const tooltipDate = dayjs(data?.[index].date).format(dateFormat.shortDate);

  const date = data?.find((item) => item?.date === tooltipDate);

  const entries = filterEntries(date).sort(
    ([, a], [, b]) => Number(b) - Number(a)
  );

  const allOtherApplications = Object.entries(date ?? []).filter(([key]) =>
    otherCategoriesFields.find((value) => value === key)
  );

  const costCorrection = Object.entries(date ?? []).filter(
    ([key]) => key === CostAnalysisFields.CORRECTION_COST
  );

  const costCorrectionValue = getCorrectionValue(costCorrection);

  const finalEntries = mapEntries([...entries, ...allOtherApplications]);

  const totalCost = finalEntries
    .map(([_, value]) => Number(value))
    .reduce((subTotal, currentCost) => subTotal + currentCost, 0)
    .toFixed(2);

  const costBeforeCorrection = (
    Number(totalCost) - Number(costCorrectionValue)
  ).toFixed(2);

  return (
    <FlexContainer
      key={`${index}`}
      direction="column"
      css={{
        display: "block",
      }}
    >
      <Text m={0} size="sm" color={theme.color.text.text03}>
        {tooltipDisplayDate}
      </Text>
      <StyledItem width="60%">{`${formatTotalLabel(granularity)}:`}</StyledItem>
      <StyledItem width="40%" align="right">
        {formatMoney(Number(totalCost), currency)}
      </StyledItem>
      <When condition={costCorrectionValue !== 0}>
        <FlexContainer key="uncorrected-cost" direction="row">
          <StyledItem width="60%">{`${formatTotalLabel(
            granularity
          )} before correction:`}</StyledItem>
          <StyledItem width="40%" align="right">
            {formatMoney(Number(costBeforeCorrection), currency)}
          </StyledItem>
        </FlexContainer>
        <FlexContainer key="correction-cost" direction="row">
          <TooltipIconBox color={invoiceCorrectionColor} />
          {getTooltipItem(
            CostAnalysisFields.CORRECTION_COST,
            costCorrectionValue.toString(),
            currency
          )}
        </FlexContainer>
      </When>
      <Line />
      {finalEntries.map(([key, value]) => {
        return (
          <FlexContainer
            key={key}
            direction="row"
            css={{ minWidth: "12rem", maxWidth: "20rem" }}
          >
            <When condition={colors[key] !== undefined}>
              <TooltipIconBox color={colors[key] ?? "primary"} />
              {getTooltipItem(key, value, currency)}
            </When>
          </FlexContainer>
        );
      })}
    </FlexContainer>
  );
}

export function getTooltipItem(key: string, value: string, currency: string) {
  const isCorrection = key.includes("correction");
  return (
    <>
      <FlexContainer
        css={{
          width: "60%",
        }}
      >
        <If condition={isCorrection}>
          <Then>
            <StyledItem>{`${key}:`}</StyledItem>
          </Then>
          <Else>
            <StyledItem
              css={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >{`${key}`}</StyledItem>
            <StyledItem>:</StyledItem>
          </Else>
        </If>
      </FlexContainer>
      <StyledItem
        align="right"
        marginLeft={theme.spacing.spacing02}
        width="40%"
      >
        {formatMoney(Number(value), currency)}
      </StyledItem>
    </>
  );
}

const otherCategories = [
  CostAnalysisFields.OTHER_APPLICATIONS_COST,
  CostAnalysisFields.OTHER_CATEGORIES_COST,
  CostAnalysisFields.CORRECTION_COST,
  CostAnalysisFields.OTHER_SERVICES_COST,
];

function filterEntries(date?: { date: string }) {
  return Object.entries(date ?? []).filter(
    ([key]) => !["date", ...otherCategories].includes(key)
  );
}

function mapEntries(entries: [string, string][]) {
  const correction = entries.find(
    ([key]) => key === CostAnalysisFields.CORRECTION_COST
  );
  const correctionCost = Number(correction?.[1] ?? 0);

  if (correctionCost === 0) {
    return entries.filter(
      ([key]) => key !== CostAnalysisFields.CORRECTION_COST
    );
  }

  return entries;
}

function formatTotalLabel(granularity: Granularity) {
  return granularity === Granularity.DAYS
    ? CostAnalysisFields.DAILY_COST
    : CostAnalysisFields.MONTHLY_COST;
}

function getCorrectionValue(corrections: [string, string][]) {
  if (isNotEmpty(corrections)) {
    return (
      getFirstItem(
        getFirstItem(corrections).filter(
          (value) => value !== CostAnalysisFields.CORRECTION_COST
        )
      ) ?? 0
    );
  }

  return 0;
}
