/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import {
  Label,
  FlexContainer,
  theme,
  Input,
  Button,
  Spacer,
} from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { isNotNil } from "~/tools";
import { useHandleExternalRecipients } from "../hooks";
import { ThresholdFormData, ThresholdFormFields } from "../validators";

export function ExternalRecipients() {
  const {
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<ThresholdFormData>();

  const [
    recipientSlots,
    { onAddRecipientSlot, onRemoveRecipientSlot, onFillRecipientSlot },
  ] = useHandleExternalRecipients(
    getValues(ThresholdFormFields.EXTERNAL_RECIPIENTS)
  );

  useEffect(() => {
    setValue(
      ThresholdFormFields.EXTERNAL_RECIPIENTS,
      recipientSlots
        .filter((slot) => isNotNil(slot.value))
        .map((slot) => slot.value ?? "")
    );
  }, [recipientSlots, setValue]);

  return (
    <div css={{ width: "100%" }}>
      <Label name="External Recipients" />
      <FormGroup error={errors[ThresholdFormFields.EXTERNAL_RECIPIENTS]}>
        <FlexContainer
          gap={theme.spacing.spacing02}
          direction="column"
          alignItems="flex-start"
        >
          <Controller
            name={ThresholdFormFields.EXTERNAL_RECIPIENTS}
            render={() => (
              <>
                {recipientSlots.map(({ id, value }) => (
                  <FlexFullWidthChildren key={id} gap={theme.spacing.spacing04}>
                    <Input
                      key={id}
                      placeholder="e.g.: name@example.com"
                      value={value ?? ""}
                      onChange={(event) =>
                        onFillRecipientSlot(id, event.currentTarget.value)
                      }
                    />
                    <Button
                      severity="low"
                      icon="trash"
                      type="button"
                      onClick={() => onRemoveRecipientSlot(id)}
                    />
                  </FlexFullWidthChildren>
                ))}
              </>
            )}
          />
          <Spacer height={theme.spacing.spacing01} />
          <Button
            type="button"
            severity="low"
            icon="plus"
            onClick={onAddRecipientSlot}
          >
            Add Recipient
          </Button>
        </FlexContainer>
      </FormGroup>
    </div>
  );
}

const FlexFullWidthChildren = styled(FlexContainer)`
  width: 100%;
  div {
    width: 100%;
  }
`;
