/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import dayjs from "dayjs";
import { useReservationUtilizationV2LazyQuery } from "~/generated/graphql";
import { DateRange } from "~/components";
import { EstatePeriod, dateFormat } from "~/constants";
import { isNotNil } from "~/tools";
import { Granularity, defaultReservationId } from "../constants";
import { ReservationType } from "../types";

type Props = {
  nid: string;
};

const defaultRange = {
  from: dayjs().startOf("month").format(dateFormat.shortDate),
  to: dayjs().format(dateFormat.shortDate),
};

export function useReservationUtilizationData({ nid }: Props) {
  const [period, setPeriod] = useState(EstatePeriod.DEFAULT_MONTH);
  const [granularity, setGranularity] = useState(() => {
    const diffMonths = dayjs(defaultRange?.to).diff(
      dayjs(defaultRange?.from),
      "month"
    );
    return diffMonths >= 6 ? Granularity.MONTHLY : Granularity.DAILY;
  });
  const [range, setRange] = useState<DateRange | undefined>(defaultRange);

  const [selectedReservationId, setSelectedReservationId] = useState<
    ReservationType | null | undefined
  >(undefined);

  const onChangePeriod = (periodName: EstatePeriod) => setPeriod(periodName);

  const onChangeRange = (newRange: DateRange | undefined) => {
    const diffMonths = dayjs(newRange?.to).diff(dayjs(newRange?.from), "month");
    setGranularity(diffMonths >= 6 ? Granularity.MONTHLY : Granularity.DAILY);
    setRange(newRange);
  };

  const variables = {
    nid,
    reservationId: selectedReservationId?.value ?? "",
    start: range?.from ?? "",
    end: range?.to ?? "",
    granularity,
  };

  const [getUtilization, { data, loading }] =
    useReservationUtilizationV2LazyQuery({
      variables,
      fetchPolicy: "no-cache",
    });

  const utilizationdata = data?.reservationUtilizationV2?.monthlyUtilization;
  const benefit = utilizationdata?.benefit ?? "";
  const forecast = utilizationdata?.forecast ?? "";
  const baseReservationIds = (utilizationdata?.reservationIDs ?? [])
    .filter(isNotNil)
    .map((value, index) => ({
      label: value,
      value,
      key: `${value}-${index}`,
    }));

  const reservationIds = [
    ...(baseReservationIds.length > 1
      ? [{ ...defaultReservationId, key: "default" }]
      : []),
    ...baseReservationIds,
  ];

  const chartData = utilizationdata?.timeSeries ?? [];
  const utilizationValue = Number(utilizationdata?.utilization ?? 0) * 100;

  return {
    getUtilization,
    setSelectedReservationId,
    selectedReservationId,
    onChangeRange,
    onChangePeriod,
    loading,
    benefit,
    forecast,
    reservationIds,
    chartData,
    utilizationValue,
    period,
    range,
  };
}
