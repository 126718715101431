/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useDebounce } from "react-use";
import { useSearchPresetsV2LazyQuery } from "~/generated/graphql";

const DEBOUNCE_TIME_MS = 300;

type Props = {
  page: number;
  limit: number;
  nameQuery?: string;
};

export function useSearchPresets({ page, limit, nameQuery }: Props) {
  const [getSearchPresets, { data, loading, error }] =
    useSearchPresetsV2LazyQuery();

  useDebounce(
    () => {
      void getSearchPresets({
        variables: { page, limit, nameQuery },
      });
    },
    DEBOUNCE_TIME_MS,
    [nameQuery, page, limit]
  );

  return {
    data,
    loading,
    error,
  };
}
