/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const enum ProviderType {
  MICROSOFT_CAPACITY_RESERVATIONS = "microsoft.capacity/reservations",
  RESERVED_INSTANCES = "reserved-instances",
  SAVINGS_PLANS = "savingsplans",
  EC2_SAVINGS_PLANS = "savingsplans/ec2instance",
}

export const enum AWSHeaderLabels {
  ID = "AWS ID",
  TYPE = "AWS Type",
}

export const enum AzureHeaderLabels {
  ID = "Azure ID",
  TYPE = "Azure Type",
}

export const defaultRangeLabel = "Date Range";
export const defaultSelectButtonWidth = "3rem";

export const enum Granularity {
  DAILY = "daily",
  MONTHLY = "monthly",
}

export const defaultReservationId = { label: "Aggregated", value: "" };

export const reservedInstanceTypes = [
  "microsoft.capacity/reservations/blockblob",
  "microsoft.capacity/reservations/cosmosdb",
  "microsoft.capacity/reservations/mariadb",
  "microsoft.capacity/reservations/mysql",
  "microsoft.capacity/reservations/postgresql",
  "microsoft.capacity/reservations/sqldatabases",
  "microsoft.capacity/reservations/sqldatawarehouse",
  "microsoft.capacity/reservations/virtualmachines",
  "dynamodb/reserved-instances",
  "ec2/reserved-instances",
  "elasticache/reserved-instances",
  "es/reserved-instances",
  "rds/reserved-instances",
  "redshift/reserved-instances",
  "savingsplans/compute",
  "savingsplans/ec2instance",
  "savingsplans/sagemaker",
];
