/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  ExpandedState,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { Text, theme } from "@nordcloud/gnui";
import { TableData } from "~/components";
import { EstatePeriod } from "~/constants";
import { isEmpty } from "~/tools";
import { StyledTable, Td, Th } from "./EstateGroupingsStyles";

type Props = {
  data: TableData[];
  period: EstatePeriod;
  columns: ColumnDef<TableData>[];
  getSubRows?: (
    originalRow: TableData,
    index: number
  ) => TableData[] | undefined;
  firstColumnWidth?: string;
};

export function EstateGroupingsTable({
  data,
  period,
  columns,
  getSubRows,
  firstColumnWidth,
}: Props) {
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    state: { expanded },
    onExpandedChange: setExpanded,
    getSubRows,
  });

  if (isEmpty(table.getRowModel().rows)) {
    return <Text>No data available.</Text>;
  }

  const columnSize = table.getAllColumns().length;

  return (
    <StyledTable mb="0">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={`header-row-${headerGroup.id}`}>
            {headerGroup.headers.map((header) => (
              <Th
                key={header.id}
                colSpan={header.colSpan}
                wide={columnSize < 10}
                firstColumnWidth={firstColumnWidth}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </Th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={`data-row-${row.id}`}>
            {row.getVisibleCells().map((cell) => (
              <Td
                key={`cell-${cell.id}`}
                background={getCellBackgroundColor(row.index, period)}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
}

const getCellBackgroundColor = (rowIndex: number, period: EstatePeriod) => {
  return rowIndex === 0 && period === EstatePeriod.RANGE
    ? theme.color.background.ui03
    : undefined;
};
