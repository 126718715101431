/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

export const GridWrapper = styled.div`
  display: grid;
  width: 100%;
  row-gap: ${theme.spacing.spacing04};
  grid-template-rows: 0.75fr 1px 2fr;
  grid-template-columns: 60% 40%;
  grid-template-areas:
    "name costs"
    "line costs"
    "details costs";

  @media screen and (max-width: ${theme.breakpoints.lg}px) {
    height: 100%;
    grid-template-rows: 1fr 1px 2fr 3fr;
    grid-template-columns: 1fr;
    grid-template-areas:
      "name"
      "line"
      "details"
      "costs";
  }
`;

export const NameWrapper = styled.div`
  grid-area: name;
`;

export const CostsWrapper = styled.div`
  grid-area: costs;
`;

export const DetailsWrapper = styled.div`
  grid-area: details;
`;

export const Line = styled.div`
  grid-area: line;
  border-top: solid 1px ${theme.color.border.border01};
  margin: 0 ${theme.spacing.spacing03} 0 0;
`;

export const InstanceGridWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  row-gap: ${theme.spacing.spacing02};
  column-gap: ${theme.spacing.spacing04};
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: ${theme.breakpoints.lg}px) {
    grid-template-rows: repeat(6, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const TableWrapper = styled.div`
  th:last-child {
    div {
      justify-content: end;
    }
  }

  th:first-child {
    width: ${theme.spacing.spacing00};
  }
`;

export const BenefitWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -2px;

  & > span {
    margin-left: ${theme.spacing.spacing04};
  }
`;
