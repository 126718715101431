/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  Box,
  Button,
  FlexContainer,
  Input,
  Label,
  List,
  Spacer,
  Text,
  Upload,
  theme,
} from "@nordcloud/gnui";
import { ExternalLink, FormGroup, FormHint } from "~/components";
import { ContactsSelector } from "~/components/Inputs/ContactsSelector/ContactsSelector";
import { ERROR_TEXT, SUCCESS_TEXT } from "~/constants";
import { showError, showSuccess } from "~/services/toast";
import { isEmpty } from "~/tools";
import { CopyBox } from "../../components/CopyBox";
import {
  GCP_CLOUD_STORAGE,
  GCP_IAM,
  GCP_SERVICE_ACCOUNTS,
} from "../../constants";
import { GcpFormFields, GcpJsonData, Provider, ProviderTab } from "../../types";
import { readFile } from "../../utils";
import { hasFiles, prepareData } from "../utils";
import { AdditionalCloudAccountSettings } from "./AdditionalCloudAccountSettings";
import { GcpFormData } from "./types";
import { useAddAccount } from "./useAddAccount";
import { addGcpSchema } from "./validators";

type Props = {
  closeSidebar: () => void;
};

export function GcpSidebarContent({ closeSidebar }: Props) {
  const formMethods = useForm<GcpFormData>({
    resolver: yupResolver(addGcpSchema),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = formMethods;

  const { addAccount, loading } = useAddAccount(ProviderTab.GCP);

  const [filename, setFilename] = useState("");

  const apiList = [
    "IAM Service Account Credentials API",
    "Service Usage API",
    "Storage API",
    "Cloud Asset API",
    "Cloud Resource Manager API",
    "Recommender API",
    "Cloud SQL Admin API",
  ].map((title) => ({ title }));

  const placeholderText = "Upload service account key json file";
  const placeholder = isEmpty(filename) ? placeholderText : filename;

  const onSubmit = async (formData: GcpFormData) => {
    if (loading) {
      return;
    }

    try {
      const json = await readFile<GcpJsonData>(formData.gcpFile[0]);
      const input = {
        provider: Provider.Gcp,
        providerId: formData.organisationId,
        ownerId: formData.accountOwner,
        name: formData.organisationId,
        displayName: formData.displayName,
        description: formData.description,
        contactIds: formData.contactPerson
          ? [formData.contactPerson]
          : undefined,
        creds: prepareData(json),
      };

      const result = await addAccount({
        variables: {
          input: {
            ...input,
            creds: JSON.stringify(prepareData(json)),
          },
        },
      });

      if (result.data?.addAccountV3?.nid) {
        showSuccess(SUCCESS_TEXT.accountAdded);
        closeSidebar();
      }
    } catch {
      showError(ERROR_TEXT.failedAddingAccount);
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    callback: (fileList: unknown) => void
  ) => {
    const fileList = event.target.files;
    if (fileList instanceof FileList && hasFiles(fileList)) {
      setFilename(fileList[0].name);
    }

    callback(fileList);
  };

  return (
    <FormProvider {...formMethods}>
      <form id="add-gcp-form" onSubmit={handleSubmit(onSubmit)}>
        <InputFields />
        <AdditionalCloudAccountSettings />
        <Text weight="bold">Account Credentials</Text>
        <FormHint stepNumber={1}>Create a project for Klarity</FormHint>
        <FormHint stepNumber={2}>
          Go to the{" "}
          <ExternalLink href={GCP_CLOUD_STORAGE}>Cloud Storage</ExternalLink>{" "}
          and Create Bucket with label:
        </FormHint>
        <Box
          boxStyle="grey"
          mt={theme.spacing.spacing01}
          mb={theme.spacing.spacing03}
        >
          Key: nordcloud-purpose
          <br />
          Value: scanner
        </Box>
        <FormHint stepNumber={3}>
          Go to the{" "}
          <ExternalLink href={GCP_SERVICE_ACCOUNTS}>
            Service Accounts
          </ExternalLink>{" "}
          and create new Service Account
        </FormHint>
        <FormHint stepNumber={4}>
          Go to the Service Account details and create new Key
        </FormHint>
        <Spacer height={theme.spacing.spacing02} />
        <FormGroup error={errors[GcpFormFields.GCP_FILE]}>
          <Controller
            name={GcpFormFields.GCP_FILE}
            rules={{ required: "This field is required." }}
            control={control}
            render={({ field: { onChange } }) => (
              <Upload
                id={GcpFormFields.GCP_FILE}
                placeholder={placeholder}
                onChange={(event) => handleInputChange(event, onChange)}
              />
            )}
          />
        </FormGroup>
        <Spacer height={theme.spacing.spacing05} />
        <FormHint stepNumber={5}>
          Go to the <ExternalLink href={GCP_IAM}>IAM</ExternalLink> and on
          project level add following roles to the Service Account
        </FormHint>
        <CopyBox>Service Account Token Creator</CopyBox>
        <CopyBox>Service Usage Admin</CopyBox>
        <CopyBox>Storage Object Creator</CopyBox>
        <Spacer height={theme.spacing.spacing05} />
        <FormHint stepNumber={6}>
          Switch to the organisation level and add following roles to the
          Service Account
        </FormHint>
        <CopyBox>Cloud Asset Viewer</CopyBox>
        <CopyBox>Viewer</CopyBox>
        <CopyBox>Organization Viewer</CopyBox>
        <Spacer height={theme.spacing.spacing05} />
        <FormHint stepNumber={7}>
          Make sure that the following APIs are enabled on the project level:
          <Spacer height={theme.spacing.spacing03} />
        </FormHint>
        <List
          unordered
          items={apiList}
          css={{
            marginLeft: theme.spacing.spacing02,
          }}
        />
        <Spacer height={theme.spacing.spacing05} />
        <FlexContainer columnGap={theme.spacing.spacing03}>
          <Button
            icon="checkmark"
            initialState={loading ? "loading" : ""}
            css={{ border: 0 }}
            type="submit"
            form="add-gcp-form"
          >
            Apply
          </Button>
          <Button severity="low" type="button" onClick={closeSidebar}>
            Cancel
          </Button>
        </FlexContainer>
      </form>
    </FormProvider>
  );
}

function InputFields() {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<GcpFormData>();

  return (
    <>
      <FormGroup error={errors[GcpFormFields.ORGANISATION_ID]}>
        <Label
          required
          htmlFor={GcpFormFields.ORGANISATION_ID}
          name="Provide Organisation ID"
        />
        <Input
          placeholder="e.g., 123456789012"
          // @ts-expect-error: this line throws error for circular references (most probably react-hook-form type problems)
          {...register(GcpFormFields.ORGANISATION_ID)}
        />
      </FormGroup>
      <FormGroup error={errors[GcpFormFields.ACCOUNT_OWNER] ?? []}>
        <Label
          required
          htmlFor={GcpFormFields.ACCOUNT_OWNER}
          name="Account Owner"
        />
        <ContactsSelector
          onChange={(selectedContact) =>
            setValue(GcpFormFields.ACCOUNT_OWNER, selectedContact)
          }
        />
      </FormGroup>
    </>
  );
}
