/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCloudWastePoliciesQuery } from "~/generated/graphql";
import { isNotNil } from "~/tools";

export function useWasteResourcesQuery() {
  const { data, loading, error } = useCloudWastePoliciesQuery({
    fetchPolicy: "cache-and-network",
  });

  const wastePolicies =
    data?.cloudWasteSummary?.policies
      ?.map((policy) => policy?.id)
      .filter(isNotNil) ?? [];

  const policyMapper = (policy: string, index: number) =>
    `&wastePolicy[${index}]=${policy}`;

  const query = `?page=0${wastePolicies.map(policyMapper).join("")}`;

  return {
    wasteResourcesQuery: query,
    loading,
    error,
  };
}
