/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import { When } from "react-if";
import { Checkbox, FlexContainer, Text } from "@nordcloud/gnui";
import { SidebarItemWrapper } from "~/components";
import { useToggle } from "~/hooks";
import { StyledSVGIcon } from "../styles";
import { CloudWasteOption, PolicyOption } from "../types";
import { PoliciesList } from "./PoliciesList";
import { SwitchWrap } from "./styles";

type Props = {
  cloudWaste: CloudWasteOption;
  selectedCategories: CloudWasteOption[];
  selectedPolicies: PolicyOption[];
  onCategoryCheck: (category: string) => void;
  onPolicyCheck: (policy: PolicyOption) => void;
  isFiltered: boolean;
};

export function SelectableSavingSuggestionRow({
  cloudWaste,
  selectedCategories,
  selectedPolicies,
  onCategoryCheck,
  onPolicyCheck,
  isFiltered,
}: Props) {
  const [isRowOpen, toggleRowOpen] = useToggle(false);
  const { category, categoryName, isInextensible, policies } = cloudWaste;
  const policyCount = policies.length;
  const isCategoryChecked = selectedCategories.some(
    (selectedCategory) => selectedCategory.category === category
  );

  useEffect(() => {
    if (isFiltered && !isRowOpen) {
      toggleRowOpen();
    }
  }, [isFiltered, isRowOpen, toggleRowOpen]);

  return (
    <>
      <FlexContainer>
        <StyledSVGIcon
          isOpen={isRowOpen}
          name="chevronDown"
          data-testid="category-expander"
          disabled={policyCount === 0}
          css={{ visibility: isInextensible ? "hidden" : undefined }}
          onClick={toggleRowOpen}
        />
        <div>
          <SidebarItemWrapper
            hideBottomBorder
            title={categoryName}
            onClick={() => onCategoryCheck(category)}
          >
            <SwitchWrap>
              <Checkbox
                withoutLabel
                readOnly
                data-testid="policy-filter-checkbox"
                checked={isCategoryChecked}
              />
            </SwitchWrap>
            <Text mb="0" align="left">
              {categoryName}
            </Text>
          </SidebarItemWrapper>
        </div>
      </FlexContainer>
      <When condition={isRowOpen}>
        <PoliciesList
          category={cloudWaste}
          selectedPolicies={selectedPolicies}
          isCategoryChecked={isCategoryChecked}
          onPolicyCheck={onPolicyCheck}
        />
      </When>
    </>
  );
}
