/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { ExtendedPaginationBox, Sidebar } from "@nordcloud/gnui";
import { SearchPresetV2 } from "~/generated/graphql";
import { noop } from "~/tools";
import { useSearchPresets } from "../hooks";
import { SearchPresets } from "./SearchPresets";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSearchPresetApply: (searchPreset: SearchPresetV2) => void;
};

export function EstateFiltersToggleSidebar({
  isOpen,
  onClose,
  onSearchPresetApply,
}: Props) {
  const QUERY_SIZE = 20;
  const [nameQuery, setNameQuery] = useState("");
  const [page, setPage] = useState(0);

  const { data, loading } = useSearchPresets({
    page,
    limit: QUERY_SIZE,
    nameQuery,
  });

  const handleClick = (searchPreset: SearchPresetV2) => {
    onSearchPresetApply(searchPreset);
    onClose();
  };

  return (
    <Sidebar
      title="List of predefined filters"
      isOpen={isOpen}
      footer={
        <ExtendedPaginationBox
          sidebar
          small
          size={QUERY_SIZE}
          from={page * QUERY_SIZE}
          count={data?.searchPresetsV2?.count ?? 0}
          setSize={noop}
          setPage={(newPage) => {
            setPage(newPage / QUERY_SIZE);
          }}
        />
      }
      onClick={() => onClose()}
    >
      <SearchPresets
        isLoading={loading}
        presets={data?.searchPresetsV2?.presets ?? []}
        nameQuery={nameQuery}
        setNameQuery={setNameQuery}
        onClick={handleClick}
      />
    </Sidebar>
  );
}
