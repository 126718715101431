/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useUpdateIbmAccountCredentialsMutation } from "~/generated/graphql";
import { Provider } from "../../types";
import { UpdateHandlerVariables } from "../types";

export function useUpdateIbmAccountCredentials() {
  const [updateIbmCloudCredentials, ...mutationState] =
    useUpdateIbmAccountCredentialsMutation();

  const handleIbmCloudCredentialsUpdate = async (
    variables: UpdateHandlerVariables<Provider.Ibmcloud>,
    selectedNid: string
  ) => {
    const { apikey } = variables;

    await updateIbmCloudCredentials({
      variables: {
        id: selectedNid,
        apikey,
      },
    });
  };

  return [handleIbmCloudCredentialsUpdate, mutationState] as const;
}
