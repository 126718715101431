/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  ChartTableSortField,
  ChartTableSortOrder,
  Maybe,
} from "~/generated/graphql";
import { Granularity } from "~/components";
import { EstatePeriod } from "~/constants";

export enum EstateGroupBy {
  NONE = "None",
  APP = "Application",
  ORG = "Organizational Unit",
  ENV = "Environment Type",
  PROVIDER = "Provider",
  REGION = "Region",
  CATEGORY = "Category",
  ACCOUNT = "Cloud Account",
  SERVICE = "Service",
  TAG = "Tags",
}

const estateGroupByLite = [
  EstateGroupBy.NONE,
  EstateGroupBy.PROVIDER,
  EstateGroupBy.REGION,
  EstateGroupBy.CATEGORY,
  EstateGroupBy.ACCOUNT,
  EstateGroupBy.SERVICE,
  EstateGroupBy.TAG,
];

export const estateGroupByOptions = Object.values(EstateGroupBy).map(
  (item) => ({
    value: item,
    label: item,
  })
);

export const estateGroupByLiteOptions = Object.values(estateGroupByLite).map(
  (item) => ({
    value: item,
    label: item,
  })
);

export const estateGroupByRestrictedOptions = Object.values(EstateGroupBy)
  .filter((option) => option !== EstateGroupBy.ORG)
  .map((item) => ({
    value: item,
    label: item,
  }));

export type EstateChartsQueryState = {
  periodStart?: string;
  periodEnd?: string;
  groupBy?: EstateGroupBy;
  granularity?: Granularity;
  estatePeriod?: EstatePeriod;
  groupByTagKey?: string;
  groupByBusinessContext?: string;
  field?: string;
  order?: ChartTableSortOrder;
};

export type GroupTimePoint = {
  id: string;
  name: string;
  value: string;
} | null;

export type ChartTimePoint = {
  date: string;
  value: string;
  groups?: Maybe<GroupTimePoint[]>;
} | null;

export type ChartJobData = {
  chartJob?: Maybe<{
    data?: Maybe<{
      total: string;
      timePoints?: Maybe<ChartTimePoint[]>;
      extraDetails?:
        | ({
            name: string;
            value: string;
          } | null)[]
        | null;
    }>;
  }>;
  chartMonthlyJob?: Maybe<{
    data?: Maybe<{
      total: string;
      timePoints?: Maybe<ChartTimePoint[]>;
    }>;
  }>;
};

export type ExtraChartDetails = ({ name: string; value: string } | null)[];

export type EstateChartTableQueryState = {
  field?: ChartTableSortField;
  order?: ChartTableSortOrder;
  periodStart?: string;
  periodEnd?: string;
  estatePeriod?: EstatePeriod;
  query?: string;
  groupByTagKey?: string;
  groupByBusinessContext?: string;
};

export type BusinessContext = {
  name: string;
  nid: string;
};
