/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import dayjs from "dayjs";
import { useApplicationBudgetQuery } from "~/generated/graphql";
import {
  Period,
  TimePoint,
  TableData,
} from "~/components/Charts/CostAnalysis/types";
import {
  mapCostChartData,
  mapPeriodForCostChartData,
} from "~/components/Charts/CostAnalysis/utils";
import { dateFormat } from "~/constants";
import { isNotEmpty } from "~/tools";

type Props = {
  applicationId: string;
  currentYear: string;
  period: Period;
  accumulatedCostBudgetChartData: TimePoint[];
  startDate: string;
  endDate: string;
  accumulatedCostTimePoints: TimePoint[];
  accumulatedCostTotal: number;
};

export function useCostVersusBudgetChart({
  applicationId,
  currentYear,
  period,
  accumulatedCostBudgetChartData,
  startDate,
  endDate,
  accumulatedCostTimePoints,
  accumulatedCostTotal,
}: Props) {
  const { data, loading, error } = useApplicationBudgetQuery({
    variables: {
      id: applicationId,
      year: currentYear,
      previousYear: (parseInt(currentYear, 10) - 1).toString(),
    },
  });

  const applicationCurrentYearBudgetData =
    data?.application?.budgetYearly.budgetByMonth.map((value, index) => {
      const date = dayjs()
        .startOf("year")
        .add(index, "month")
        .format(dateFormat.shortDate);
      return {
        date,
        value: Number(value),
      };
    }) ?? [];

  const applicationPreviousYearBudgetData =
    data?.application?.prevBudgetYearly.budgetByMonth.map((value, index) => {
      const date = dayjs()
        .subtract(1, "year")
        .startOf("year")
        .add(index, "month")
        .format(dateFormat.shortDate);
      return {
        date,
        value: Number(value),
      };
    }) ?? [];

  const combinedBudgetData = [
    ...applicationPreviousYearBudgetData,
    ...applicationCurrentYearBudgetData,
  ];

  const applicationBudgetData = combinedBudgetData.filter(
    ({ date }) =>
      dayjs(date).isAfter(dayjs().subtract(13, "month")) &&
      dayjs(date).isBefore(dayjs())
  );

  const budgetYearly = useMemo(
    () => ({
      id: data?.application?.budgetYearly.id ?? "",
      budgetByMonth: applicationBudgetData.map(({ value }) => String(value)),
      yearlySum: String(
        applicationBudgetData.reduce((a, b) => a + Number(b.value), 0)
      ),
    }),
    [applicationBudgetData, data]
  );

  const costVersusBudgetChartData = useMemo(
    () =>
      mapCostChartData(
        mapPeriodForCostChartData(period, startDate, endDate),
        accumulatedCostBudgetChartData
      ),
    [period, accumulatedCostBudgetChartData, startDate, endDate]
  );

  const applicationBudgetTotal = Number(budgetYearly.yearlySum);

  const applicationBudgetTable: TableData[] = useMemo(
    () => [
      {
        field: "Cost",
        total: Number(accumulatedCostTotal),
        ...accumulatedCostTimePoints?.reduce(
          (acc, { date, value }) => ({ ...acc, [date]: Number(value ?? 0) }),
          {}
        ),
      },
      {
        field: "Budget",
        total: applicationBudgetTotal,
        ...applicationBudgetData.reduce((acc, { date, value }) => {
          return { ...acc, [date]: value };
        }, {}),
      },
    ],
    [
      accumulatedCostTotal,
      applicationBudgetTotal,
      applicationBudgetData,
      accumulatedCostTimePoints,
    ]
  );

  const hasBudget =
    isNotEmpty(applicationBudgetData ?? []) &&
    applicationBudgetData?.some(({ value }) => value !== 0);

  return {
    costVersusBudgetChartData,
    applicationBudgetTable,
    applicationBudgetData,
    applicationBudgetTotal,
    hasBudget,
    loading,
    error,
  };
}
