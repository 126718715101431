/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState, useCallback, useMemo } from "react";
import { Label } from "@nordcloud/gnui";
import {
  Fieldset,
  FilterWrapper,
  SelectableListWithSearch,
  Mode,
} from "~/components";
import { isArrayFilterSelected } from "~/utils";
import { useInfiniteScroll } from "./hook";

type Props = {
  selectedApplications: string[];
  onSelectApplications: (applicationIds: string[]) => void;
  onClear: () => void;
};

export function ApplicationList({
  selectedApplications,
  onSelectApplications,
  onClear,
}: Props) {
  const [mode, setMode] = useState(Mode.INCLUDE);
  const { loading, elementRef, applications } = useInfiniteScroll();

  const handleInstanceMultiSelect = useCallback(
    (ids: string[]) => {
      onSelectApplications(ids);
    },
    [onSelectApplications]
  );

  const selectableItems = useMemo(
    () =>
      mode === Mode.INCLUDE
        ? applications
        : applications.filter((app) =>
            selectedApplications.includes(app.value)
          ),
    [mode, applications, selectedApplications]
  );

  return (
    <Fieldset>
      <Label name="Permissions" />
      <FilterWrapper
        isExpandedInitially
        name="Applications"
        isSelected={isArrayFilterSelected(selectedApplications)}
        selectedOptions={selectedApplications}
        onClear={onClear}
      >
        <SelectableListWithSearch
          allowModeChange
          isInfiniteScroll
          elementRef={elementRef}
          isLoading={loading}
          selectableItems={selectableItems}
          selectedOptions={selectedApplications}
          onModeChange={(newMode) => setMode(newMode)}
          onMultiSelect={handleInstanceMultiSelect}
        />
      </FilterWrapper>
    </Fieldset>
  );
}
