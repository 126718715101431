/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Maybe } from "~/tools";

export enum Period {
  MONTH = 1,
  QUARTER = 3,
  YEAR = 12,
  RANGE = 13,
}

export enum ChartType {
  ACCUMULATED_COST = "Accumulated Cost",
  ANOMALY_COST = "Anomaly Cost",
  COST_PER_ENVIRONMENT = "Cost per Environment",
  COST_PER_ENVIRONMENT_TYPE = "Cost per Environment Type",
  COST_PER_CATEGORY = "Cost per Category",
  COST_VS_BUDGET = "Cost vs Budget",
  COST_PER_APPLICATION = "Cost per Application",
  COST_PER_SERVICES = "Cost per Service",
  COST_PER_ORG_UNIT = "Cost per Organization Unit",
  COST_PER_PROVIDER = "Cost per Provider",
  COST_PER_REGION = "Cost per Region",
  COST_PER_ACCOUNT = "Cost per Cloud Account",
  COST_PER_TAG = "Cost per Tag",
}

const ApplicationDetailsPageChartType = [
  ChartType.ACCUMULATED_COST,
  ChartType.ANOMALY_COST,
  ChartType.COST_VS_BUDGET,
  ChartType.COST_PER_ENVIRONMENT,
  ChartType.COST_PER_CATEGORY,
];

export enum Granularity {
  DAYS = "days",
  MONTHS = "months",
}

export enum CostAnalysisFields {
  TOTAL_COST = "Total cost",
  OTHER_APPLICATIONS_COST = "All other applications",
  OTHER_CATEGORIES_COST = "All other categories",
  CORRECTION_COST = "Google Cloud invoice month correction",
  DAILY_COST = "Daily cost",
  MONTHLY_COST = "Monthly cost",
  COST_BEFORE_CORRECTION = "Daily cost before correction",
  OTHER_SERVICES_COST = "All other services",
  OTHER_ACCOUNTS_COST = "All other cloud accounts",
  OTHER_REGIONS_COST = "All other regions",
  ANOMALY_COST = "cost",
  SAVINGS_PLANS = "Savings Plans commitments",
  OTHER = "other",
}

// refers to a size of group
export const topSpendingGroup = {
  ENVIRONMENTS: 5,
  APPLICATIONS: 5,
  CATEGORIES: 4,
} as const;

export const chartTypeOptions = Object.values(ChartType)
  .filter((type) => ApplicationDetailsPageChartType.includes(type))
  .map((chartType) => ({
    value: chartType,
    label: chartType,
  }));

export const PlaceholderCategory = {
  UNCATEGORIZED: {
    id: "Uncategorized",
    name: "Uncategorized",
  },
  OTHER: {
    id: "Other",
    name: "Other categories",
  },
} as const;

export type ChartOption = {
  value: ChartType;
  label: ChartType;
};

export type TimePoint = {
  date: string;
  value: number;
};

export type TableData = {
  [key: string]: string[] | number | string | undefined;
  // everything that is not a date-specific cost value is explicitly defined below
  id?: string;
  field: string;
  total: number | string;
  types?: string[];
};

export type CostVsBudgetForecastChartTimePoint = TimePoint & {
  forecast: number;
  budget: number;
};

export type BudgetYearly = {
  id: string;
  budgetByMonth: string[];
  yearlySum: string;
};

export const LinkQueryParameterName = {
  APPLICATION: "application",
  ENVIRONMENT: "environment",
  TYPE_SUBTYPE: "typeSubtype",
  ACCOUNT: "cloudAccounts",
} as const;

type LinkQueryParameter = {
  name: string;
  value: string[];
};

export type LinkParameters = {
  queryParams: LinkQueryParameter[];
  otherCategoryIds: string[];
};

export type ChartDates = { date: string; displayDate: string };

export type ChartsQueryState = {
  page?: number;
  period?: Period;
  granularity?: Granularity;
  chartType?: string;
  rangeStartDate?: string;
  rangeEndDate?: string;
  costTab?: string;
  otherCosts?: boolean;
};

type EnvGroup = {
  id: string;
  name: string;
  value: string;
  types?: Maybe<Maybe<string>[]>;
};

export type TopGroupsCostChartProps = {
  date: string;
  value: string;
  totalCost?: string;
  total?: string;
  groups?: Maybe<Maybe<EnvGroup>[]>;
};

export type DateRange = {
  from: string;
  to: string;
};

export type Anomaly = {
  anomaly: boolean;
  date: string;
  cost: number;
  forecastedCost?: number | null;
  upperCostEstimate?: number | null;
  lowerCostEstimate?: number | null;
  anomalyCost?: number | null;
  isAttached?: boolean;
};

export type TimePointResponse = {
  date: string;
  value: string;
};

export type AccumulatedCostQuery = {
  accumulatedCost: {
    total: string;
    timePoints: TimePointResponse[];
  };
  accumulatedCostTable?: {
    total: string;
    timePoints: TimePointResponse[];
  };
  customerCorrectionCost?: {
    timePoints?: (TimePointResponse | null)[] | null;
  };
};
