/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useUpdateAzureAccountCredentialsMutation } from "~/generated/graphql";
import { Provider } from "../../types";
import { UpdateHandlerVariables } from "../types";

export function useUpdateAzureAccountCredentials() {
  const [updateAzureCredentials, ...mutationState] =
    useUpdateAzureAccountCredentialsMutation();

  const handleAzureCredentialsUpdate = async (
    variables: UpdateHandlerVariables<Provider.Azure>,
    selectedNid: string
  ) => {
    const { tenantId, appId, password } = variables;

    await updateAzureCredentials({
      variables: {
        id: selectedNid,
        tenantId,
        appId,
        password,
      },
    });
  };

  return [handleAzureCredentialsUpdate, mutationState] as const;
}
