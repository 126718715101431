/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { SearchV2Filter } from "~/generated/graphql";
import { DateRange, Mode } from "~/components";
import { dateFormat, EstatePeriod } from "~/constants";
import { FiltersProp } from "~/models/estateRecordRow";
import { isNotEmpty } from "~/tools";
import { CARBON_FOOTPRINT_SUGGESTION } from "./types";

export function getNumberFilterValue(value: string): number | undefined {
  return isNotEmpty(value) ? Number(value) : undefined;
}

export function isDoubleFilterSelected(from?: string, to?: string) {
  return isFilterSelected(from) || isFilterSelected(to);
}

function isFilterSelected(value?: string) {
  return isNotEmpty(value ?? "");
}

export function isExcluded(mode: Mode) {
  return mode === Mode.EXCLUDE ? true : undefined;
}

export function getStartDate(
  period: EstatePeriod,
  range: DateRange | undefined
) {
  const currentMonthStart = dayjs()
    .startOf("month")
    .format(dateFormat.shortDate);

  if (period === EstatePeriod.RANGE) {
    return range?.from ?? currentMonthStart;
  }

  return period === EstatePeriod.PREVIOUS_MONTH
    ? dayjs().subtract(1, "month").startOf("month").format(dateFormat.shortDate)
    : currentMonthStart;
}

export function getEndDate(period: EstatePeriod, range: DateRange | undefined) {
  const currentMonthEnd = dayjs().endOf("month").format(dateFormat.shortDate);

  if (period === EstatePeriod.RANGE) {
    return range?.to ?? currentMonthEnd;
  }

  return period === EstatePeriod.PREVIOUS_MONTH
    ? dayjs().subtract(1, "month").endOf("month").format(dateFormat.shortDate)
    : currentMonthEnd;
}

export function filterExcludeFilters(filters: FiltersProp): FiltersProp {
  const hasExclude = Object.keys(filters).some((key) =>
    key.toLowerCase().includes("exclude")
  );

  if (hasExclude) {
    return {
      ...filters,
      externalIntegrationExclude: isExcludeValid(
        filters.externalIntegrationExclude,
        filters.externalIntegration
      ),
      tagsExclude: isExcludeValid(filters.tagsExclude, filters.tags),
      regionExclude: isExcludeValid(filters.regionExclude, filters.region),
      accountExclude: isExcludeValid(filters.accountExclude, filters.account),
      serviceExclude: isExcludeValid(filters.serviceExclude, filters.service),
      categoryExclude: isExcludeValid(
        filters.categoryExclude,
        filters.category
      ),
      metadataExclude: isExcludeValid(
        filters.metadataExclude,
        filters.metadata
      ),
      providerExclude: isExcludeValid(
        filters.providerExclude,
        filters.provider
      ),
      unassignedExclude:
        filters.unassignedExclude && filters.unassigned
          ? filters.unassignedExclude
          : undefined,
      applicationExclude: isExcludeValid(
        filters.applicationExclude,
        filters.application
      ),
      typeSubtypeExclude: isExcludeValid(
        filters.typeSubtypeExclude,
        filters.typeSubtype
      ),
      wastePolicyExclude: isExcludeValid(
        filters.wastePolicyExclude,
        filters.wastePolicy
      ),
      environmentExclude: isExcludeValid(
        filters.environmentExclude,
        filters.environment
      ),
      firstSeenAtExclude: isExcludedRangeValid(
        filters.firstSeenAtExclude,
        filters.firstSeenAtFrom,
        filters.firstSeenAtTo
      ),
      lastSeenAtExclude: isExcludedRangeValid(
        filters.lastSeenAtExclude,
        filters.lastSeenAtFrom,
        filters.lastSeenAtTo
      ),
      assignmentUpdateExclude: isExcludedRangeValid(
        filters.assignmentUpdateExclude,
        filters.assignmentUpdatedFrom,
        filters.assignmentUpdatedTo
      ),
      costExclude: isExcludedRangeValid(
        filters.costExclude,
        filters.costFrom,
        filters.costTo
      ),
      lifeSpanExclude: isExcludedRangeValid(
        filters.lifeSpanExclude,
        filters.lifeSpanFrom,
        filters.lifeSpanTo
      ),
    };
  }

  return filters;
}

function isExcludeValid(isExcludeSelected?: boolean, item?: string[]) {
  return isExcludeSelected && isNotEmpty(item ?? [])
    ? isExcludeSelected
    : undefined;
}

function isExcludedRangeValid(
  isExcludeSelected?: boolean,
  from?: number | string,
  to?: number | string
) {
  return isExcludeSelected && (from || to) ? isExcludeSelected : undefined;
}

export function filterWasteFilter(
  filters: FiltersProp | SearchV2Filter
): FiltersProp | SearchV2Filter {
  const isCarbonOptionSelected = filters.wastePolicy?.find(
    (policy) => policy === CARBON_FOOTPRINT_SUGGESTION.nid
  );

  if (isCarbonOptionSelected) {
    const wastePolicies =
      filters.wastePolicy?.filter(
        (policy) => policy !== CARBON_FOOTPRINT_SUGGESTION.nid
      ) ?? [];

    return {
      ...filters,
      wastePolicy: isNotEmpty(wastePolicies) ? wastePolicies : undefined,
      waste: filters.wastePolicyExclude ? false : true,
    };
  }

  return filters;
}
