/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  AddEnvironmentMutationVariables,
  useAddEnvironmentMutation,
  useRemoveEnvironmentMutation,
  RemoveEnvironmentMutationVariables,
  Query,
  Application,
} from "~/generated/graphql";
import { removeFromApolloCache } from "~/tools";

export function useAddEnvironment(
  options?: Parameters<typeof useAddEnvironmentMutation>[0]
) {
  const [add, { client, ...rest }] = useAddEnvironmentMutation({
    ...options,
  });

  const addEnvironment = async (variables: AddEnvironmentMutationVariables) => {
    const { data } = await add({ variables });

    client.cache.modify<Application>({
      id: client.cache.identify({
        __typename: "Application",
        id: variables.input.applicationNid,
      }),
      fields: {
        environments: (existingEnvs) => {
          return [
            // @ts-expect-error TODO: fix
            ...existingEnvs,
            ...(data?.addEnvironmentV3 != null ? [data.addEnvironmentV3] : []),
          ];
        },
      },
    });

    void client.refetchQueries({
      updateCache(cache) {
        cache.modify<Query>({
          fields: {
            // Update queries that involve Query.someRootField, without actually
            // changing its value in the cache.
            // @ts-expect-error TODO: fix
            application(value, { INVALIDATE }) {
              if (
                value != null &&
                "__ref" in value &&
                value.__ref === `Application:${variables.input.applicationNid}`
              ) {
                return INVALIDATE;
              }

              return false;
            },
          },
        });
      },
    });
  };

  return [addEnvironment, { client, ...rest }] as const;
}

export function useRemoveEnvironment(
  options?: Parameters<typeof useRemoveEnvironmentMutation>[0]
) {
  const [remove, { client, ...rest }] = useRemoveEnvironmentMutation({
    ...options,
  });

  const removeEnvironment = async (
    variables: RemoveEnvironmentMutationVariables
  ) => {
    await remove({ variables });

    removeFromApolloCache(client.cache, {
      item: `Environment:${variables.nid}`,
    });
  };

  return [removeEnvironment, { client, ...rest }] as const;
}
