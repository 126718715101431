import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ChartJobTableQuery,
  ChartTableSortField,
  ChartTableSortOrder,
  useChartJobTableLazyQuery,
} from "~/generated/graphql";
import { SortDirection } from "~/components";
import { useQueryState } from "~/hooks";
import { ERROR_CONFIG, InputError, isEmpty } from "~/tools";
import { EstateChartTableQueryState } from "../types";
import { POLL_INTERVAL_MS, POLL_INTERVAL_ON_FAIL_MS } from "./constants";
import {
  getDefaultSortOrderByFilter,
  isJobCompleted,
  isJobFailed,
} from "./helpers";

type ChartTableProps = {
  jobId: string;
  limit: number;
  page: number;
  query?: string;
  skip?: boolean;
};

export function useChartTableJob({
  jobId,
  limit,
  page,
  query,
  skip,
}: ChartTableProps) {
  const [errorCount, setErrorCount] = useState(0);
  const [data, setData] = useState<ChartJobTableQuery | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<InputError | undefined>(undefined);
  const {
    state: { field = ChartTableSortField.Cost, order },
    updateQueryState,
  } = useQueryState<EstateChartTableQueryState>();

  const onSortSelect = useCallback(
    (filter: ChartTableSortField) => {
      updateQueryState({
        field: filter,
        order: getDefaultSortOrderByFilter(filter),
      });
    },
    [updateQueryState]
  );

  const onSortOrderChange = useCallback(
    (direction: SortDirection) => {
      updateQueryState({
        field,
        order:
          direction === SortDirection.Descending
            ? ChartTableSortOrder.Dsc
            : ChartTableSortOrder.Asc,
      });
    },
    [updateQueryState, field]
  );

  const [fetchData, { stopPolling, startPolling }] = useChartJobTableLazyQuery({
    // cache-first is not working with pagination
    fetchPolicy: "no-cache",
    pollInterval: POLL_INTERVAL_MS,
    notifyOnNetworkStatusChange: true,
    onCompleted: (result) => {
      if (isJobCompleted(result.chartJobTable?.status)) {
        handleResponse(result);
      }

      if (isJobFailed(result.chartJobTable?.status)) {
        handleResponse(result);
        setError(new Error(ERROR_CONFIG.UNKNOWN));
      }
    },
    onError: (err) => {
      stopPolling();
      if (errorCount < 4) {
        setErrorCount((prevValue) => prevValue + 1);
        startPolling(POLL_INTERVAL_ON_FAIL_MS);
      } else {
        setErrorCount(0);
        setError(err);
      }
    },
  });

  const handleResponse = (tableData: ChartJobTableQuery) => {
    setData(tableData);
    setIsLoading(false);
    stopPolling();
  };

  type QueryChartJobTable = Parameters<typeof fetchData>[0];

  const fetch = useCallback(
    (variables: NonNullable<QueryChartJobTable>["variables"]) => {
      void fetchData({
        variables: {
          jobId: variables?.jobId ?? "",
          limit: variables?.limit ?? 20,
          ...variables,
        },
      });
      setIsLoading(true);
    },
    [fetchData]
  );
  useEffect(() => {
    if (!isEmpty(jobId) && !skip) {
      fetch({
        jobId,
        limit,
        page,
        query,
        order: {
          order: order ?? ChartTableSortOrder.Dsc,
          field,
        },
      });
    }
  }, [jobId, page, limit, query, field, order, skip, fetch]);

  return useMemo(
    () => ({
      isLoading,
      data,
      error,
      field,
      order,
      onSortSelect,
      onSortOrderChange,
    }),
    [isLoading, data, error, field, order, onSortSelect, onSortOrderChange]
  );
}
