import { useEffect, useMemo, useState } from "react";
import { ChartJobQuery, useChartJobLazyQuery } from "~/generated/graphql";
import { ERROR_CONFIG, InputError, isEmpty } from "~/tools";
import { POLL_INTERVAL_MS, POLL_INTERVAL_ON_FAIL_MS } from "./constants";
import { isJobCompleted, isJobFailed } from "./helpers";

export function useChartJob(jobId: string) {
  const [errorCount, setErrorCount] = useState(0);
  const [data, setData] = useState<ChartJobQuery | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<InputError | undefined>(undefined);

  const [fetchData, { stopPolling, startPolling }] = useChartJobLazyQuery({
    fetchPolicy: "no-cache",
    pollInterval: POLL_INTERVAL_MS,
    notifyOnNetworkStatusChange: true,
    onCompleted: (result) => {
      if (
        isJobCompleted(result.chartJob?.status) &&
        isJobCompleted(result.chartMonthlyJob?.status)
      ) {
        handleDataResponse(result);
      }

      if (
        isJobFailed(result.chartJob?.status) ||
        isJobFailed(result.chartMonthlyJob?.status)
      ) {
        handleDataResponse(result);
        setError(new Error(ERROR_CONFIG.UNKNOWN));
      }
    },
    onError: (err) => {
      stopPolling();
      if (errorCount < 4) {
        setErrorCount((prevValue) => prevValue + 1);
        startPolling(POLL_INTERVAL_ON_FAIL_MS);
      } else {
        setErrorCount(0);
        setError(err);
      }
    },
  });

  const handleDataResponse = (response: ChartJobQuery | undefined) => {
    setData(response);
    setIsLoading(false);
    stopPolling();
  };

  useEffect(() => {
    if (!isEmpty(jobId)) {
      setData(undefined);
      void fetchData({
        variables: {
          jobId,
          top: 10,
        },
      });
      setIsLoading(true);
    }
  }, [jobId, fetchData]);

  return useMemo(
    () => ({
      isLoading,
      data,
      error,
    }),
    [isLoading, data, error]
  );
}
