/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { theme } from "@nordcloud/gnui";
import { Provider } from "~/generated/graphql";
import { inflect } from "~/tools";
import { EstateRecord } from "../Ec2Instance/types";
import { AWSHeaderLabels, AzureHeaderLabels, ProviderType } from "./constants";
import { CloudIcon, ProvisionState } from "./types";

export function getRIDetailsTerm(
  createdDate: Date | string,
  expirationDate: Date | string
): string {
  const startDate = dayjs(createdDate).subtract(1, "month");
  const endDate = dayjs(expirationDate);
  const term = endDate.diff(startDate, "year");
  return `${term} ${inflect("year")(term)}`;
}

export function getCloudIconAndColor(provisioningState: string | undefined) {
  switch (provisioningState) {
    case ProvisionState.SUCCEEDED:
      return {
        cloudIcon: CloudIcon.CLOUD_ONLINE,
        color: theme.color.text.success,
      };

    case ProvisionState.CANCELLED:
      return {
        cloudIcon: CloudIcon.CLOUD_OFFLINE,
        color: theme.color.text.error,
      };
    default:
      return {
        cloudIcon: CloudIcon.CLOUD,
        color: theme.color.text.text01,
      };
  }
}

export function getReservationHeaderLabel(provider: Provider) {
  return provider === Provider.Aws
    ? {
        headerId: AWSHeaderLabels.ID,
        headerName: AWSHeaderLabels.TYPE,
      }
    : {
        headerId: AzureHeaderLabels.ID,
        headerName: AzureHeaderLabels.TYPE,
      };
}

export function getCloudProviderFlags(estateRecord: EstateRecord) {
  const isAzureReservation = estateRecord.providerType?.includes(
    ProviderType.MICROSOFT_CAPACITY_RESERVATIONS
  );
  const isAWSReservedInstance = estateRecord.providerType?.includes(
    ProviderType.RESERVED_INSTANCES
  );
  const isAWSSavingsPlan = estateRecord.providerType?.includes(
    ProviderType.SAVINGS_PLANS
  );
  const isReservationProvider =
    isAWSReservedInstance || isAWSSavingsPlan || isAzureReservation;

  return {
    isAWSReservedInstance,
    isAWSSavingsPlan,
    isAzureReservation,
    isReservationProvider,
  };
}
