/**
 * Copyright 2021-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState, useCallback } from "react";
import { Row, Col } from "react-awesome-styled-grid";
import { Else, If, Then, When } from "react-if";
import { theme } from "@nordcloud/gnui";
import {
  ReservationsAndCommitmentsCoverageOfferingType,
  ReservationsAndCommitmentsCoverageServiceUsageType,
  ReservationsAndCommitmentsFilters,
  ReservationsAndCommitmentsLookbackPeriod,
  ReservationsProvider,
  ReservationsRecommendationPaymentOption,
  ReservationsRecommendationTerm,
} from "~/generated/graphql";
import { isNotEmpty, Maybe, noop } from "~/tools";
import { ReservationCommitments } from "./components";
import { useGetCurrentTab, useGetRecommendationBox } from "./hooks";
import { ReservationsCommitmentsWidgets } from "./ReservationsCommitmentsWidgets";
import {
  ComputeSavingsProps,
  PaymentOptionsFilter,
  ReservationsDetails,
} from "./types";

type Props = {
  reservedInstances?: Maybe<ReservationsDetails>;
  savingsPlans?: Maybe<ReservationsDetails>;
  selectedType?: ReservationsAndCommitmentsCoverageOfferingType;
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  timePeriod: ReservationsAndCommitmentsLookbackPeriod;
  paymentOption: ReservationsRecommendationPaymentOption;
  termOption: ReservationsRecommendationTerm;
  filters: ReservationsAndCommitmentsFilters;
  paymentOptionsFilter: PaymentOptionsFilter[];
  provider: ReservationsProvider;
  hasFilters?: boolean;
  onClick?: (type: ReservationsAndCommitmentsCoverageOfferingType) => void;
  onClearFilters?: () => void;
};

export function ReservationsWidgets({
  selectedType,
  serviceUsageType,
  timePeriod,
  paymentOption,
  termOption,
  filters,
  paymentOptionsFilter,
  provider,
  hasFilters = false,
  onClick = noop,
  onClearFilters = noop,
}: Props) {
  const columnSizes = {
    lg: 6,
    md: 4,
    xs: 8,
    fullWidth: 12,
  } as const;

  const { offeringType, savingsPlanType, hasMultiTabs } =
    useGetRecommendationBox({
      serviceUsageType,
      provider,
    });

  const isActive = (
    currentType: ReservationsAndCommitmentsCoverageOfferingType
  ) => {
    return currentType === selectedType;
  };

  const { isComputeTab, isMachineLearningTab } =
    useGetCurrentTab(serviceUsageType);

  const [savingsPlanData, setSavingsPlanData] = useState<ComputeSavingsProps>({
    savingsPlansCommitments: [],
    loading: true,
  });

  const setComputeSavingsData = useCallback(
    (data: ComputeSavingsProps) => {
      setSavingsPlanData((prevState) => ({
        ...prevState,
        ...data,
      }));
    },
    [setSavingsPlanData]
  );

  const isOfferingType =
    selectedType ===
    ReservationsAndCommitmentsCoverageOfferingType.SavingsPlans;
  const isServiceUsageType =
    (isOfferingType && isComputeTab) || isMachineLearningTab;
  const hasSavingsPlansCommitment = isNotEmpty(
    savingsPlanData.savingsPlansCommitments
  );

  const showComputeSavingsSection =
    hasSavingsPlansCommitment && isServiceUsageType;

  const commitmentLabel = isComputeTab
    ? "Compute Savings Plan Commitments"
    : "Machine Learning Savings Plan Commitment";

  return (
    <>
      <Row css={{ rowGap: theme.spacing.spacing06 }}>
        <If condition={hasMultiTabs}>
          <Then>
            <Col lg={columnSizes.lg} md={columnSizes.md} xs={columnSizes.xs}>
              <ReservationsCommitmentsWidgets
                hasSelectable
                type={offeringType}
                serviceUsageType={serviceUsageType}
                timePeriod={timePeriod}
                setComputeSavingsData={setComputeSavingsData}
                filters={filters}
                term={termOption}
                paymentOption={paymentOption}
                paymentOptionsFilter={paymentOptionsFilter}
                provider={provider}
                hasFilters={hasFilters}
                selected={isActive(
                  ReservationsAndCommitmentsCoverageOfferingType.All
                )}
                onClick={onClick}
                onClearFilters={onClearFilters}
              />
            </Col>
            <Col lg={columnSizes.lg} md={columnSizes.md} xs={columnSizes.xs}>
              <ReservationsCommitmentsWidgets
                hasSelectable
                serviceUsageType={serviceUsageType}
                timePeriod={timePeriod}
                setComputeSavingsData={setComputeSavingsData}
                filters={filters}
                term={termOption}
                paymentOption={paymentOption}
                paymentOptionsFilter={paymentOptionsFilter}
                provider={provider}
                type={savingsPlanType}
                hasFilters={hasFilters}
                selected={isActive(
                  ReservationsAndCommitmentsCoverageOfferingType.SavingsPlans
                )}
                onClick={onClick}
                onClearFilters={onClearFilters}
              />
            </Col>
          </Then>
          <Else>
            <Col lg={columnSizes.fullWidth}>
              <ReservationsCommitmentsWidgets
                selected
                hasSelectable={false}
                type={offeringType}
                serviceUsageType={serviceUsageType}
                timePeriod={timePeriod}
                setComputeSavingsData={setComputeSavingsData}
                filters={filters}
                term={termOption}
                paymentOption={paymentOption}
                paymentOptionsFilter={paymentOptionsFilter}
                provider={provider}
                hasFilters={hasFilters}
                onClick={onClick}
                onClearFilters={onClearFilters}
              />
            </Col>
          </Else>
        </If>
      </Row>
      <When condition={showComputeSavingsSection}>
        <ReservationCommitments
          savingsPlanData={savingsPlanData}
          commitmentLabel={commitmentLabel}
        />
      </When>
    </>
  );
}
