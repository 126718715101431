/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { PropsWithChildren } from "react";
import { Box, FlexContainer, theme } from "@nordcloud/gnui";

export function SearchBarContainer({ children }: PropsWithChildren<{}>) {
  return (
    <Box boxStyle="lightGrey">
      <FlexContainer css={{ width: "100" }} gap={theme.spacing.spacing04}>
        {children}
      </FlexContainer>
    </Box>
  );
}
