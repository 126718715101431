/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useRef } from "react";
import { When } from "react-if";
import { useClickAway, useToggle } from "react-use";
import {
  Box,
  Button,
  SVGIcon,
  SelectButton,
  Spacer,
  Text,
  theme,
} from "@nordcloud/gnui";
import { EstatePeriod } from "~/constants";
import { useDateValues } from "~/hooks";
import { DateRange, Period } from "../Charts/CostAnalysis/types";
import { Separator } from "../Styled";
import { DatePreselects } from "./DatePreselects";
import { DateRangeInputs } from "./DateRangeInputs";
import { useDateRangeSelector } from "./hooks/useDateRangeSelector";
import { ButtonWrapper, Content, Wrapper } from "./styles";

type Props = {
  isActive: boolean;
  onApply: (range?: DateRange) => void;
  label: string;
  period: EstatePeriod | Period;
  onClear: () => void;
  range?: DateRange;
  disabled?: boolean;
  showPreselects?: boolean;
};

export function DateRangeSelector({
  isActive,
  onApply,
  label,
  period,
  onClear,
  range,
  disabled = false,
  showPreselects = false,
}: Props) {
  const [isOpen, toggleIsOpen] = useToggle(false);
  const wrapper = useRef<HTMLDivElement>(null);
  const {
    endDate,
    setEndDate,
    startDate,
    setStartDate,
    previousPeriodSelected,
    setPreviousPeriodSelected,
    handlePrevPeriodClick,
  } = useDateValues(range);

  const { isApplyDisabled, isError, errorMessage } = useDateRangeSelector({
    startDate: startDate ?? "",
    endDate: endDate ?? "",
    range,
  });

  useClickAway(wrapper, () => toggleIsOpen(false));

  const handleRangeClick = useCallback(() => {
    if (period !== EstatePeriod.RANGE && period !== Period.RANGE && range) {
      onApply(range);
    } else {
      toggleIsOpen();
    }
  }, [period, range, onApply, toggleIsOpen]);

  const handleApply = useCallback(() => {
    onApply({
      from: startDate ?? "",
      to: endDate ?? "",
    });
    toggleIsOpen();
  }, [startDate, endDate, onApply, toggleIsOpen]);

  const handleClear = () => {
    onClear();
    setStartDate(undefined);
    setEndDate(undefined);
    setPreviousPeriodSelected(undefined);
  };

  return (
    <Wrapper ref={wrapper}>
      <ButtonWrapper>
        <SelectButton
          name="range"
          value="13"
          labelText={label}
          isActive={isActive}
          disabled={disabled}
          style={{
            whiteSpace: "nowrap",
            minWidth: "7.5rem",
          }}
          onClick={handleRangeClick}
        />
        <When condition={label !== "Date Range"}>
          <Button
            icon="close"
            size="sm"
            severity={isActive ? undefined : "medium"}
            css={{ border: "none" }}
            disabled={disabled}
            onClick={handleClear}
          />
        </When>
      </ButtonWrapper>
      <Content isVisible={isOpen}>
        <Box innerSpacing="spacing03" shadow="shadow04">
          <When condition={showPreselects}>
            <DatePreselects
              periodSelected={previousPeriodSelected}
              handleClick={handlePrevPeriodClick}
            />
          </When>
          <DateRangeInputs
            startDate={startDate ?? ""}
            endDate={endDate ?? ""}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
          <When condition={isError}>
            <Spacer height={theme.spacing.spacing02} />
            <SVGIcon name="warning" color="danger" />
            <Text
              tag="span"
              size="md"
              color={theme.color.text.error}
              ml={theme.spacing.spacing01}
            >
              {errorMessage}
            </Text>
          </When>
          <Separator css={{ marginTop: theme.spacing.spacing04 }} />
          <Button
            disabled={isApplyDisabled}
            size="md"
            css={{ width: "98%", display: "inline-block" }}
            marginTop={theme.spacing.spacing04}
            onClick={handleApply}
          >
            Apply
          </Button>
        </Box>
      </Content>
    </Wrapper>
  );
}
