/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { Else, If, Then, When } from "react-if";
import { theme, Text } from "@nordcloud/gnui";
import { dateFormat } from "~/constants";
import { QueryStateFilterValue, useCurrency } from "~/hooks";
import { Currency, formatMoney, inflect, isNotEmpty } from "~/tools";

type Props = {
  name: string;
  value: QueryStateFilterValue;
};

export function TagValue({ name, value }: Props) {
  const { currency } = useCurrency();
  const isDateValue = ["discoveryTime", "unallocated"].includes(name);
  const isPairValue = ["lifeSpan"].includes(name);
  const isCostPairValue = ["monthlyCost"].includes(name);

  const isNotPairValue = !(isDateValue || isPairValue || isCostPairValue);
  return (
    <>
      <If condition={isNotPairValue}>
        <Then>
          <Text size="sm" mr={theme.spacing.spacing02} mb={0}>
            {formatTagValue(name, value, currency)}
          </Text>
        </Then>
        <Else>
          <When condition={isDateValue}>{formatDatePairValue(value)}</When>
          <When condition={isPairValue}>{formatDaysRangeValue(value)}</When>
          <When condition={isCostPairValue}>
            {formatPairCostValue(value, currency)}
          </When>
        </Else>
      </If>
    </>
  );
}

function splitValues(value: QueryStateFilterValue) {
  const filterValue = value?.toString() ?? "";
  const filterValues = filterValue.split(",");

  const from = filterValues[0] ?? "";
  const to = filterValues[1] ?? "";

  return {
    from,
    to,
  };
}

function formatDatePairValue(value: QueryStateFilterValue) {
  const { from, to } = splitValues(value);

  return pairValueText(
    isValue(from)
      ? dayjs(from).format(dateFormat.dayMonthYearShortNoComma)
      : "Undefined",
    isValue(to)
      ? dayjs(to).format(dateFormat.dayMonthYearShortNoComma)
      : "Undefined"
  );
}

function pairValueText(from: string, to: string) {
  return (
    <Text size="sm" mr={theme.spacing.spacing02} mb={0}>
      from:{" "}
      <b style={{ fontWeight: theme.fontWeights.medium }}>
        {isValue(from) ? from : "Undefined"}
      </b>{" "}
      to:{" "}
      <b style={{ fontWeight: theme.fontWeights.medium }}>
        {isValue(to) ? to : "Undefined"}
      </b>
    </Text>
  );
}

function formatDaysRangeValue(value: QueryStateFilterValue) {
  const filterValue = value?.toString() ?? "";
  const filterValues = filterValue.split(",");

  const [from, to] = filterValues;

  return pairValueText(
    isValue(from) ? `${from} day(s)` : "Undefined",
    isValue(to) ? `${to} day(s)` : "Undefined"
  );
}

function formatPairCostValue(value: QueryStateFilterValue, currency: Currency) {
  const filterValue = value?.toString() ?? "";
  const filterValues = filterValue.split(",");

  const [from, to] = filterValues;

  return pairValueText(
    isValue(from) ? `${formatMoney(from, currency)}` : "Undefined",
    isValue(to) ? `${formatMoney(to, currency)}` : "Undefined"
  );
}

function formatTagValue(
  name: string,
  value: QueryStateFilterValue,
  currency: Currency
) {
  const lowerCaseName = name.toLowerCase();
  const filterValue = value?.toString() ?? "";
  if (lowerCaseName.includes("updated") || lowerCaseName.includes("seen")) {
    return dayjs(filterValue?.toString()).format(dateFormat.dayMonthYear);
  }

  if (lowerCaseName.includes("cost")) {
    return `${formatMoney(filterValue, currency)}`;
  }

  if (lowerCaseName.includes("span")) {
    return `${filterValue} ${inflect("day")(Number(filterValue))}`;
  }

  if (lowerCaseName.includes("unassigned")) {
    return "All Unallocated Estate Records";
  }
}

function isValue(value: string) {
  return value && isNotEmpty(value) && value !== "undefined";
}
