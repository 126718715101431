/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Item } from "../../components/Item";

type Props = {
  paymentOption: string;
  expirationDate: string;
  startDate: string;
  term: string;
  firstSeen: string | undefined;
  lastSeen: string | undefined;
};

export function InstanceDetails({
  paymentOption,
  term,
  firstSeen,
  lastSeen,
  startDate,
  expirationDate,
}: Props) {
  return (
    <>
      <Item label="Payment Option" value={paymentOption} />
      <Item label="Term" value={term} />
      <Item value={startDate} label="Start Date" />
      <Item value={expirationDate} label="End Date" />
      <Item label="First Seen" value={firstSeen} />
      <Item label="Last Seen" value={lastSeen} />
    </>
  );
}
