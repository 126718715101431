/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FlexContainer, theme, Text, Box, BrickLoader } from "@nordcloud/gnui";
import { formatMoney } from "~/tools";
import { LoaderWrap } from "../Utils";
import { GridItemWrapper } from "./components";
import { CostGrid, CostWrapper, CustomWrapper } from "./styles";

type Props = {
  monthlyCost: number;
  upfrontCost: number;
  currency: string;
  loading?: boolean;
};
export function ReservationCostBox({
  monthlyCost,
  upfrontCost,
  currency,
  loading = false,
}: Props) {
  return (
    <Box
      boxStyle="lightGrey"
      css={{ height: "100%" }}
      padding={
        loading ? theme.spacing.spacing00 : `${theme.spacing.spacing03} 0`
      }
    >
      <LoaderWrap
        loading={loading}
        Component={BrickLoader}
        viewBox="0 0 1.75 1"
      >
        <CostGrid>
          <CostWrapper>
            <ReservationCost
              cost={monthlyCost}
              currency={currency}
              label="Recurring Payment Amount"
            />
          </CostWrapper>
          <CustomWrapper>
            <ReservationCost
              cost={upfrontCost}
              currency={currency}
              label="Upfront Payment Amount"
            />
          </CustomWrapper>
        </CostGrid>
      </LoaderWrap>
    </Box>
  );
}

function ReservationCost({ cost, currency, label }: CostProps) {
  return (
    <GridItemWrapper title={label}>
      <FlexContainer gap={theme.spacing.spacing02}>
        <Text
          tag="div"
          size="lg"
          weight="medium"
          color={theme.color.text.text01}
        >
          {formatMoney(cost, currency)}
        </Text>
      </FlexContainer>
    </GridItemWrapper>
  );
}

type CostProps = {
  cost: number;
  currency: string;
  label: string;
};
