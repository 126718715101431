/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useRef, useState } from "react";
import { When } from "react-if";
import styled from "styled-components";
import { FlexContainer, Loader } from "@nordcloud/gnui";
import { FilterHeader, Mode } from "~/components";
import { FiltersProp } from "~/models/estateRecordRow";
import { isNotEmpty } from "~/tools";
import { useEnvFilterSelector, useInfiniteApplications } from "../hooks";
import { filterUniqueEnvironments } from "../hooks/utils";
import { SelectableApplicationRow } from "./SelectableApplicationRow";

type Props = {
  selectedEnvironments: string[];

  isCleared: boolean;
  onMultiSelect: (filter: FiltersProp) => void;
  selectedApplications?: string[];
  headerProps?: {
    allowModeChange?: boolean;
    defaultMode?: Mode;
    onModeChange?: (mode: Mode) => void;
  };
};

const defaultHeaderProps = {};

export function AppEnvAccordion({
  selectedEnvironments,
  selectedApplications = [],
  isCleared,
  onMultiSelect,
  headerProps = defaultHeaderProps,
}: Props) {
  const [applicationName, setApplicationName] = useState("");
  const elementRef = useRef(null);

  const {
    isAppsLoading,
    applications,
    selectedEnvs,
    selectedAppEnvs,
    setSelectedEnvs,
  } = useInfiniteApplications({
    elementRef,
    selectedEnvironments,
    selectedApplications,
    applicationName,
    isCleared,
  });

  const { selectApp, selectEnv } = useEnvFilterSelector({
    apps: applications,
    selectedEnvs,
    setSelectedEnvs,
  });

  useEffect(() => {
    onMultiSelect({
      environment: selectedEnvs.map(({ nid }) => nid),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEnvs]);

  // should be applied only when application query parameter is provided
  useEffect(() => {
    if (isNotEmpty(selectedApplications)) {
      setSelectedEnvs((prevState) =>
        filterUniqueEnvironments([...prevState, ...selectedAppEnvs])
      );
    }
  }, [selectedAppEnvs, selectedApplications]);

  return (
    <>
      <FilterHeader
        showSearchBar
        onSetSearch={setApplicationName}
        {...headerProps}
      />
      <SelectableApplicationRowWrapper>
        {applications.map((application) => (
          <SelectableApplicationRow
            key={application.nid}
            application={application}
            selectedEnvs={selectedEnvs}
            onAppCheck={selectApp}
            onEnvCheck={selectEnv}
          />
        ))}
        <When condition={isAppsLoading}>
          <Loader inContent />
        </When>
        <div ref={elementRef} />
      </SelectableApplicationRowWrapper>
    </>
  );
}

const SelectableApplicationRowWrapper = styled(FlexContainer)`
  align-items: flex-start;
  flex-direction: column;
  max-height: 20rem;
  overflow-y: scroll;
  overflow-x: hidden;
`;
