/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import * as queryString from "query-string";
import { generatePath } from "react-router-dom";
import { validate } from "uuid";
import {
  Granularity as APIGranularity,
  GroupTimePoint,
} from "~/generated/graphql";
import { dateFormat, EstatePeriod } from "~/constants";
import { ROUTES } from "~/routing/routes";
import { periodDayJs } from "~/services/customers";
import {
  formatMoneyWithoutSymbol,
  generateNumericArray,
  includes,
  isEmpty,
  isNotEmpty,
  isNotNil,
} from "~/tools";
import { TimePoint } from "../types";
import {
  accumulatedCostChartColors,
  accumulatedCostColor,
  chartTypesWithLinks,
  invoiceCorrectionColor,
  otherCostsColor,
  savingsPlanColor,
  stackBarChartColors,
} from "./components/consts";
import {
  ChartType,
  CostAnalysisFields,
  Granularity,
  LinkParameters,
  Period,
  PlaceholderCategory,
  TableData,
  TopGroupsCostChartProps,
} from "./types";

export function mapGranularity(granularity: Granularity) {
  return granularity === Granularity.DAYS
    ? APIGranularity.Daily
    : APIGranularity.Monthly;
}

export function getTableGranularity(granularity: Granularity, period: Period) {
  if (
    period === Period.QUARTER ||
    period === Period.RANGE ||
    period === Period.YEAR
  ) {
    return APIGranularity.Monthly;
  }

  return mapGranularity(granularity);
}

export function getColorsPalette(chart: ChartType): string[] {
  if (chart === ChartType.ACCUMULATED_COST) {
    return accumulatedCostChartColors;
  }

  return stackBarChartColors;
}

export function mapStackBarChartColors(groups: (number | string)[]) {
  return Object.fromEntries(
    groups.map((value, index) => [value, getColor(String(value), index)])
  );
}

function getColor(value: string, index: number) {
  if (isOtherCost(value)) {
    return otherCostsColor;
  }
  if (value === CostAnalysisFields.CORRECTION_COST) {
    return invoiceCorrectionColor;
  }
  if (value === CostAnalysisFields.SAVINGS_PLANS) {
    return savingsPlanColor;
  }
  if (
    value === CostAnalysisFields.MONTHLY_COST ||
    value === CostAnalysisFields.DAILY_COST
  ) {
    return accumulatedCostColor;
  }
  return stackBarChartColors[index];
}

function isOtherCost(key: string) {
  const otherCosts = [
    CostAnalysisFields.OTHER_APPLICATIONS_COST,
    CostAnalysisFields.OTHER_CATEGORIES_COST,
    CostAnalysisFields.OTHER_SERVICES_COST,
    CostAnalysisFields.OTHER_ACCOUNTS_COST,
    CostAnalysisFields.OTHER_REGIONS_COST,
    CostAnalysisFields.OTHER,
  ];

  return includes(otherCosts, key);
}

export function filterStackBarColors(
  showOtherApplications: boolean,
  numberOfItems: number
) {
  return showOtherApplications
    ? stackBarChartColors.filter(
        (_, index) => index >= stackBarChartColors.length - numberOfItems
      )
    : stackBarChartColors;
}

export function getTooltipDate(
  value: string,
  period: Period,
  granularity: Granularity
) {
  if (period === Period.RANGE) {
    return dayjs(value).format(
      granularity === "days" ? dateFormat.dayMonthYear : dateFormat.monthYear
    );
  }

  return dayjs(value).format(
    granularity === "days" ? dateFormat.dayMonthShort : dateFormat.monthShort
  );
}

function isFieldTypeCategorized(fieldType: string | undefined) {
  return fieldType !== PlaceholderCategory.UNCATEGORIZED.name;
}

function isLinkVisible(chartType: ChartType, data?: TableData): boolean {
  const showLinks = chartTypesWithLinks.includes(chartType);
  const hasData = isNotEmpty(`${data?.id ?? ""}`);
  const isValidId = validate(data?.id ?? "");

  return (
    showLinks && isFieldTypeCategorized(data?.field) && hasData && isValidId
  );
}

function getEstateRecordsCategoryLink(
  categoryIds: string[],
  parameters: LinkParameters
) {
  const queryParameters = {
    category: categoryIds,
    page: 0,
    ...(parameters?.queryParams ?? []).map((parameter) => {
      return { [parameter.name]: categoryIds };
    }),
  };

  return `${ROUTES.estateRecords.index}?${queryString.stringify(
    queryParameters,
    {
      arrayFormat: "index",
    }
  )}`;
}

function getEstateRecordsServiceLink(
  types: string[],
  parameters: LinkParameters
) {
  const queryParameters = {
    typeSubtype: types,
    page: 0,
    ...(parameters?.queryParams ?? []).map((parameter) => {
      return { [parameter.name]: types };
    }),
  };

  return `${ROUTES.estateRecords.index}?${queryString.stringify(
    queryParameters,
    {
      arrayFormat: "index",
    }
  )}`;
}

export function generateLinks(
  chartType: ChartType,
  params?: LinkParameters,
  data?: TableData
) {
  const isNotVisible = !isLinkVisible(chartType, data) || params === undefined;

  if (isNotVisible) {
    return undefined;
  }

  if (chartType === ChartType.COST_PER_APPLICATION) {
    return generatePath(ROUTES.applications.details, {
      application: data?.id ?? "",
    });
  }

  if (chartType === ChartType.COST_PER_CATEGORY) {
    return getEstateRecordsCategoryLink(
      data?.id === PlaceholderCategory.OTHER.id
        ? params?.otherCategoryIds ?? []
        : asIdArray(data?.id),
      params
    );
  }

  if (chartType === ChartType.COST_PER_SERVICES) {
    return getEstateRecordsServiceLink(data?.types ?? [], params);
  }

  if (chartType === ChartType.COST_PER_ACCOUNT) {
    return generatePath(ROUTES.cloudAccounts.details, {
      nid: data?.id ?? "",
    });
  }

  const queryParameters = {
    page: 0,
  };
  Object.assign(
    queryParameters,
    ...(params?.queryParams ?? []).map((parameter) => {
      return {
        [parameter.name]: isEmpty(parameter.value)
          ? asIdArray(data?.id)
          : parameter.value,
      };
    })
  );

  return `${ROUTES.estateRecords.index}?${queryString.stringify(
    queryParameters,
    {
      arrayFormat: "index",
    }
  )}`;
}

export function generateLinkParameters(
  name: string,
  value: string[],
  otherCategoryIds?: string[]
) {
  return {
    queryParams: [{ name, value }],
    otherCategoryIds: otherCategoryIds ?? [],
  };
}

export function getTickDateFormatStackBarChart(granularity: Granularity) {
  return granularity === Granularity.DAYS
    ? dateFormat.dayMonthShort
    : dateFormat.monthYearShortNoComma;
}

export function getTicksNumStackBarChart(
  width: number,
  period: EstatePeriod | Period
): number {
  const xTicksBreakpoint = 1000;

  return width > xTicksBreakpoint &&
    [Period.MONTH, EstatePeriod.PREVIOUS_MONTH].includes(period)
    ? periodDayJs().daysInMonth()
    : periodDayJs().daysInMonth() / 3;
}

export function getXDatesStackBarChart(
  xTicks: number,
  startDate: string,
  granularity: Granularity
) {
  return generateNumericArray(xTicks).map((_, index) =>
    dayjs(startDate)
      .add(index, granularity)
      .startOf(granularity)
      .format(dateFormat.shortDate)
  );
}

export function getEndDate(endDate: string) {
  if (dayjs().isAfter(endDate)) {
    return endDate;
  }

  return dayjs().format(dateFormat.shortDate);
}

export function extractCategoryIds(costTimePoints: GroupTimePoint[]): string[] {
  return [
    ...new Set<string>(
      costTimePoints
        .flatMap((timePoint) => timePoint.groups?.map((group) => group?.id))
        .filter(isNotNil)
    ),
  ].filter((categoryId) => categoryId !== PlaceholderCategory.UNCATEGORIZED.id);
}

function asIdArray(id: string | undefined): string[] {
  if (id) {
    return [id];
  }
  return [];
}

export function mapSwitcherGroupLabel(chartType?: ChartType) {
  switch (chartType) {
    case ChartType.COST_PER_CATEGORY:
      return CostAnalysisFields.OTHER_CATEGORIES_COST;
    case ChartType.COST_PER_SERVICES:
      return CostAnalysisFields.OTHER_SERVICES_COST;
    case ChartType.COST_PER_APPLICATION:
      return CostAnalysisFields.OTHER_APPLICATIONS_COST;
    default:
      return CostAnalysisFields.OTHER_APPLICATIONS_COST;
  }
}

export function getAllDayValues(arr: { date: string }) {
  return Object.entries(arr)
    .filter(([key]) => key !== "date")
    .map(([, value]) =>
      Number(formatMoneyWithoutSymbol(Number(value).toFixed(2)))
    );
}

export function mapDataWithOtherCosts(
  data: TopGroupsCostChartProps[],
  showOthers: boolean,
  chartType?: ChartType
) {
  return data?.map((item) => {
    const itemGroups =
      Object.fromEntries(
        item?.groups
          ?.filter(isNotNil)
          .map(({ name, value }) => [
            name,
            Number(getValue(value)).toFixed(2),
          ]) ?? []
      ) ?? [];

    // Other costs are not shown for future values
    if (!showOthers || dayjs(item.date).isAfter(dayjs(), "day")) {
      return {
        ...itemGroups,
        date: item.date,
      };
    }

    // if totalCost is empty means that otherCost comes with a group
    // and in that case there is no need to do additional calculations
    if (isNotNil(item?.totalCost)) {
      const otherTotal = Number(item?.totalCost) - Number(item?.value ?? 0);

      const otherGroup = {
        [mapSwitcherGroupLabel(chartType)]: otherTotal.toFixed(2),
      };

      return {
        ...itemGroups,
        ...otherGroup,
        date: item.date,
      };
    }

    return {
      ...itemGroups,
      date: item.date,
    };
  });
}

export function getNoDataMessage(
  isCostsBelowCent: boolean,
  granularity: Granularity
) {
  return isCostsBelowCent && granularity === Granularity.DAYS
    ? "Daily cost values that are less than 0.01 are not displayed"
    : undefined;
}

export function isValueLessThanCent(value: number | string) {
  return Number(value) >= 0 && Number(value) < 0.01;
}

function stringToNumber(value: string) {
  return isNaN(Number(value)) ? 0 : Number(value);
}

function getValue(value: string) {
  const cost = stringToNumber(value);

  return formatMoneyWithoutSymbol(cost);
}

export function getNumberOfTicks(endDate: string, granularity: Granularity) {
  return dayjs(endDate).endOf(granularity);
}

export function getEndDateDayjs(endDate: string, granularity: Granularity) {
  return granularity === Granularity.MONTHS
    ? dayjs(endDate).endOf(Granularity.MONTHS)
    : dayjs(endDate).endOf(Granularity.DAYS);
}

export function mapTimePointValue(timePoint: TimePoint) {
  return {
    ...timePoint,
    value: Number(timePoint.value),
  };
}
