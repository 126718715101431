/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloError } from "@apollo/client";
import { BusinessContext } from "~/generated/graphql";
import { SimpleError, createContext_ } from "~/tools";
import { MappedEnvironmentV2 } from "~/views/applications/ApplicationsPage/components/types";

type EnvironmentsProps = {
  id: string;
  limit: number;
  page: number;
};

type Props = {
  businessContexts?: Partial<BusinessContext>[] | null;
  primaryBusinessContextId?: string;
  openSidebar: () => void;
  setEnvironmentId: (id: string) => void;
  setApplicationId: (id: string) => void;
  applicationId: string;
  getEnvironments: ({
    id,
    limit,
    page,
  }: EnvironmentsProps) => Promise<Partial<MappedEnvironmentV2[]>>;
  isLoadingEnvironments: boolean;
  errorEnvironments?: ApolloError | Error | SimpleError;
  refetchEnvironments: () => void;
};

export const [useApplications, ApplicationsProvider] = createContext_<Props>();
