/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { groupBy } from "lodash";

import {
  TimePoint,
  useGetAnomaliesQuery,
  useGetApplicationAccumulatedCostsQuery,
} from "~/generated/graphql";
import {
  getTableGranularity,
  mapGranularity,
} from "~/components/Charts/CostAnalysis";
import {
  Anomaly,
  Granularity,
  Period,
  TableData,
  TimePoint as TimePointValue,
} from "~/components/Charts/CostAnalysis/types";
import { dateFormat } from "~/constants";
import { generateNumericArray, isNotEmpty, isNotNil, sum } from "~/tools";
import { getMonthDifference, mapAnomaliesChartData } from "./utils";

dayjs.extend(customParseFormat);

type Props = {
  appId: string;
  period: Period;
  startDate: string;
  endDate: string;
  granularity: Granularity;
};

export function useAnomaliesChart({
  appId,
  period,
  startDate,
  endDate,
  granularity,
}: Props) {
  const {
    data,
    loading,
    error: anomaliesError,
    refetch,
  } = useGetAnomaliesQuery({
    variables: {
      input: {
        applicationId: appId,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: accumulatedCostData,
    loading: isAccumulatedCostLoading,
    error: accumulatedCostError,
  } = useGetApplicationAccumulatedCostsQuery({
    variables: {
      applicationId: appId,
      startDate: dayjs().startOf("month").format(dateFormat.shortDate),
      endDate: dayjs().format(dateFormat.shortDate),
      granularity: mapGranularity(granularity),
      tableGranularity: getTableGranularity(granularity, period),
    },
  });

  const anomaliesData = (data?.anomalies ?? []).filter(isNotNil);

  const accumulatedCostChartData = useMemo(
    () =>
      (accumulatedCostData?.applicationAccumulatedCost?.timePoints ?? [])
        .filter(isNotNil)
        .map(mapTimePointValue),
    [accumulatedCostData]
  );

  const anomaliesDataPerPeriod = mapAnomaliesChartData(
    anomaliesData,
    {
      startDate,
      endDate,
    },
    granularity
  );

  const anomaliesChartData = isNotEmpty(anomaliesDataPerPeriod)
    ? mapAnomaliesAndAccumulatedCostData(
        anomaliesDataPerPeriod,
        accumulatedCostChartData
      )
    : anomaliesDataPerPeriod;

  const applicationAnomaliesByMonth = useMemo(
    () => getAnomaliesByMonth(anomaliesChartData, startDate, endDate),
    [period, startDate, data, granularity]
  );

  const applicationAnomaliesTableData: TableData[] = useMemo(
    () => [
      {
        field: "Expected Cost",
        ...applicationAnomaliesByMonth?.reduce(
          (acc, { date, cost, anomalyCost }) => ({
            ...acc,
            total: Number(acc.total) + (cost - anomalyCost),
            [date]: cost - anomalyCost,
          }),
          { total: 0 }
        ),
      },
      {
        field: "Anomaly Cost",
        total: sum(
          applicationAnomaliesByMonth.map((item) => item.anomalyCost ?? 0)
        ).toString(),
        ...applicationAnomaliesByMonth?.reduce(
          (acc, { date, anomalyCost }) => ({
            ...acc,
            [date]: anomalyCost,
          }),
          {}
        ),
      },
    ],
    [applicationAnomaliesByMonth]
  );

  const anomaliesTotal = sum(
    applicationAnomaliesByMonth.map((item) => Number(item.cost) ?? 0)
  );

  const isLoading = loading || isAccumulatedCostLoading;
  const error = anomaliesError || accumulatedCostError;

  return {
    anomaliesData,
    anomaliesChartData,
    applicationAnomaliesByMonth,
    applicationAnomaliesTableData,
    anomaliesTotal,
    isLoading,
    error,
    refetch,
  };
}

function getAnomaliesByMonth(
  anomaliesData: Anomaly[] | undefined,
  startDate: string,
  endDate: string
) {
  const months = generateNumericArray(
    getMonthDifference(startDate, endDate) + 1
  ).map((_, index) =>
    dayjs(startDate).add(index, "month").format(dateFormat.shortDate)
  );

  const mappedAnomalies = anomaliesData?.map(({ date, cost, anomalyCost }) => ({
    date: dayjs(date).format(dateFormat.monthYear),
    cost,
    anomalyCost: anomalyCost ?? 0,
  }));

  const anomaliesByPeriod = mappedAnomalies?.filter((anomaly) =>
    months.find((month) =>
      dayjs(anomaly.date, dateFormat.monthYear).isSame(dayjs(month), "month")
    )
  );

  const anomaliesByMonth = groupBy(anomaliesByPeriod, "date");

  return Object.entries(anomaliesByMonth).map(([key, value]) => {
    const formattedDate = dayjs(key, dateFormat.monthYear).format(
      dateFormat.shortDate
    );

    return {
      date: formattedDate,
      ...value.reduce(
        (anomaly, { cost, anomalyCost }) => ({
          cost: anomaly.cost + cost,
          anomalyCost: anomaly.anomalyCost + anomalyCost,
        }),
        { cost: 0, anomalyCost: 0 }
      ),
    };
  });
}

function mapTimePointValue(timePoint: TimePoint) {
  return {
    ...timePoint,
    value: Number(timePoint.value),
  };
}

function mapAnomaliesAndAccumulatedCostData(
  anomaliesData: Anomaly[],
  accumulatedCostChartData: TimePointValue[]
): Anomaly[] {
  const accumulatedData =
    accumulatedCostChartData
      .map((item) => {
        if (!anomaliesData.some((anomaly) => anomaly.date === item.date)) {
          return {
            date: item.date,
            cost: item.value,
            anomaly: false,
            isAttached: true,
          };
        }
        return null;
      })
      .filter(isNotNil) ?? [];

  return [...anomaliesData, ...accumulatedData];
}
