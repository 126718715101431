/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme, FlexContainer, Button } from "@nordcloud/gnui";

export const StyledOption = styled(FlexContainer)`
  min-width: 9rem;
  max-width: 19rem;
  border-radius: ${theme.radius.md};
`;

type FilterPropTag = {
  isFilterExcluded: boolean;
};

export const FilterButton = styled(Button)<FilterPropTag>`
  background-color: ${(props: FilterPropTag) =>
    props.isFilterExcluded ? theme.color.support.redInverse : undefined};
  &:hover {
    background-color: ${(props: FilterPropTag) =>
      props.isFilterExcluded ? "#ef9a9a" : undefined};
  }
`;

export const OptionDeleteButton = styled(Button)`
  background-color: ${theme.color.background.ui01};
  padding: ${theme.spacing.spacing02};
  &:hover {
    cursor: pointer;
    background-color: ${theme.color.background.ui04};
  }
`;
