/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloError } from "@apollo/client/errors";
import { When } from "react-if";
import { Spacer, theme } from "@nordcloud/gnui";
import { BreadcrumbsBox, UniversalWrap } from "~/components";
import { useCurrency } from "~/hooks";
import { useAccess, ACTIONS } from "~/services/auth/RBAC";
import { ResourceLifecycleDates, formatCustomDate, formatMoney } from "~/tools";
import { Metadata } from "./components";
import { Ec2InstanceApplications } from "./Ec2Instance";
import { EstateRecord } from "./Ec2Instance/types";
import {
  ReservationInstanceHeader,
  ReservationTabs,
  AzureReservedInstanceDetails,
  getRIDetailsTerm,
  useReservationInstanceDetails,
  AwsSavingsPlanDetails,
  AwsReservedInstanceDetails,
  getCloudProviderFlags,
} from "./ReservationDetails";
import { ApplicationProps, Costs } from "./types";

type Props = {
  estateRecord: EstateRecord;
  loading: boolean;
  error: ApolloError | undefined;
  costs: Costs;
  nid: string;
  application: ApplicationProps;
  metadata: Metadata[];
  resourceLifecycleDates: ResourceLifecycleDates | undefined;
};

export function ReservationInstancePage({
  nid,
  estateRecord,
  loading,
  error,
  costs,
  application,
  metadata,
  resourceLifecycleDates,
}: Props) {
  const { currency } = useCurrency();
  const { resultWithMetadata, headerData, showUtilizationData } =
    useReservationInstanceDetails({
      metadata,
      resourceLifecycleDates,
      estateRecord,
      costs: { ...costs },
      nid,
    });
  const {
    reservationDetails: {
      savingsPlanType,
      displayName,
      instanceType,
      offeringClass,
      operatingSystem,
      billingPlan,
      paymentOption,
      skuName = "",
      expirationDate,
      reservationStartDate,
      commitment,
      createdDate,
      quantity,
      lastSeen,
      firstSeen,
    },
  } = resultWithMetadata;
  const { isAWSReservedInstance, isAWSSavingsPlan, isAzureReservation } =
    getCloudProviderFlags(estateRecord);

  const { hasAccess: hasUtilizationTabAccess } = useAccess(
    ACTIONS.viewUtilizationTab
  );

  const requestedDate = formatCustomDate(createdDate);
  const reservationDate = formatCustomDate(reservationStartDate ?? "");
  const endDate = formatCustomDate(expirationDate);
  const termYear = getRIDetailsTerm(reservationDate, endDate);

  const breadcrumbLabels = [
    {
      value: nid,
      label: headerData.name,
    },
  ];

  const commitmentAmount = formatMoney(commitment, currency);
  const commitmentValue = `${commitmentAmount}/hr`;
  const commonProps = {
    firstSeen,
    lastSeen,
    expirationDate: endDate,
    term: termYear,
  };

  return (
    <UniversalWrap loaderProps={{ loading }} errorProps={{ error }}>
      <BreadcrumbsBox title={headerData.name} labels={breadcrumbLabels} />
      <ReservationInstanceHeader {...headerData} />
      <Spacer height={theme.spacing.spacing04} />
      <When condition={isAzureReservation}>
        <AzureReservedInstanceDetails
          location={estateRecord.region ?? "-"}
          billingPlan={billingPlan}
          skuName={skuName}
          quantity={quantity}
          requestedDate={requestedDate}
          displayName={displayName}
          reservationDate={reservationDate}
          {...commonProps}
        />
      </When>
      <When condition={isAWSReservedInstance}>
        <AwsReservedInstanceDetails
          {...commonProps}
          instanceType={instanceType}
          purchaseOption={billingPlan}
          offeringClass={offeringClass}
          operatingSystem={operatingSystem}
          startDate={reservationDate}
          unitsCount={quantity}
          location={estateRecord.region ?? ""}
        />
      </When>
      <When condition={isAWSSavingsPlan}>
        <AwsSavingsPlanDetails
          {...commonProps}
          savingsPlanType={savingsPlanType}
          paymentOption={paymentOption}
          startDate={reservationDate}
          commitment={commitmentValue}
        />
      </When>
      <Spacer height={theme.spacing.spacing04} />
      <Ec2InstanceApplications {...application} />
      <Spacer height={theme.spacing.spacing04} />
      <ReservationTabs
        metadata={metadata}
        nid={nid}
        showUtilizationTab={hasUtilizationTabAccess && showUtilizationData}
      />
    </UniversalWrap>
  );
}
