/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { FlexContainer, SVGIcon, theme } from "@nordcloud/gnui";
import { Provider } from "~/generated/graphql";
import { IconBox } from "~/components";
import { ReservationCostBox } from "~/components/CostBox";
import { useCloudAccount, getAccountUrl } from "~/hooks";
import { Item } from "~/views/estate/EstateDetailsPage/components/Item";
import { Costs } from "~/views/estate/EstateDetailsPage/types";
import { CostsWrapper, DetailsWrapper, Line, NameWrapper } from "./styles";
import { CloudIcon } from "./types";
import { getReservationHeaderLabel } from "./utils";

type Props = {
  name: string;
  providerId: string;
  providerType: string;
  account: string;
  costs: Costs;
  cloudIcon: CloudIcon;
  color: string;
  provider: Provider;
  upfrontCost: number;
};

export function ReservationHeader({
  providerId,
  providerType,
  account,
  costs,
  name,
  cloudIcon,
  color,
  provider,
  upfrontCost,
}: Props) {
  const cloudAccount = useCloudAccount(account, Provider.Azure);
  const accountUrl = cloudAccount?.url ?? getAccountUrl(account, "");
  const hasAzureProvider = provider === Provider.Azure;
  const cloudName = hasAzureProvider ? "azure" : "aws";
  const { headerId, headerName } = getReservationHeaderLabel(provider);

  return (
    <>
      <NameWrapper>
        <FlexContainer columnGap={theme.spacing.spacing04}>
          <When condition={hasAzureProvider}>
            <IconBox
              icon={<SVGIcon name={cloudIcon} size="lg" color={color} />}
            />
          </When>
          <IconBox icon={<SVGIcon name={cloudName} size="lg" />} />
          <Item value={name} label="Resource Name" />
        </FlexContainer>
      </NameWrapper>
      <Line />
      <DetailsWrapper>
        <FlexContainer
          direction="column"
          alignItems="flex-start"
          rowGap={theme.spacing.spacing01}
        >
          <Item value={providerId} label={headerId} />
          <Item value={providerType} label={headerName} />
          <Item
            value={cloudAccount?.urlLabel ?? account}
            label="Account"
            url={accountUrl}
          />
        </FlexContainer>
      </DetailsWrapper>
      <CostsWrapper>
        <ReservationCostBox
          monthlyCost={costs.currentMonth}
          currency={costs.currency}
          upfrontCost={upfrontCost}
        />
      </CostsWrapper>
    </>
  );
}
