/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import dayjs from "dayjs";
import { ChartTableSortField, SearchV2SortField } from "~/generated/graphql";
import { dateFormat, EstatePeriod } from "~/constants";
import { useQueryState } from "~/hooks";
import { BILLING_PERIOD, getPeriod } from "~/services/customers";
import { isNil, isNotNil } from "~/tools";
import { EstateChartsQueryState, EstateGroupBy } from "../Charts/types";

export function useQueryParametersValidation() {
  const {
    state: {
      field,
      periodStart,
      periodEnd,
      groupBy = EstateGroupBy.NONE,
      estatePeriod = EstatePeriod.DEFAULT_MONTH,
    },
    updateQueryState,
  } = useQueryState<EstateChartsQueryState>();
  const period = getPeriod()?.period || "";
  const isPreviousBillingPeriod = period === BILLING_PERIOD.previous;

  const isActiveChartValid = Object.values(EstateGroupBy).includes(groupBy);

  const activeChart = isActiveChartValid ? groupBy : EstateGroupBy.NONE;

  const isAccumulatedChart = activeChart === EstateGroupBy.NONE;

  const isAcucumulatedSortValid = Object.values(SearchV2SortField).includes(
    field as SearchV2SortField
  );

  const isOtherSortIsValid = Object.values(ChartTableSortField).includes(
    field as ChartTableSortField
  );

  if (!isActiveChartValid) {
    updateQueryState({
      groupBy: EstateGroupBy.NONE,
    });
  }

  if (isNotNil(field) && isAccumulatedChart && !isAcucumulatedSortValid) {
    updateQueryState({
      field: SearchV2SortField.Cost,
    });
  }

  if (isNotNil(field) && !isAccumulatedChart && !isOtherSortIsValid) {
    updateQueryState({
      field: ChartTableSortField.Cost,
    });
  }

  const preSelectedMonth = useMemo(() => {
    const monthStart = getMonthStart(isPreviousBillingPeriod, estatePeriod);
    const monthEnd = getMonthEndDates(isPreviousBillingPeriod, estatePeriod);

    return {
      from: validateDate(estatePeriod, monthStart, periodStart),
      to: validateDate(estatePeriod, monthEnd, periodEnd),
    };
  }, [isPreviousBillingPeriod, periodStart, periodEnd, estatePeriod]);

  return {
    activeChart,
    preSelectedMonth,
  };
}

function getMonthStart(
  isPreviousBillingPeriod: boolean,
  estatePeriod: EstatePeriod
) {
  const defaultMonthStart = isPreviousBillingPeriod
    ? dayjs().subtract(1, "month").startOf("month").format(dateFormat.shortDate)
    : dayjs().startOf("month").format(dateFormat.shortDate);

  const previousMonthStart = dayjs(defaultMonthStart)
    .subtract(1, "month")
    .startOf("month")
    .format(dateFormat.shortDate);

  return estatePeriod === EstatePeriod.DEFAULT_MONTH
    ? defaultMonthStart
    : previousMonthStart;
}

function getMonthEndDates(
  isPreviousBillingPeriod: boolean,
  estatePeriod: EstatePeriod
) {
  const defaultMonthEnd = isPreviousBillingPeriod
    ? dayjs().subtract(1, "month").endOf("month").format(dateFormat.shortDate)
    : dayjs().endOf("month").format(dateFormat.shortDate);

  const previousMonthdEnd = dayjs(defaultMonthEnd)
    .subtract(1, "month")
    .endOf("month")
    .format(dateFormat.shortDate);

  return estatePeriod === EstatePeriod.DEFAULT_MONTH
    ? defaultMonthEnd
    : previousMonthdEnd;
}

function getValidDate(date: string, queryStateDate: string) {
  const isDateValid = dayjs(queryStateDate).isSame(date);

  return isDateValid ? queryStateDate : date;
}

function validateDate(
  estatePeriod: EstatePeriod,
  date: string,
  queryStateDate: string | undefined
) {
  if (isNil(queryStateDate)) {
    return date;
  }

  if (estatePeriod !== EstatePeriod.RANGE) {
    return getValidDate(date, queryStateDate);
  }

  return queryStateDate;
}
