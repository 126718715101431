/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { CloudWasteOption, PolicyOption } from "../types";
import {
  generateSelectedCategories,
  generateSelectedPolicies,
  filterCategoriesByName,
  filterUniquePolicies,
  findByCategory,
  areAllPoliciesSelected,
  findById,
  hasPolicies,
  getPoliciesIds,
  generateSelectedCategoriesFromPolicies,
  isAllPoliciesSelected,
} from "./utils";

type Props = {
  categories: CloudWasteOption[];
  isCleared: boolean;
  search: string;
  selectedOptions: string[];
  onMultiSelect: (ids: string[]) => void;
};

export function useSavingSuggestionsSelector({
  categories,
  isCleared,
  search,
  selectedOptions,
  onMultiSelect,
}: Props) {
  const [filteredCategories, setFilteredCategories] =
    useState<CloudWasteOption[]>(categories);

  const [selectedCategories, setSelectedCategories] = useState<
    CloudWasteOption[]
  >(generateSelectedCategories(selectedOptions, categories));

  const [selectedPolicies, setSelectedPolicies] = useState<PolicyOption[]>(
    generateSelectedPolicies(selectedOptions)
  );

  useEffect(() => {
    setFilteredCategories(filterCategoriesByName(search, categories));
  }, [search, categories]);

  useEffect(() => {
    onMultiSelect(selectedPolicies.flatMap(({ id }) => id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategories, selectedPolicies]);

  useEffect(() => {
    if (isCleared) {
      setSelectedCategories([]);
      setSelectedPolicies([]);
    }
  }, [isCleared]);

  function findParentCategory(policy: PolicyOption) {
    return categories?.find((category) =>
      category.policies.find(({ id }) => id === policy.id)
    );
  }

  function removeCatFromSelectedCategories(categoryToRemove: string) {
    setSelectedCategories((prevState) =>
      prevState.filter(({ category }) => category !== categoryToRemove)
    );
  }

  function addCatToSelectedCategories(category: CloudWasteOption) {
    setSelectedCategories((prevState) => [...prevState, category]);
  }

  function addPolicyToSelectedPolicies(policy: PolicyOption) {
    setSelectedPolicies((prevState) => [...prevState, policy]);
  }

  function removePolicyFromSelectedPolicies(policyId: string) {
    setSelectedPolicies((prevState) =>
      prevState.filter(({ id }) => id !== policyId)
    );
  }

  function removeChildrenPoliciesFromSelectedCat(category: CloudWasteOption) {
    setSelectedPolicies((prevState) => {
      return prevState.filter((prevPol) =>
        category.policies.every(
          (parentPol) => parentPol && parentPol.id !== prevPol.id
        )
      );
    });
  }

  function addChildrenPoliciesToSelectedCategories(
    parentCategory: CloudWasteOption,
    policy?: PolicyOption
  ) {
    // Toggle-on other policies belonging to parent category if policy is provided
    // Toggle-on all policies belonging to parent if policy is not provided
    const selectedPoliciesIds = getPoliciesIds(parentCategory, policy);
    setSelectedPolicies((prevState) =>
      filterUniquePolicies([
        ...prevState,
        ...generateSelectedPolicies(selectedPoliciesIds),
      ])
    );
  }

  const selectCategory = (categoryId: string) => {
    // When category is toggled on/off, remove all of its policies from selected policies
    const category = findByCategory(filteredCategories, categoryId);

    if (category) {
      if (findByCategory(selectedCategories, categoryId)) {
        deselectCategoryAndPolicies(categoryId, category);
      } else {
        selectCategoryAndPolicies(categoryId, category);
      }
    }
  };

  const selectPolicy = (policy: PolicyOption) => {
    const parentCategory = findParentCategory(policy);
    const isParentCategorySelected =
      parentCategory?.category &&
      findByCategory(selectedCategories, parentCategory.category);

    const isPolicySelected = findById(selectedPolicies, policy.id);

    if (isPolicySelected || isParentCategorySelected) {
      if (parentCategory && isParentCategorySelected) {
        removeCatFromSelectedCategories(parentCategory?.category ?? "");
      }
      removePolicyFromSelectedPolicies(policy.id);
    } else if (
      parentCategory &&
      areAllPoliciesSelected(selectedPolicies, parentCategory, policy)
    ) {
      addCatToSelectedCategories(parentCategory);
      addPolicyToSelectedPolicies(policy);
    } else {
      addPolicyToSelectedPolicies(policy);
    }
  };

  const isAllSelected = isAllPoliciesSelected(
    filteredCategories,
    selectedPolicies
  );

  function selectAll() {
    if (isAllSelected) {
      const policiesToLeave = selectedPolicies.filter(
        (selectedPolicy) =>
          filteredCategories
            .flatMap(({ policies }) => policies)
            .findIndex((policy) => selectedPolicy.id === policy.id) === -1
      );

      setSelectedPolicies(policiesToLeave);
      setSelectedCategories(
        generateSelectedCategories(
          policiesToLeave.flatMap((policy) => policy.id),
          categories
        )
      );
    } else {
      const policiesToAdd = filterUniquePolicies([
        ...selectedPolicies,
        ...filteredCategories.flatMap(({ policies }) => policies),
      ]);

      setSelectedPolicies(policiesToAdd);
      setSelectedCategories(
        generateSelectedCategoriesFromPolicies(policiesToAdd, categories)
      );
    }
  }

  function deselectCategoryAndPolicies(
    categoryId: string,
    category: CloudWasteOption
  ) {
    removeCatFromSelectedCategories(categoryId);

    if (hasPolicies(category.policies)) {
      removeChildrenPoliciesFromSelectedCat(category);
    }
  }

  function selectCategoryAndPolicies(
    categoryId: string,
    category: CloudWasteOption
  ) {
    const parentCategory = findByCategory(categories, categoryId);
    const isCategoryAndPoliciesSelected =
      parentCategory &&
      areAllPoliciesSelected(
        [...selectedPolicies, ...category.policies],
        parentCategory
      );

    if (isCategoryAndPoliciesSelected) {
      addCatToSelectedCategories(category);
    }

    if (hasPolicies(category.policies)) {
      addChildrenPoliciesToSelectedCategories(category);
    }
  }

  return {
    filteredCategories,
    selectCategory,
    selectPolicy,
    selectedCategories,
    selectedPolicies,
    selectAll,
    isAllSelected,
  };
}
