/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { ChartGroupTimePoint } from "~/generated/graphql";
import { ChartType } from "~/components";
import { mapSwitcherGroupLabel } from "~/components/Charts/CostAnalysis/helpers";
import { isNotNil } from "~/tools";
import { ExtraChartDetails } from "./types";

export function getChartGroups(
  timePoints: ChartGroupTimePoint[],
  chartType: ChartType
) {
  return timePoints
    .flatMap(
      (timePoint) =>
        timePoint.groups?.flatMap((group) =>
          group?.name === "other"
            ? mapSwitcherGroupLabel(chartType)
            : group?.name ?? ""
        )
    )
    .filter(isNotNil);
}

// 'other' & 'savings plans commitments' groups should be the last
export function reorderGroups(groups: string[]) {
  const otherGroups = groups.filter(
    (group) => group.includes("other") || group.includes("commitments")
  );

  if (otherGroups.length > 0) {
    return [
      ...groups.filter((group) => !otherGroups.includes(group)),
      ...otherGroups,
    ];
  }

  return groups;
}

export function getExtraCostDetails(extraDetails: ExtraChartDetails) {
  const totalTop =
    extraDetails.find((detail) => detail?.name === "TotalTop")?.value ?? "0";
  const totalOther =
    extraDetails.find((detail) => detail?.name === "TotalOther")?.value ?? "0";

  return {
    totalTop,
    totalOther,
  };
}

export function getAverageCost(
  totalCost: number,
  startDate: string,
  endDate: string
) {
  const totalMonths =
    dayjs(endDate)
      .endOf("month")
      .diff(dayjs(startDate).startOf("month"), "month") + 1;

  return (totalCost / totalMonths).toFixed(2);
}
