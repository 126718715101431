/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Text } from "@nordcloud/gnui";
import { FilterHeader, FilterWrapper, getSelectedMode } from "~/components";
import { isEmpty, noop } from "~/tools";
import { isArrayFilterSelected } from "~/utils";
import { isDoubleFilterSelected, isExcluded } from "../helpers";
import { useDataOptions, useFilters } from "../hooks";
import { CARBON_FOOTPRINT_SUGGESTION, CloudWasteOption } from "../types";
import { CostInput } from "./CostInput";
import { SavingSuggestionAccordion } from "./SavingSuggestionAccordion";
import { SidebarFilterProps } from "./types";

export function CostAndSavingsFilters({
  filters,
  updateFilters,
}: SidebarFilterProps) {
  const { clearCostFilter, clearWastePolicyFilter } = useFilters({
    filters,
    updateFilters,
  });

  const { cloudWastePoliciesCategorised } = useDataOptions(filters);

  const carbonFootprintOption: CloudWasteOption = {
    category: CARBON_FOOTPRINT_SUGGESTION.nid,
    categoryName: CARBON_FOOTPRINT_SUGGESTION.name,
    isInextensible: true,
    policies: [
      {
        id: CARBON_FOOTPRINT_SUGGESTION.nid,
        name: CARBON_FOOTPRINT_SUGGESTION.name,
        isHidden: true,
      },
    ],
  };

  return (
    <>
      <Text weight="medium">Costs and Savings</Text>
      <FilterWrapper
        name="Monthly Cost"
        isSelected={isDoubleFilterSelected(
          filters.costFrom?.toString(),
          filters.costTo?.toString()
        )}
        onClear={clearCostFilter}
      >
        <FilterHeader
          allowModeChange
          defaultMode={getSelectedMode(filters.costExclude)}
          onSetSearch={noop}
          onModeChange={(mode) => {
            updateFilters({
              costExclude: isExcluded(mode),
            });
          }}
        />
        <CostInput filters={filters} updateFilters={updateFilters} size="m" />
      </FilterWrapper>
      <FilterWrapper
        name="Estate Records with Savings Suggestions"
        isSelected={isArrayFilterSelected(filters.wastePolicy)}
        selectedOptions={filters.wastePolicy ?? []}
        onClear={clearWastePolicyFilter}
      >
        <SavingSuggestionAccordion
          selectedOptions={filters.wastePolicy ?? []}
          categories={[...cloudWastePoliciesCategorised, carbonFootprintOption]}
          isCleared={isEmpty(filters.wastePolicy ?? [])}
          isExcludeSelected={filters.wastePolicyExclude}
          onMultiSelect={(wastePolicy) => updateFilters({ wastePolicy })}
          onModeChange={(mode) =>
            updateFilters({ wastePolicyExclude: isExcluded(mode) })
          }
        />
      </FilterWrapper>
    </>
  );
}
