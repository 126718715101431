/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { CollapsibleBox } from "~/components";
import { Item } from "../../components";
import { InstanceGridWrapper } from "../styles";
import { InstanceDetails } from "./InstanceDetails";

type Props = {
  savingsPlanType: string;
  commitment: string;
  paymentOption: string;
  expirationDate: string;
  startDate: string;
  term: string;
  firstSeen: string | undefined;
  lastSeen: string | undefined;
};

export function AwsSavingsPlanDetails({
  savingsPlanType,
  commitment,
  paymentOption,
  startDate,
  term,
  expirationDate,
  firstSeen,
  lastSeen,
}: Props) {
  return (
    <CollapsibleBox title="SavingsPlan Details">
      <InstanceGridWrapper>
        <Item label="SavingsPlan Type" value={savingsPlanType} />
        <Item label="Commitment/hr" value={commitment} />
        <InstanceDetails
          paymentOption={paymentOption}
          startDate={startDate}
          expirationDate={expirationDate}
          firstSeen={firstSeen}
          lastSeen={lastSeen}
          term={term}
        />
      </InstanceGridWrapper>
    </CollapsibleBox>
  );
}
