/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useRef, useState, useEffect } from "react";
import { When } from "react-if";
import {
  Description,
  FlexContainer,
  Navigation,
  Sidebar,
  SVGIcon,
  Text,
  theme,
} from "@nordcloud/gnui";
import { BusinessContext } from "~/generated/graphql";
import { useApplications } from "~/views/applications/ApplicationsPage/components/ApplicationsProvider";
import { Indicator, Container } from "../styled";
import { OrganizationUnitsList } from "./OrganizationUnitsList";

type Props = {
  isOpen: boolean;
  close: () => void;
  environmentId: string;
};

export function AttachOrganizationUnit({
  isOpen,
  close,
  environmentId,
}: Props) {
  const scrollToRef = useRef<HTMLDivElement>(null);
  const { businessContexts, primaryBusinessContextId, refetchEnvironments } =
    useApplications();
  const [businessContextId, setBusinessContextId] = useState<string>(
    primaryBusinessContextId ?? ""
  );

  useEffect(() => {
    setBusinessContextId(primaryBusinessContextId ?? "");
  }, [primaryBusinessContextId]);

  const onClose = () => {
    setBusinessContextId(primaryBusinessContextId ?? "");
    close();
    refetchEnvironments();
  };

  return (
    <Sidebar
      title="Attach Environment to the Organizational Unit"
      isOpen={isOpen}
      width="52rem"
      onClick={onClose}
    >
      <FlexContainer ref={scrollToRef} alignItems="flex-start">
        <Container borderRight>
          <Text>Business Context</Text>
          {[...(businessContexts ?? [])]
            .sort(sortBoolean)
            .map((businessContext) => {
              const isActive = businessContextId === businessContext.id;
              return (
                <FlexContainer key={businessContext.id} alignItems="center">
                  <Navigation.Item
                    css={{
                      flex: 1,
                      display: "flex",
                      cursor: "pointer",
                      alignItems: "center",
                      backgroundColor: isActive
                        ? theme.color.background.ui04
                        : "transparent",
                    }}
                    onClick={() => {
                      setBusinessContextId(businessContext?.id ?? "");
                      scrollToRef.current?.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }}
                  >
                    <Indicator
                      color={
                        businessContext.color ?? theme.color.support.purple
                      }
                    />
                    {businessContext.name}
                    <When condition={businessContext.isPrimary}>
                      <SVGIcon
                        name="starFilled"
                        size="sm"
                        css={{ marginLeft: theme.spacing.spacing02 }}
                      />
                    </When>
                  </Navigation.Item>
                </FlexContainer>
              );
            })}
        </Container>
        <Container>
          <Text>List of Organizational Units</Text>
          <Description>
            The environment can only be connected to a singular organizational
            unit within the selected business context.
          </Description>
          <OrganizationUnitsList
            environmentId={environmentId}
            businessContextId={businessContextId}
          />
        </Container>
      </FlexContainer>
    </Sidebar>
  );
}

const sortBoolean = (
  a: Partial<BusinessContext>,
  b: Partial<BusinessContext>
) => {
  if (a.isPrimary === b.isPrimary) {
    return 0;
  } else if (a.isPrimary) {
    return -1;
  } else {
    return 1;
  }
};
