/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { noop } from "@tanstack/react-table";
import { When } from "react-if";
import { Checkbox, Text } from "@nordcloud/gnui";
import { SidebarItemWrapper } from "~/components";
import { SwitchWrap } from "../AppEnvSelector/styles";
import { StyledFlexContainer } from "../styles";
import { CloudWasteOption, PolicyOption } from "../types";

type Props = {
  category: CloudWasteOption;
  selectedPolicies: PolicyOption[];
  isCategoryChecked: boolean;
  onPolicyCheck: (policy: PolicyOption) => void;
};

export function PoliciesList({
  category,
  selectedPolicies,
  isCategoryChecked,
  onPolicyCheck,
}: Props) {
  return (
    <>
      {category.policies.map((policy) => (
        <PolicyRow
          key={policy.id}
          policy={policy}
          selectedPolicies={selectedPolicies}
          isCategoryChecked={isCategoryChecked}
          onPolicyCheck={onPolicyCheck}
        />
      ))}
    </>
  );
}

type PolicyRowProps = {
  policy: PolicyOption;
  selectedPolicies: PolicyOption[];
  isCategoryChecked: boolean;
  onPolicyCheck: (policy: PolicyOption) => void;
};

function PolicyRow({
  policy,
  selectedPolicies,
  isCategoryChecked,
  onPolicyCheck,
}: PolicyRowProps) {
  const { id, name, isHidden } = policy;
  const isPolicyChecked =
    isCategoryChecked ||
    selectedPolicies.some(({ id: policyId }) => policyId === id);

  return (
    <When condition={!isHidden}>
      <StyledFlexContainer key={id}>
        <SidebarItemWrapper
          hideBottomBorder
          title={name}
          data-testid="sidebar-policy-item"
          onClick={noop}
        >
          <SwitchWrap>
            <Checkbox
              withoutLabel
              data-testid="policy-checkbox"
              checked={isPolicyChecked}
              onChange={() => onPolicyCheck(policy)}
            />
          </SwitchWrap>
          <Text mb="0" css={{ textAlign: "start" }}>
            {name}
          </Text>
        </SidebarItemWrapper>
      </StyledFlexContainer>
    </When>
  );
}
