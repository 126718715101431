/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { isNotEmpty } from "~/tools";
import { DateRange } from "../../Charts/CostAnalysis/types";

type Props = {
  startDate: string;
  endDate: string;
  range?: DateRange;
};

export function useDateRangeSelector({ startDate, endDate, range }: Props) {
  const areDatesNotDefined = isRangeInvalid(startDate, endDate, range);

  const areDatesInRange = validateDateRange(startDate ?? "", endDate ?? "");
  const isEndDateInRange = isEndDateValid(endDate ?? "", startDate ?? "");

  const isError = !isEndDateInRange || !areDatesInRange;

  return {
    isApplyDisabled: areDatesNotDefined || isError,
    isError,
    errorMessage: isError ? getErrorMessage(areDatesInRange) : "",
  };
}

function isRangeSet(from?: string, to?: string) {
  return from !== undefined && to !== undefined;
}

function isRangeInvalid(from: string, to: string, range?: DateRange) {
  if (range) {
    return range.from === from && range?.to === to;
  }

  return isNotEmpty(from) && isNotEmpty(to) ? !isRangeSet(from, to) : true;
}

function validateDateRange(startDate: string, endDate: string) {
  if (isNotEmpty(startDate) && isNotEmpty(endDate)) {
    return (
      isDateValid(startDate ?? "") &&
      isDateValid(endDate ?? "") &&
      isLessThan5YearDiff(startDate, endDate)
    );
  }

  return true;
}

function isDateValid(date: Date | string) {
  const minDate = dayjs().endOf("day").subtract(5, "year").subtract(1, "day");
  const maxDay = dayjs().endOf("day");
  return dayjs(date).startOf("day").isBetween(minDate, maxDay);
}

function isEndDateValid(endDate: string, startDate: string) {
  return isNotEmpty(endDate)
    ? isDateValid(endDate ?? "") &&
        dayjs(endDate).isAfter(dayjs(startDate).add(-1, "day"))
    : true;
}

function isLessThan5YearDiff(start?: Date | string, end?: Date | string) {
  const startDate = dayjs(start).startOf("day");
  const endDate = dayjs(end).startOf("day");

  return Math.abs(dayjs(endDate).diff(startDate, "year")) <= 5;
}

function getErrorMessage(areDatesInRange: boolean) {
  if (!areDatesInRange) {
    return "Dates selected must fall within a range spanning the past 5 years.";
  }

  return "End Date should be after Start Date";
}
