/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export enum ProvisionState {
  SUCCEEDED = "Succeeded",
  CANCELLED = "Cancelled",
  EXPIRED = "Expired",
}

export enum CloudIcon {
  CLOUD_ONLINE = "cloudOnline",
  CLOUD = "cloud",
  CLOUD_OFFLINE = "cloudOffline",
}

export type MappedMetadata = {
  displayName: string;
  originalQuantity: string;
  term: string;
  billingPlan: string;
  createdDateTime: string;
  benefitStartTime: string;
  sku: {
    name: string;
  };
  expiryDate: string;
  lastUpdateDate: string;
  provisioningState: string;
  instanceType: string;
  savingsPlanType: string;
  commitment: string;
  paymentOption: string;
  start: string;
  end: string;
  productDescription: string;
  offeringType: string;
  offeringClass: string;
  instanceCount: string;
  savingPlanUsageType: string;
  cacheNodeCount: string;
  cacheNodeType: string;
  /*
    Note: 'DBInstanceCount' is named in this format to match the naming convention used in the metadata.
    Even though it deviates from camelCase, it's intentional to maintain consistency with the metadata.
    Please do not modify the naming of this property unless there is a change in the metadata structure.
  */
  DBInstanceClass: string;
  DBInstanceCount: string;
};

export type ReservationType = { value: string; label: string };
