/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Cell, Row } from "@tanstack/react-table";
import dayjs from "dayjs";
import styled from "styled-components";
import { Button, ExtendedPopover, Text, theme } from "@nordcloud/gnui";
import { CloudAccountsQuery } from "~/generated/graphql";
import { ACTION, ExtendPopoverControls } from "~/components";
import { dateFormat } from "~/constants";
import {
  Currency,
  FormatMoney,
  getFirstItem,
  isNil,
  isNotEmpty,
  isNotNil,
} from "~/tools";
import { Service, Threshold, ThresholdGranularity } from "./types";

type Props = {
  currency: Currency;
  services: Service[];
  cloudAccounts: CloudAccountsQuery | undefined;
  onEditHandler: (threshold: Threshold) => void;
  onRemoveHandler: (id: string, name: string) => void;
};

export function ThresholdsListColumns({
  onRemoveHandler,
  onEditHandler,
  currency,
  services,
  cloudAccounts,
}: Props) {
  const popoverActions = (row: Row<Threshold>) => [
    {
      label: "Edit Threshold",
      action: ACTION.EDIT,
      onClick: () => {
        if (isNotNil(row.original)) {
          onEditHandler(row.original);
        }
      },
    },
    {
      label: "Delete Threshold",
      action: ACTION.DELETE,
      onClick: () => {
        if (isNotNil(row.original)) {
          onRemoveHandler(row.original?.id, row.original?.name);
        }
      },
    },
  ];

  return [
    {
      id: "name",
      accessorKey: "name",
      header: <div css={{ minWidth: "10rem" }}>Name</div>,
      cell: ({ row }: Cell<Threshold>) => {
        return <span>{row.original?.name}</span>;
      },
    },
    {
      id: "data",
      header: "Data",
      cell: ({ row }: Cell<Threshold>) => {
        const accountId = getFirstItem(row.original?.accounts ?? []);
        const accountName =
          cloudAccounts?.cloudAccounts?.accounts?.find(
            ({ providerId }) => providerId === accountId
          )?.name ?? "";

        const serviceId = getFirstItem(row.original?.services ?? []);
        const serviceName =
          services.find(({ id }) => serviceId === id)?.name ?? "";

        const provider = getProviderName(
          getFirstItem(row.original?.providers ?? [""]) ?? ""
        );

        const result = [accountName, serviceName, provider]
          .filter(isNotEmpty)
          .join(", ");

        return <span>{result}</span>;
      },
    },
    {
      id: "recipients",
      header: "Recipients",
      cell: ({ row }: Cell<Threshold>) => {
        const recipients = [
          ...(row.original?.userRecipients || []),
          ...(row.original?.externalRecipients || []),
        ];

        if (recipients.length === 1) {
          return getFirstItem(recipients);
        }

        return (
          <ExtendedPopover
            placement="top"
            content={
              <RecipientsWrapper>
                <Text weight="medium">Recipients</Text>
                {recipients.map((recipient) => (
                  <Text key={recipient} mb={theme.spacing.spacing01}>
                    {recipient}
                  </Text>
                ))}
              </RecipientsWrapper>
            }
            trigger={
              <span
                css={{
                  textDecoration: "underline dashed 1px",
                  textUnderlineOffset: theme.spacing.spacing01,
                }}
              >
                {`${recipients.length} Recipients`}
              </span>
            }
          />
        );
      },
    },
    {
      id: "amountGranularity",
      header: "Amount / Granularity",
      cell: ({ row }: Cell<Threshold>) => {
        const amount = row.original?.thresholdAmount;
        const granularity = formatGranularity(row.original?.granularity);

        if (isNil(amount) || isNil(granularity)) {
          return "-";
        }

        return (
          <span>
            <Text weight="medium" tag="span">
              <FormatMoney value={amount} currency={currency} />
            </Text>{" "}
            / {granularity}
          </span>
        );
      },
    },
    {
      id: "createdDate",
      accessorKey: "createdDate",
      header: "Creation Date",
      cell: ({ row }: Cell<Threshold>) => {
        return (
          <span>
            {dayjs(row.original?.createdDate).format(dateFormat.fullDateShort)}
          </span>
        );
      },
    },
    {
      id: "actions",
      header: "",
      meta: { width: "2rem" },
      cell: ({ row }: Cell<Threshold>) => {
        return (
          <ExtendPopoverControls
            items={popoverActions(row)}
            trigger={<Button severity="low" icon="menu" size="md" />}
          />
        );
      },
    },
  ];
}

const RecipientsWrapper = styled.div`
  background-color: ${theme.color.background.ui01};
  border-radius: ${theme.radiusDefault};
  border: solid 1px ${theme.color.background.ui04};
  box-shadow: rgb(31 30 47 / 10%) 12px 0 22px;
  padding: ${theme.spacing.spacing02} ${theme.spacing.spacing04};

  display: flex;
  flex-direction: column;
`;

function formatGranularity(granularity: ThresholdGranularity | undefined) {
  if (granularity === ThresholdGranularity.Daily) {
    return "Daily";
  }
  if (granularity === ThresholdGranularity.Monthly) {
    return "Monthly";
  }
}

function getProviderName(name: string) {
  switch (name.toUpperCase()) {
    case "AWS":
      return "AWS";
    case "AZURE":
      return "Azure";
    case "GCP":
      return "Google Cloud";
    default:
      return name;
  }
}
