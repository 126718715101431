/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloError } from "@apollo/client";
import { isNotNil } from "~/tools";
import { useAwsBillingData } from "../../BillingData/aws/hooks/useAwsBillingData";
import { useAzureBillingData } from "../../BillingData/azure/hooks/useAzureBillingData";
import { useGcpBillingData } from "../../BillingData/gcp/hooks/useGcpBillingData";
import { ProviderTab } from "../../types";

export function useBillingDataStatus(provider: ProviderTab) {
  const [awsBillingData, { error: awsError, loading: isAwsLoading }] =
    useAwsBillingData();
  const [azureBillingData, { error: azureError, loading: isAzureLoading }] =
    useAzureBillingData();
  const [gcpBillingData, { error: gcpError, loading: isGcpLoading }] =
    useGcpBillingData();

  switch (provider) {
    case ProviderTab.AWS:
      return getBillingDataStatuses(isAwsLoading, awsError, awsBillingData);

    case ProviderTab.AZURE:
      return getBillingDataStatuses(
        isAzureLoading,
        azureError,
        azureBillingData
      );

    case ProviderTab.GCP:
      return getBillingDataStatuses(isGcpLoading, gcpError, gcpBillingData);

    default:
      return defaultBillingDataStatuses;
  }
}

function getBillingDataStatuses(
  loading: boolean,
  error: ApolloError | undefined,
  data: BillingDataStatuses[]
) {
  if (loading === true || isNotNil(error)) {
    return defaultBillingDataStatuses;
  }

  return {
    isBillingDataOnboarding: data.some((item) => item.isOnboarding),
    isBillingDataInactive: data.some((item) => item.isActive === false),
  };
}

type BillingDataStatuses = {
  isOnboarding: boolean;
  isActive: boolean;
};

const defaultBillingDataStatuses = {
  isBillingDataOnboarding: false,
  isBillingDataInactive: false,
};
