/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { FlexContainer, theme, Button, Text } from "@nordcloud/gnui";

type Props = {
  allowSavingFilters: boolean;
  onClear?: () => void;
  onSave?: () => void;
};

export function ActionButtons({ allowSavingFilters, onClear, onSave }: Props) {
  return (
    <FlexContainer
      gap={theme.spacing.spacing04}
      css={{
        borderLeft: `1px solid ${theme.color.border.border01}`,
        paddingLeft: theme.spacing.spacing03,
      }}
    >
      <Button icon="close" size="md" severity="low" onClick={onClear}>
        <Text size="sm" mr={theme.spacing.spacing02} mb={0}>
          Clear Filters
        </Text>
      </Button>
      <When condition={allowSavingFilters}>
        <Button icon="save" size="md" severity="low" onClick={onSave}>
          <Text size="sm" mb={0}>
            Save Filters
          </Text>
        </Button>
      </When>
    </FlexContainer>
  );
}
