/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { When } from "react-if";
import { FlexContainer, Select, Text, theme } from "@nordcloud/gnui";
import { Role } from "~/generated/graphql";
import { useCustomers } from "~/services/customers";
import { isNotEmpty } from "~/tools";
import { useEstateFilters } from "../hooks";
import { BusinessContextSelector } from "./BusinessContextSelector";
import { TagKeySelector } from "./TagKeySelector";
import {
  EstateGroupBy,
  estateGroupByOptions,
  estateGroupByLiteOptions,
  estateGroupByRestrictedOptions,
  BusinessContext,
} from "./types";

type Props = {
  activeChart: EstateGroupBy;
  disabled?: boolean;
  handleGroupByChange: (
    groupBy: EstateGroupBy,
    groupByTagKey?: string,
    groupByBusinessContext?: string
  ) => void;
  periodStart: string;
  periodEnd: string;
};

export function GroupingSelector({
  activeChart,
  disabled = false,
  handleGroupByChange,
  periodStart,
  periodEnd,
}: Props) {
  const { groupByTagKey, groupByBusinessContext } = useEstateFilters();
  const { userRole } = useCustomers();
  const [tagKey, setTagKey] = useState(groupByTagKey ?? "");
  const [businessContext, setBusinessContext] = useState<BusinessContext>({
    name: "",
    nid: groupByBusinessContext ?? "",
  });

  useEffect(() => {
    if (isNotEmpty(tagKey) && activeChart === EstateGroupBy.TAG) {
      handleGroupByChange(activeChart, tagKey, undefined);
    }
    if (isNotEmpty(businessContext.nid) && activeChart === EstateGroupBy.ORG) {
      handleGroupByChange(activeChart, undefined, businessContext?.nid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagKey, businessContext]);

  const onGroupByChange = (value: EstateGroupBy | undefined) => {
    const tagKeyValue =
      value === EstateGroupBy.TAG && isNotEmpty(tagKey) ? tagKey : undefined;
    const businessContextValue =
      value === EstateGroupBy.ORG && isNotEmpty(businessContext?.nid)
        ? businessContext.nid
        : undefined;

    handleGroupByChange(
      value ?? EstateGroupBy.NONE,
      tagKeyValue,
      businessContextValue
    );
  };

  return (
    <FlexContainer marginRight={theme.spacing.spacing04}>
      <Text
        size="sm"
        mb="0"
        mr={theme.spacing.spacing04}
        weight="medium"
        color={theme.color.text.text02}
      >
        Group by:
      </Text>
      <Select
        name="GroupBy"
        options={getGroupByOptions(userRole)}
        isDisabled={disabled}
        maxMenuHeight={300}
        isSearchable={false}
        value={{
          label: activeChart,
          value: activeChart,
        }}
        styles={{
          control: (base) => ({
            ...base,
            minWidth: "13rem",
          }),
        }}
        onChange={(value) => onGroupByChange(value?.value)}
      />
      <When condition={activeChart === EstateGroupBy.TAG}>
        <TagKeySelector
          activeChart={activeChart}
          tagKey={tagKey}
          selectTagKey={setTagKey}
          periodStart={periodStart}
          periodEnd={periodEnd}
          isDisabled={disabled}
        />
      </When>
      <When condition={activeChart === EstateGroupBy.ORG}>
        <BusinessContextSelector
          activeChart={activeChart}
          businessContext={businessContext}
          selectBusinessContext={setBusinessContext}
          isDisabled={disabled}
        />
      </When>
    </FlexContainer>
  );
}

function getGroupByOptions(userRole: Role) {
  switch (userRole) {
    case Role.AdminLite:
      return estateGroupByLiteOptions;
    case Role.TeamLead:
      return estateGroupByRestrictedOptions;
    default:
      return estateGroupByOptions;
  }
}
