/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { useEffect, useState } from "react";
import { Row, Col } from "react-awesome-styled-grid";
import { Controller, useFormContext, UseFormTrigger } from "react-hook-form";
import { When } from "react-if";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  FlexContainer,
  Input,
  Label,
  Spacer,
  Switch,
  theme,
} from "@nordcloud/gnui";
import { ContactsInput, FormGroup, FormWizardFooter } from "~/components";
import { ERROR_TEXT, SUCCESS_TEXT } from "~/constants";
import { showError, showSuccess } from "~/services/toast";
import { isNonEmpty, isNotEmpty } from "~/tools";
import { WorkflowFormData } from "../types";
import { WorkflowFields } from "./constants";

type Props = {
  onNextButtonClick: () => void;
};

export function WorkflowDetailsForm({ onNextButtonClick }: Props) {
  const { trigger, getValues, setValue } = useFormContext<WorkflowFormData>();

  const [contactPersonSlots, setContactPersonSlots] = useState(
    getValues("contactPersonIds").map((contactPersonId) => ({
      contactPersonId: contactPersonId.value,
      slotId: uuidv4(),
    }))
  );

  const handleNextButtonClick = async () => {
    const cleanContactPersonSlots = getValues("contactPersonIds").filter(
      (contactPersonId) => isNotEmpty(contactPersonId.value)
    );

    if (isNonEmpty(cleanContactPersonSlots)) {
      setValue("contactPersonIds", cleanContactPersonSlots);
    }

    const isValid = await validateWorkflowDetails(trigger);

    if (isValid) {
      onNextButtonClick();
    }
  };

  return (
    <>
      <Row justify="center">
        <Col sm={6} md={6} lg={8}>
          <Spacer height={theme.spacing.spacing07} />
          <WorkflowDetails
            contactPersonSlots={contactPersonSlots}
            setContactPersonSlots={setContactPersonSlots}
          />
          <Spacer height={theme.spacing.spacing07} />
        </Col>
      </Row>
      <FormWizardFooter
        nextButtonProps={{
          onClick: handleNextButtonClick,
          type: "button",
        }}
      />
    </>
  );
}

export function WorkflowDetails({
  contactPersonSlots,
  setContactPersonSlots,
}: WorkflowDetailsProps) {
  const {
    control,
    register,
    trigger,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<WorkflowFormData>();

  useEffect(() => {
    const update = contactPersonSlots.map(({ contactPersonId }) => ({
      value: contactPersonId,
    }));

    setValue("contactPersonIds", update);
    clearErrors("contactPersonIds");
  }, [contactPersonSlots, clearErrors, setValue]);

  return (
    <>
      <FormGroup error={errors[WorkflowFields.NAME]}>
        <Label required name="Workflow Name" htmlFor={WorkflowFields.NAME} />
        <Input
          id={WorkflowFields.NAME}
          placeholder="Workflow Name"
          {...register(WorkflowFields.NAME)}
        />
      </FormGroup>
      <FormGroup error={errors[WorkflowFields.DESCRIPTION]}>
        <Label
          name="Workflow Description"
          htmlFor={WorkflowFields.DESCRIPTION}
        />
        <Input
          id={WorkflowFields.DESCRIPTION}
          placeholder="Workflow Description"
          {...register(WorkflowFields.DESCRIPTION)}
        />
      </FormGroup>
      <FormGroup error={errors[WorkflowFields.CONTACT_PERSON_IDS]}>
        <Label
          required
          name="Contact Person"
          htmlFor={WorkflowFields.CONTACT_PERSON_IDS}
        />
        <>
          {contactPersonSlots.map((currentSlot, index) => (
            <ContactPersonFieldArrayItem
              key={currentSlot.slotId}
              slotValue={currentSlot.contactPersonId}
              index={index}
              displayRemoveButton={contactPersonSlots.length > 1}
              hasError={Boolean(errors[WorkflowFields.CONTACT_PERSON_IDS])}
              onRemoveButtonClick={() => {
                setContactPersonSlots(
                  contactPersonSlots.filter(
                    (contactPersonSlot) =>
                      contactPersonSlot.slotId !== currentSlot.slotId
                  )
                );
                validateWorkflowDetails(trigger);
              }}
              onAddButtonClick={(id) => {
                const newSlots = contactPersonSlots
                  .map((slot, index_) =>
                    index_ === index ? { ...slot, contactPersonId: id } : slot
                  )
                  .filter((slot) => isNotEmpty(slot.contactPersonId));
                setContactPersonSlots(newSlots);
              }}
            />
          ))}
        </>

        <Button
          type="button"
          severity="low"
          onClick={() =>
            setContactPersonSlots([
              ...contactPersonSlots,
              { contactPersonId: "", slotId: uuidv4() },
            ])
          }
        >
          Add Contact Person
        </Button>
        <Spacer height={theme.spacing.spacing01} />
      </FormGroup>
      <FormGroup>
        <Controller
          control={control}
          name={WorkflowFields.ACTIVE}
          render={({ field: { onChange, value: isWorkflowActive } }) => (
            <FlexContainer>
              <Switch
                checked={isWorkflowActive}
                id={WorkflowFields.ACTIVE}
                onChange={onChange}
              />
              <span css={{ paddingLeft: theme.spacing.spacing02 }}>
                The workflow is {isWorkflowActive ? "active" : "inactive"}
              </span>
            </FlexContainer>
          )}
        />
      </FormGroup>
    </>
  );
}

const validateWorkflowDetails = (trigger: UseFormTrigger<WorkflowFormData>) =>
  trigger([
    WorkflowFields.NAME,
    WorkflowFields.DESCRIPTION,
    WorkflowFields.CONTACT_PERSON_IDS,
  ]);

type ContactPersonFieldArrayItemProps = {
  slotValue: string;
  index: number;
  hasError: boolean;
  displayRemoveButton: boolean;
  onRemoveButtonClick: () => void;
  onAddButtonClick: (id: string) => void;
};

function ContactPersonFieldArrayItem({
  slotValue,
  index,
  hasError,
  displayRemoveButton,
  onRemoveButtonClick,
  onAddButtonClick,
}: ContactPersonFieldArrayItemProps) {
  const handleChange = (id: string, onChange: (id: string) => void) => {
    try {
      showSuccess(SUCCESS_TEXT.contactPersonAdded);
      onChange(id);
      onAddButtonClick(id);
    } catch {
      showError(ERROR_TEXT.default);
    }
  };

  return (
    <FlexContainer marginBottom={theme.spacing.spacing02}>
      <Controller
        name={`contactPersonIds.${index}.value` as const}
        render={({ field: { onChange, value } }) => (
          <>
            <ContactsInput
              aria-label={`${WorkflowFields.CONTACT_PERSON_IDS}${index}`}
              value={[slotValue]}
              placeholder="Select Contact Person"
              status={hasError && !value ? "error" : undefined}
              onChange={(id: string) => handleChange(id, onChange)}
            />
          </>
        )}
      />
      <When condition={displayRemoveButton}>
        <Button
          ml={theme.spacing.spacing02}
          severity="low"
          type="button"
          icon="trash"
          onClick={onRemoveButtonClick}
        />
      </When>
    </FlexContainer>
  );
}

type ContactPersonSlot = {
  contactPersonId: string;
  slotId: string;
};

type WorkflowDetailsProps = {
  setContactPersonSlots: (slots: ContactPersonSlot[]) => void;
  contactPersonSlots: ContactPersonSlot[];
};
