/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Row, Col } from "react-awesome-styled-grid";
import { FormProvider, UnpackNestedValue, useForm } from "react-hook-form";
import { Tabs, Tab } from "@nordcloud/gnui";
import {
  IntegrationPluginType,
  IntegrationPluginsQuery,
  IntegrationPluginsQueryVariables,
  useAddTurbonomicIntegrationPluginV2Mutation,
  IntegrationPluginsDocument,
} from "~/generated/graphql";
import { BreadcrumbsBox, ErrorWrap, GlobalSpinner } from "~/components";
import { ERROR_TEXT } from "~/constants";
import { showError } from "~/services/toast";
import { AddIntegrationSummary } from "./AddIntegrationSummary";
import { IntegrationPluginDetailsForm } from "./IntegrationPluginDetailsForm";
import { IntegrationPluginFormData, getDefaultValues } from "./types";
import { getIntegrationPluginSchema } from "./validators";

export function AddIntegrationPlugin() {
  const [currentStep, setCurrentStep] = React.useState(
    Step.GENERAL_INFORMATION
  );
  const [integrationId, setIntegrationId] = React.useState("");

  const [
    addTurbonomicPlugin,
    {
      loading,
      client: { cache },
    },
  ] = useAddTurbonomicIntegrationPluginV2Mutation();

  const formMethods = useForm<UnpackNestedValue<IntegrationPluginFormData>>({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(getIntegrationPluginSchema()),
  });
  const { reset, handleSubmit } = formMethods;

  const handleAddAnotherClick = () => {
    reset(getDefaultValues());
    setCurrentStep(Step.GENERAL_INFORMATION);
  };

  const onSubmit = async (
    formData: UnpackNestedValue<IntegrationPluginFormData>
  ) => {
    try {
      const response = await addTurbonomicPlugin({
        variables: {
          input: formDataToInput(formData),
        },
      });

      const responseIntegrationPlugin = response.data
        ?.addTurbonomicIntegrationPluginV2
        ? [response.data.addTurbonomicIntegrationPluginV2]
        : [];

      cache.updateQuery<
        IntegrationPluginsQuery,
        IntegrationPluginsQueryVariables
      >({ query: IntegrationPluginsDocument }, (integrationPluginsQuery) => ({
        integrationPlugins: [
          ...responseIntegrationPlugin,
          ...(integrationPluginsQuery?.integrationPlugins ?? []),
        ],
      }));

      setIntegrationId(
        response.data?.addTurbonomicIntegrationPluginV2?.id ?? ""
      );
      setCurrentStep(Step.SUMMARY);
    } catch (err) {
      showError(err.message ?? ERROR_TEXT.tryAgainDefault);
    }
  };

  return (
    <ErrorWrap>
      <GlobalSpinner loading={loading} />
      <Row>
        <Col>
          <BreadcrumbsBox
            title="Add New Integration"
            labels={BREADCRUMBS_LABELS}
          />

          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Tabs wizard step={currentStep} handleTab={setCurrentStep}>
                <Tab disabled heading="General Information">
                  <IntegrationPluginDetailsForm />
                </Tab>
                <Tab disabled heading="Summary">
                  <AddIntegrationSummary
                    id={integrationId}
                    onAddAnotherClick={handleAddAnotherClick}
                  />
                </Tab>
              </Tabs>
            </form>
          </FormProvider>
        </Col>
      </Row>
    </ErrorWrap>
  );
}

const BREADCRUMBS_LABELS = [
  {
    value: "integration-plugins",
    label: "Integration Plugins",
  },
  {
    value: "add-plugin",
    label: "Add New Plugin",
  },
];

const enum Step {
  GENERAL_INFORMATION = 0,
  SUMMARY = 1,
}

function formDataToInput(
  formData: UnpackNestedValue<IntegrationPluginFormData>
) {
  return {
    name: formData.integrationName,
    description: formData.integrationDescription,
    integrationType: IntegrationPluginType.Turbonomic,
    contactPersonIds: formData.contactPersonIds.map((id) => id.value),
    url: formData.url,
    username: formData.accountName,
    password: formData.password,
  };
}
