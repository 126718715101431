/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { useUpdateEffect } from "react-use";
import { Item } from "./types";

type Props = {
  selectableItems: Item[];
  selectedOptions: string[];
  onMultiSelect: (ids: string[]) => void;
};

export function useSelectableListFilter({
  selectableItems,
  selectedOptions,
  onMultiSelect,
}: Props) {
  const [selectedItems, setSelectedItems] = useState(selectedOptions);
  const [search, setSearch] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<Item[]>([]);

  useUpdateEffect(() => {
    setSelectedItems(selectedOptions);
  }, [JSON.stringify(selectedOptions)]);

  useUpdateEffect(() => {
    onMultiSelect(selectedItems ?? []);
  }, [selectedItems]);

  useEffect(() => {
    setFilteredOptions(
      selectableItems.filter(
        ({ label, value }) =>
          label?.toLowerCase().includes(search) ||
          value?.toLowerCase().includes(search)
      )
    );
  }, [search, selectableItems]);

  const onApply = (item: string) => {
    const itemClearedZeros = item.replace(/^0+/, "");
    const isItemSelected =
      selectedItems.includes(item) || selectedItems.includes(itemClearedZeros);

    if (isItemSelected) {
      setSelectedItems((prev) =>
        prev.filter(
          (storedItem) => storedItem !== item && storedItem !== itemClearedZeros
        )
      );
    } else {
      setSelectedItems((prev) => [...prev, item]);
    }
  };

  return {
    selectedItems,
    filteredOptions,
    setSearch,
    onApply,
  };
}
