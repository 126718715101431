/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { stringify } from "query-string";
import { generatePath } from "react-router-dom";
import {
  CloudProviderType,
  Provider,
  useAccountsV2ByProviderQuery,
} from "~/generated/graphql";
import { ROUTES } from "~/routing/routes";
import { getFirstItem, isNotEmpty } from "~/tools";

export function useCloudAccount(accountId: string, provider: Provider) {
  const { data } = useAccountsV2ByProviderQuery({
    variables: {
      page: 0,
      limit: 2,
      filter: {
        cloudProviderIds: [accountId],
        providers: [provider],
        providerTypes: [CloudProviderType.Cloud],
      },
    },
  });

  if (
    data?.accountsV2?.count === 1 &&
    getFirstItem(data?.accountsV2?.accounts ?? []) != null
  ) {
    const account = getFirstItem(data?.accountsV2?.accounts ?? []);
    return {
      ...account,
      url: getAccountUrl(accountId, account.id),
      urlLabel: getAccountLabel(account),
    };
  }

  return undefined;
}

export function getAccountUrl(id: string, nid: string) {
  if (nid) {
    return generatePath(`${ROUTES.cloudAccounts.details}`, { nid });
  }

  // this fallback is planned to be changed in the future as part of another story
  return `${ROUTES.estateRecords.index}?${stringify(
    { account: [id] },
    { arrayFormat: "index" }
  )}`;
}

function getAccountLabel(account: {
  displayName?: string | null;
  name: string;
  providerId: string;
}) {
  const name = account.displayName ?? account.name;
  return isNotEmpty(name)
    ? `${name} (${account.providerId})`
    : account.providerId;
}
