/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloError } from "@apollo/client";
import { If, Then, Else, When } from "react-if";
import { theme, Text } from "@nordcloud/gnui";
import { isNotEmpty } from "~/tools";
import { ActiveFilterItemMapped } from "./ActiveFilterMapped";
import {
  formatActiveFilterName,
  getTextColor,
  keyValueFilters,
} from "./helpers";
import { OptionDeleteButton, StyledOption } from "./styles";

type Props = {
  name: string;
  mappedFilterOptions: {
    nid: string;
    name: string;
  }[];
  isFilterExcluded: boolean;
  valueArray: string[];
  error: ApolloError | undefined;
  onRemoveValueHandler: (inputName: string, index: number) => void;
};

export function FilterValues({
  name,
  mappedFilterOptions,
  valueArray,
  isFilterExcluded,
  error,
  onRemoveValueHandler,
}: Props) {
  return (
    <div css={{ maxHeight: "10rem", overflow: "auto" }}>
      <If condition={isNotEmpty(mappedFilterOptions)}>
        <Then>
          <ActiveFilterItemMapped
            filter={name}
            valueArray={valueArray}
            data={mappedFilterOptions}
            isFilterExcluded={isFilterExcluded}
            onRemoveValueHandler={onRemoveValueHandler}
          />
        </Then>
        <Else>
          <When condition={isNotEmpty(valueArray) && !error}>
            <When condition={keyValueFilters.includes(name)}>
              <Text
                nowrap
                size="sm"
                mb="0"
                mr={theme.spacing.spacing04}
                weight="medium"
                color={theme.color.text.text02}
              >
                {`${formatActiveFilterName(name)} (key:value)`}
              </Text>
            </When>
            {valueArray.map((item, index) => (
              <StyledOption
                key={item.toString()}
                justifyContent="space-between"
              >
                <Text
                  nowrap
                  size="sm"
                  mb="0"
                  mr={theme.spacing.spacing03}
                  color={getTextColor(isFilterExcluded)}
                >
                  {item}
                </Text>
                <OptionDeleteButton
                  icon="trash"
                  size="sm"
                  severity="low"
                  onClick={() => onRemoveValueHandler(name, index)}
                />
              </StyledOption>
            ))}
          </When>
        </Else>
      </If>
    </div>
  );
}
