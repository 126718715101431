/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

export const LinkButton = styled.div`
  background-color: ${theme.color.background.ui04};
  width: ${theme.spacing.spacing04};
  border-radius: ${theme.radius.sm};
  cursor: pointer;
  padding: ${theme.spacing.spacing01} ${theme.spacing.spacing02};

  svg {
    margin-top: -${theme.spacing.spacing01};
  }

  &:hover {
    background-color: ${theme.color.background.ui01};
  }
`;

export const Indicator = styled.div<{ color: string }>`
  width: ${theme.spacing.spacing04};
  height: ${theme.spacing.spacing04};
  background-color: ${(props) => props.color};
  border-radius: 2px;
`;

export const Container = styled.div<{ borderRight?: boolean }>`
  padding: ${theme.spacing.spacing04};
  border-right: ${(props) =>
    props.borderRight ? `1px solid ${theme.color.border.border01}` : `none`};

  &:first-of-type {
    padding-left: 0;
  }
`;
