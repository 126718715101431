/**
 * Copyright 2021-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import { Col, Row } from "react-awesome-styled-grid";
import { When } from "react-if";
import { FlexContainer, Text, theme } from "@nordcloud/gnui";
import { KpiWidget } from "~/components/KPI/components/KpiWidget";
import { KpiType } from "~/constants";
import { Maybe, noop } from "~/tools";

const DEFAULT_ALLOWED_WIDGETS = [
  KpiType.COST_ALLOCATION_COVERAGE,
  KpiType.OVERALL_WASTE,
  KpiType.NON_PROD_HOURS,
];

type KpiDetails = {
  target: Maybe<number>;
  value: Maybe<string>;
  additionalValue?: Maybe<string>;
};

type Props = {
  costAllocationCoverage?: Maybe<KpiDetails>;
  overallWaste?: Maybe<KpiDetails>;
  nonProdHours?: Maybe<KpiDetails>;
  selectedType?: KpiType;
  allowedWidgets?: KpiType[];
  onClick?: (type: KpiType) => void;
};

export function KpiWidgets({
  costAllocationCoverage,
  overallWaste,
  nonProdHours,
  selectedType,
  allowedWidgets = DEFAULT_ALLOWED_WIDGETS,
  onClick = noop,
}: Props) {
  const isAllowed = React.useCallback(
    (type: KpiType) => allowedWidgets?.includes(type),
    [allowedWidgets]
  );

  const columnSizes = {
    lg: allowedWidgets.length > 2 ? 4 : 6,
    md: 4,
    xs: 8,
  } as const;

  return (
    <Row css={{ rowGap: theme.spacing.spacing06 }} data-testid="kpi-widgets">
      <When condition={isAllowed(KpiType.COST_ALLOCATION_COVERAGE)}>
        <Col
          lg={columnSizes.lg}
          md={columnSizes.md}
          xs={columnSizes.xs}
          data-testid="kpi-widgets-cost-allocation"
        >
          <KpiWidget
            type={KpiType.COST_ALLOCATION_COVERAGE}
            target={costAllocationCoverage?.target}
            value={costAllocationCoverage?.value}
            additionalValue={costAllocationCoverage?.additionalValue}
            selected={selectedType === KpiType.COST_ALLOCATION_COVERAGE}
            onClick={onClick}
          />
        </Col>
      </When>
      <When condition={isAllowed(KpiType.OVERALL_WASTE)}>
        <Col
          lg={columnSizes.lg}
          md={columnSizes.md}
          xs={columnSizes.xs}
          data-testid="kpi-widgets-overall-waste"
        >
          <KpiWidget
            type={KpiType.OVERALL_WASTE}
            target={overallWaste?.target}
            value={overallWaste?.value}
            additionalValue={overallWaste?.additionalValue}
            selected={selectedType === KpiType.OVERALL_WASTE}
            onClick={onClick}
          />
        </Col>
      </When>
      <When condition={isAllowed(KpiType.NON_PROD_HOURS)}>
        <Col
          lg={columnSizes.lg}
          md={columnSizes.md}
          xs={columnSizes.xs}
          data-testid="kpi-widgets-non-prod-hours"
        >
          <KpiWidget
            type={KpiType.NON_PROD_HOURS}
            target={nonProdHours?.target}
            value={nonProdHours?.value}
            additionalValue={nonProdHours?.additionalValue}
            selected={selectedType === KpiType.NON_PROD_HOURS}
            onClick={onClick}
          />
        </Col>
      </When>
    </Row>
  );
}

export function NoKPI() {
  return (
    <FlexContainer direction="column" id="flow-kpi-0">
      <Text weight="bold" mb="0">
        No Primary Business Context
      </Text>
      <Text mb={theme.spacing.spacing06}>
        This is the KPIs section. Set up the primary Business Context to see the
        KPIs.
      </Text>
    </FlexContainer>
  );
}
