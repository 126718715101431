/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Box, theme } from "@nordcloud/gnui";
import {
  BrickLoader,
  DataContainer,
  DataItem,
  Pagination,
  TableLoader,
  UniversalWrap,
} from "~/components";
import { useDisclosure } from "~/hooks";
import {
  ApplicationsProvider,
  AttachOrganizationUnit,
  useMappedEnvironments,
} from "~/views/applications/ApplicationsPage/components";
import { ApplicationItemV2 } from "./ApplicationItemV2";
import { useApplications, useBusinessContext } from "./hooks";

export function ApplicationListWrapper() {
  const { applications, count, loading, error } = useApplications();
  const [environmentId, setEnvironmentId] = useState<string | null>(null);
  const [applicationId, setApplicationId] = useState("");

  const {
    businessContexts,
    primaryBusinessContextId,
    loading: isBusinessContextsLoading,
    error: businessContextsError,
  } = useBusinessContext({ limit: 100 });
  const { isOpen, close, open } = useDisclosure();
  const isLoading = loading || isBusinessContextsLoading;
  const errors = error || businessContextsError;

  const [
    getEnvironments,
    {
      loading: isLoadingEnvironments,
      error: errorEnvironments,
      refetch: refetchEnvironments,
    },
  ] = useMappedEnvironments();

  return (
    <Box>
      <section>
        <DataContainer hasBorder={false}>
          <DataItem
            grow
            padding="0"
            weight="medium"
            size="md"
            value="List of Applications"
          />
        </DataContainer>
      </section>
      <ApplicationsProvider
        value={{
          businessContexts,
          primaryBusinessContextId,
          openSidebar: open,
          setEnvironmentId,
          setApplicationId,
          applicationId,
          getEnvironments,
          isLoadingEnvironments,
          errorEnvironments,
          refetchEnvironments,
        }}
      >
        <UniversalWrap
          loaderProps={{ loading: isLoading, Component: Loader }}
          errorProps={{ error: errors }}
        >
          {applications.map((application) => (
            <ApplicationItemV2 key={application.id} application={application} />
          ))}
        </UniversalWrap>
        <AttachOrganizationUnit
          isOpen={isOpen}
          close={close}
          environmentId={environmentId ?? ""}
        />
      </ApplicationsProvider>
      <Pagination count={count} />
    </Box>
  );
}

function Loader() {
  return (
    <>
      <BrickLoader
        viewBox="0 0 4 1"
        style={{
          display: "block",
          height: "auto",
          marginBottom: theme.spacing.spacing04,
        }}
      />
      <BrickLoader
        viewBox="0 0 14 1"
        style={{
          display: "block",
          height: "auto",
          marginBottom: theme.spacing.spacing04,
        }}
      />
      <TableLoader
        gapY={6}
        gapX={0}
        rows={[
          {
            count: 4,
            height: 100,
            items: [
              {
                widthPercent: 100,
              },
            ],
          },
        ]}
      />
    </>
  );
}
