/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCurrency } from "~/hooks";
import { formatMoney } from "~/tools";
import { BenefitWrapper } from "./styles";

type Props = {
  value?: number | string | null;
};

export function ReservationCostCell({ value = 0 }: Props) {
  const { currency } = useCurrency();
  const formattedValue = formatMoney(value, currency);

  return (
    <BenefitWrapper>
      <span>{formattedValue}</span>
    </BenefitWrapper>
  );
}
