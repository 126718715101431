/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Cell } from "react-table";
import { Text, theme } from "@nordcloud/gnui";
import { Provider } from "~/generated/graphql";
import {
  ProviderCell,
  ResourceNameDetailsCell,
} from "~/components/Tables/Cells";
import {
  AccountLink,
  ApplicationEnvironmentCell,
} from "~/views/estate/EstateList/cells";
import { ReservationCostCell } from "../ReservationCostCell";

type Benefit = {
  id: string;
  nid: string;
  provider: Provider;
  type?: string;
  category: string;
  name: string;
  account: string;
  location: string;
  environment: {
    name: string;
    nid: string;
    application: {
      nid: string;
      name: string;
    };
  };
  benefit: string;
};

const applicationCell = ({ row }: Cell<Benefit>) => {
  return <ApplicationEnvironmentCell environment={row.original?.environment} />;
};

export const BenefitListColumns = () => [
  {
    header: "",
    accessorKey: "provider",
    id: "provider",
    enableSorting: false,
    cell: ({ row }: Cell<Benefit>) => (
      <ProviderCell provider={row.original.provider} />
    ),
  },
  {
    header: "Resource name / Resource Details",
    accessorKey: "nordcloudId",
    id: "NID",
    width: "16rem",
    enableSorting: false,
    cell: ({ row }: Cell<Benefit>) => {
      const { type, category, name, id, nid, provider, location } =
        row.original;

      return (
        <ResourceNameDetailsCell
          id={id}
          nordcloudId={nid}
          name={name}
          type={type}
          category={category}
          provider={provider}
          region={location}
        />
      );
    },
  },
  {
    header: "Account Name / Account ID",
    accessorKey: "accountName",
    id: "ACCOUNT",
    width: "19rem",
    enableSorting: false,
    cell: ({ row }: Cell<Benefit>) => (
      <>
        <AccountLink cloudAccountId={row.original.account} />
        <Text size="sm" tag="div" color={theme.color.text.text02}>
          {row.original.account}
        </Text>
      </>
    ),
  },
  {
    header: "Application / Environment",
    accessorKey: "application",
    id: "TYPE",
    width: "13rem",
    enableSorting: false,
    cell: (row: Cell<Benefit>) => applicationCell(row),
  },
  {
    header: "Benefit",
    id: "BENEFIT",
    accessorKey: "benefitCost",
    width: "6rem",
    cell: ({ row }: Cell<Benefit>) => {
      const { benefit } = row.original;

      return <ReservationCostCell value={benefit} />;
    },
  },
];
