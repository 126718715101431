/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, PropsWithChildren } from "react";
import { Row, Col } from "react-awesome-styled-grid";
import { When } from "react-if";
import { generatePath, Link } from "react-router-dom";
import {
  Spacer,
  Table,
  theme,
  Text,
  Button,
  FlexContainer,
} from "@nordcloud/gnui";
import { ROUTES } from "~/routing/routes";
import { useBillingPeriod } from "~/services/customers";
import { isNotNil } from "~/tools";
import { hasCo2e } from "~/tools/applicationMapper";
import { EnvironmentInput } from "~/views/environments/EnvironmentInput";
import { Co2eSavings } from "../Co2e";
import { WasteIndicator } from "../WasteIndicator";
import { AppEnvironment } from "./Types";

type Props = {
  applicationId: string;
  environments: AppEnvironment[];
  showAppCo2eSavings?: boolean;
  showDetach?: boolean;
  showOrganizationUnit?: boolean;
  onRemoveEnvironment?: (item: { nid: string; name: string }) => void;
};

export function EnvironmentDetails({
  applicationId,
  environments,
  showAppCo2eSavings,
  showDetach = false,
  showOrganizationUnit = false,
  onRemoveEnvironment,
}: Props) {
  const { isCurrentBillingPeriod } = useBillingPeriod();
  const textStyle = showOrganizationUnit
    ? ApplicationTextStyle
    : BusinessOrgStyle;
  const getEnvironmentLink = useCallback(
    (environmentId: string) =>
      generatePath(ROUTES.applications.environment, {
        application: applicationId,
        environment: environmentId,
      }),
    [applicationId]
  );

  return (
    <Row>
      <Col>
        <Spacer height={theme.spacing.spacing04} />
        <Table small>
          <Table.thead>
            <Table.tr>
              <Table.th width={textStyle.envWidth}>Environment</Table.th>
              <When condition={showOrganizationUnit}>
                <Table.th width="40%">Organizational Units</Table.th>
              </When>
              <TableHeader textStyle={textStyle}>Estate Records</TableHeader>
              <TableHeader textStyle={textStyle}>Monthly cost</TableHeader>
              <TableHeader textStyle={textStyle}>Monthly Forecast</TableHeader>
              <TableHeader textStyle={textStyle}>Monthly Budget</TableHeader>
              <When condition={showDetach}>
                <TableHeader textStyle={textStyle}>Detach</TableHeader>
              </When>
            </Table.tr>
          </Table.thead>
          <Table.tbody>
            {environments.map((environment) => (
              <Table.tr key={environment.id}>
                <Table.td>
                  <Link to={getEnvironmentLink(environment.id)}>
                    {environment.environment}
                  </Link>
                </Table.td>
                <When condition={showOrganizationUnit}>
                  <Table.td>
                    <EnvironmentInput environmentId={environment.id} />
                  </Table.td>
                </When>
                <Table.td>
                  <Text
                    isTitle
                    tag="div"
                    mb={theme.spacing.spacing01}
                    align={textStyle.align}
                    weight={textStyle.fontWeight}
                  >
                    {environment.resources}
                  </Text>
                </Table.td>
                <Table.td hasLeftBorder>
                  <FlexContainer
                    justifyContent={textStyle.flex}
                    alignItems="center"
                  >
                    <Text
                      tag="div"
                      ml={theme.spacing.spacing02}
                      align={textStyle.align}
                      weight={textStyle.fontWeight}
                    >
                      {environment.actualCosts}
                    </Text>
                  </FlexContainer>
                </Table.td>
                <When condition={isCurrentBillingPeriod}>
                  <Table.td hasLeftBorder>
                    <FlexContainer
                      justifyContent={textStyle.flex}
                      alignItems="center"
                    >
                      <When
                        condition={environment.cloudWasteAggregate?.hasWaste}
                      >
                        <When
                          condition={showAppCo2eSavings && hasCo2e(environment)}
                        >
                          <Co2eSavings
                            iconOnly
                            co2eSavings={parseFloat(
                              environment.cloudWasteAggregate?.co2e ?? "0"
                            )}
                          />
                        </When>
                        <WasteIndicator
                          size="sm"
                          reason="Environment has Savings Suggestions"
                          amount={{
                            amount: environment.cloudWasteAggregate?.cost ?? "",
                            uri: `${getEnvironmentLink(
                              environment.id
                            )}?page=0&waste=true`,
                          }}
                        />
                      </When>
                      <Text
                        tag="div"
                        ml={theme.spacing.spacing02}
                        weight={textStyle.fontWeight}
                      >
                        {environment.forecast}
                      </Text>
                    </FlexContainer>
                  </Table.td>
                </When>
                <Table.td hasLeftBorder>
                  <FlexContainer
                    justifyContent={textStyle.flex}
                    alignItems="center"
                  >
                    <Text
                      tag="div"
                      ml={theme.spacing.spacing02}
                      weight={textStyle.fontWeight}
                    >
                      {environment.budget}
                    </Text>
                  </FlexContainer>
                </Table.td>
                <When condition={isNotNil(onRemoveEnvironment)}>
                  <Table.td hasLeftBorder>
                    <Button
                      data-testid="environment-remove"
                      css={{ marginLeft: theme.spacing.spacing05 }}
                      severity="low"
                      icon="brokenLink"
                      size="md"
                      onClick={() =>
                        onRemoveEnvironment?.({
                          nid: environment.id,
                          name: environment.environment,
                        })
                      }
                    />
                  </Table.td>
                </When>
              </Table.tr>
            ))}
          </Table.tbody>
        </Table>
      </Col>
    </Row>
  );
}

type TableHeaderProps = {
  textStyle: typeof ApplicationTextStyle | typeof BusinessOrgStyle;
};

function TableHeader({
  children,
  textStyle,
}: PropsWithChildren<TableHeaderProps>) {
  return (
    <Table.th>
      <Text
        isTitle
        size="sm"
        tag="div"
        mb={theme.spacing.spacing01}
        align={textStyle.align}
      >
        {children}
      </Text>
    </Table.th>
  );
}

const ApplicationTextStyle = {
  envWidth: "0%",
  fontWeight: "medium",
  align: "right",
  flex: "flex-end",
} as const;

const BusinessOrgStyle = {
  envWidth: "50%",
  fontWeight: "regular",
  align: "left",
  flex: "flex-start",
} as const;
